import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { MOBILE } from "core/constants/global";
import ProductEntity from "core/entities/ProductEntity";
import {
    ContactType,
    ContactTypeEntity,
    Form as FormModel,
    FormFields,
    FormFieldType,
    FormOptions,
    Image as ImageModel,
} from "core/models";
import { FormBackEndModel } from "core/models/FormBackEndModel";
import Analytics from "core/services/analytics";
import sendContactAnnex from "core/services/contactAnnex";
import fetchContactProducts from "core/services/contactProducts";
import fetchContactTypes from "core/services/contactTypes";
import { sendForm } from "core/services/sendForm";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useWindowWidth } from "utils/useWindowWidth";
import {
    Body,
    FormFieldset,
    FormResponse,
    FormSelect,
    Image,
    ModuleWrapper,
    Spinner,
} from "view/components";
import ConfigContext from "view/context/ConfigContext";
import useAccountService from "view/hooks/useAccountService";
import useTokenService from "view/hooks/useTokenService";
import $ from "./Form.module.scss";
import {
    customerFields,
    entrepeneursFields,
    leadsFields,
    magnetsFields,
} from "./FormFields";
import { horecaHeatmapFields } from "./FormFields/HorecaHeatmapFields";

declare let MsCrmMkt: {
    Logger: new () => unknown;
    ConfigProvider: new (Logger: unknown) => {
        (): unknown;
        new (): unknown;
        getConfig: { (): Promise<unknown>; new (): unknown };
    };
    initTracking: (config: unknown) => void;
    initFormDetection: (config: unknown, scriptLoader: unknown) => void;
    ScriptLoader: new () => unknown;
    MsCrmFormLoader: {
        sendFormCaptureToCrm: (arg0: HTMLFormElement | null) => Promise<string>;
        on: (arg0: string, arg1: (event: unknown) => void) => void;
    };
};

const formatInitialsStateValue = (field: FormFields) => {
    switch (field.type) {
        case FormFieldType.Select:
        case FormFieldType.Radio:
            return field.options && field.options[0]?.value;
        case FormFieldType.Checkbox:
            return false;
        case FormFieldType.Label:
            return null;
        case FormFieldType.Hidden:
            return field.placeholder;
        default:
            return "";
    }
};

function createInitialState(fieldsets: FormFields[]) {
    // Get all field ids and when a field is a select, use the first option as default value
    const idValueArr: FormOptions[] = fieldsets
        .filter((i) => i.type !== FormFieldType.Label)
        .reduce((total: FormOptions[], field: FormFields) => {
            const nameValuePair: FormOptions = {
                label: field.name,
                value: formatInitialsStateValue(field),
            };
            return total.concat(nameValuePair);
        }, []);
    const state = {};

    idValueArr.forEach((obj) => {
        state[obj.label] = obj.value;
    });

    return state;
}

function convertProductListToOptions(
    productList: ProductEntity[],
    prop: string,
): FormOptions[] {
    return productList.map((product) => ({
        value: product[prop],
        label: product[prop],
    }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeDuplicatesFromNestedArr(arr: any[], prop: string) {
    return arr.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t[prop] === item[prop]),
    );
}

async function send(endpoint, configContext, form, setMessage) {
    setMessage("loading");
    const body = form;

    const response = await sendForm(configContext, endpoint, body);

    if (response && response.ok) {
        return response.ok;
    }

    return setMessage("fail");
}

async function sendUploadedFiles({
    apiUrl,
    subscriptionKey,
    files,
    contactFormId,
    setMessage,
}) {
    const filesArray: File[] = [];
    let i: number;

    for (i = 0; i < files.length; i += 1) {
        filesArray.push(files.item(i));
    }

    const response = await sendContactAnnex({
        apiUrl,
        subscriptionKey,
        body: filesArray,
        contactFormId,
    });

    if (response && response.status === 200) {
        setMessage("success");
        return response;
    }

    return setMessage("fail");
}

const scrollElementIntoView = (element) => {
    if (element) {
        element.scrollIntoView(false);
    }
};

const Form = (props: FormModel) => {
    const {
        title,
        text,
        contactType,
        contactSubType,
        postUri,
        colorBackground,
        image,
        id,
    } = props;
    const [message, setMessage] = React.useState<string>();
    const configContext = React.useContext(ConfigContext);
    const [listOfTypes, setListOfTypes] = React.useState<ContactType[]>([]);
    const [formType, setFormType] = React.useState<ContactType>();

    const { search, pathname } = useLocation();

    const [selectedTaste, setSelectedTaste] = React.useState<string>();
    const [listOfTastes, setListOfTastes] = React.useState<FormOptions[]>([]);

    const [selectedBrand, setSelectedBrand] = React.useState<string>();
    const [listOfBrands, setListOfBrands] = React.useState<FormOptions[]>([]);
    const [selectedPackaging, setSelectedPackaging] = React.useState<string>();
    const [listOfPackaging, setListOfPackaging] = React.useState<FormOptions[]>(
        [],
    );
    const [products, setProducts] = React.useState<ProductEntity[]>([]);

    const [showPersonalDetails, setShowPersonalDetails] = React.useState(false);

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { fetchData: fetchAccountData, data: accountData } =
        useAccountService(configContext, token);

    const formElement = React.useRef<HTMLFormElement>(null);

    const { t } = useTranslation();
    const windowWidth = useWindowWidth();

    const fieldsets = () => {
        switch (contactType) {
            case "Customers": {
                return customerFields(
                    listOfBrands,
                    listOfTastes,
                    listOfPackaging,
                    showPersonalDetails,
                    formType?.name,
                    contactSubType,
                );
            }
            case "Leads": {
                return leadsFields(contactSubType);
            }
            case "Magnets": {
                return magnetsFields(contactSubType);
            }
            case "HorecaHeatmap": {
                return horecaHeatmapFields(contactSubType);
            }
            case "Contactform":
            default: {
                return entrepeneursFields(contactSubType);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [form, setForm] = React.useState<any>(() => {
        const initialState = fieldsets && createInitialState(fieldsets());
        return initialState;
    });

    const [isLoading, setIsLoading] = React.useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [files, setFiles] = React.useState<any>();

    const formResponseId = "formResponse";

    React.useEffect(() => {
        const element = document.getElementById(formResponseId);
        scrollElementIntoView(element);
    }, [message]);

    const initiate = async () => {
        setIsLoading(true);
        const website = "vrumona.nl";

        if (contactType === "Customers") {
            await fetchContactTypes(
                configContext?.contactApiUrl,
                configContext?.apiKey,
            )
                .then((response) => {
                    const typesData = response.data.filter(
                        (type: ContactTypeEntity) => {
                            if (type.name === "Rewards") return false;
                            return true;
                        },
                    );
                    const types = typesData.map(
                        (type: ContactTypeEntity) => new ContactType(type),
                    );
                    setListOfTypes(types);
                    setFormType(types[0]);
                })
                .catch(() => {
                    setIsLoading(false);
                    setMessage("fail");
                });

            await fetchContactProducts(
                configContext?.contactApiUrl,
                configContext?.apiKey,
                website,
            )
                .then((allProducts) => {
                    const filteredBrands = removeDuplicatesFromNestedArr(
                        allProducts.data,
                        "brand",
                    );
                    const brandOptions = convertProductListToOptions(
                        filteredBrands,
                        "brand",
                    );

                    setProducts(allProducts.data);
                    setListOfBrands(brandOptions);
                    setSelectedBrand(brandOptions[0].value);
                })
                .catch(() => {
                    setIsLoading(false);
                    setMessage("fail");
                });
        } else {
            new MsCrmMkt.ConfigProvider(new MsCrmMkt.Logger())
                .getConfig()
                .then((config) => {
                    if (config) {
                        MsCrmMkt.initTracking(config);
                        MsCrmMkt.initFormDetection(
                            config,
                            new MsCrmMkt.ScriptLoader(),
                        );
                    }
                });
        }

        if (contactType === "Contactform") {
            if (isAuthenticated) {
                await fetchAccountData();
            }
        }

        setIsLoading(false);
    };

    React.useEffect(() => {
        initiate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        fetchAccountData();
    }, [fetchAccountData]);

    // Pre-fill form with account data.
    React.useEffect(() => {
        if (accountData && form) {
            form.companyname = accountData.name;
            form.street = accountData.address1line1;
            form.housenumber = accountData.address1line2;
            form.postalcode = accountData.address1postalcode;
            form.city = accountData.address1city;
            form.email = accountData.emailaddress1;
            form.phone = accountData.telephone;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData]);

    const formatData = (fields) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let formattedData: any | FormBackEndModel = {};

        if (contactType === "Customers") {
            if (!formType) return {};

            formattedData = {
                ContactType: {
                    id: formType.id,
                    name: formType.value,
                    label: formType.label,
                },
                Contact: {
                    FirstName: fields.firstname,
                    LastName: fields.lastname,
                    Gendercode: fields.gender,
                    Email: fields.email,
                    PhoneNumber: fields.phone,
                    Annotation: fields.description,
                    SourceWebsite: "vrumona.nl",
                    SourceUrl: pathname + search,
                },
            } as FormBackEndModel;

            if (formType.name === "specialOffer") {
                formattedData.additionalPromotion = {
                    Description: fields.specialoffer,
                };
            }

            if (formType.name === "product") {
                const selectedProduct = products.filter(
                    (product) =>
                        product.brand === selectedBrand &&
                        product.taste === selectedTaste &&
                        product.packagingsize === selectedPackaging,
                )[0];

                formattedData.additionalComplain = {
                    ProductCode: selectedProduct.gpcode,
                    ProductName: selectedProduct.name,
                    ProductionCode: form.productioncode,
                    ExpirationDate: form.expirationdate,
                    SoldBy: fields.purchasespot,
                    PickUpForFree: fields.pickupproduct,
                };

                if (fields.pickupproduct === "yes") {
                    formattedData.additionalContactAdress = {
                        Street: fields.street,
                        HouseNumber1: fields.housenumber,
                        HouseNumber2: fields.housenumberaddition || "",
                        City: fields.city,
                        PostalCode: fields.postalcode,
                        Country: fields.country,
                    };
                }
            }
        } else {
            formattedData.contactType = {
                id: -1,
                name: contactType,
                label: contactType,
            };

            // eslint-disable-next-line array-callback-return
            Object.keys(fields).map((fieldName) => {
                formattedData[fieldName] = fields[fieldName];
            });

            formattedData.sourceWebsite = "vrumona.nl";
            formattedData.sourceUrl = pathname + search;

            if (search) {
                const searchParams = new URLSearchParams(search);

                searchParams.forEach((value, param) => {
                    formattedData[param] = value;
                });
            }
        }

        return formattedData;
    };

    const updateListOfTastes = () => {
        if (selectedBrand) {
            const productsFilteredByBrand = products.filter(
                (product) => product.brand === selectedBrand,
            );
            const filteredTastes = removeDuplicatesFromNestedArr(
                productsFilteredByBrand,
                "taste",
            );
            const convertedListOfTastes = convertProductListToOptions(
                filteredTastes,
                "taste",
            );
            setListOfTastes(convertedListOfTastes);
            setSelectedTaste(convertedListOfTastes[0].value);
        }
    };

    const updatelistOfPackaging = () => {
        if (selectedBrand && selectedTaste) {
            const productsFilteredByBrand = products.filter(
                (product) => product.brand === selectedBrand,
            );
            const productsFilteredByTaste = productsFilteredByBrand.filter(
                (product) => product.taste === selectedTaste,
            );
            // A beverage can be served in a 28x20cl gls and a 3x6x20cl pak, both containing 20cl, that's why duplicates need to be removed
            const productsWithoutDuplicates = removeDuplicatesFromNestedArr(
                productsFilteredByTaste,
                "packagingsize",
            );
            const convertedListOfPackaging = convertProductListToOptions(
                productsWithoutDuplicates,
                "packagingsize",
            );

            setListOfPackaging(convertedListOfPackaging);
            setSelectedPackaging(convertedListOfPackaging[0].value);
        }
    };

    React.useEffect(() => {
        updateListOfTastes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrand]);

    React.useEffect(() => {
        updatelistOfPackaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaste]);

    const handleInputChange = (value, name, selectedFiles = null) => {
        const newInputs = {
            ...form,
            [name]: value,
        };
        setForm(newInputs);

        if (name === "product") {
            setSelectedBrand(value);
        }

        if (name === "taste") {
            setSelectedTaste(value);
        }

        if (name === "packagingsize") {
            setSelectedPackaging(value);
        }

        if (name === "pickupproduct") {
            setShowPersonalDetails(value === "yes");
        }

        if (selectedFiles) {
            setFiles(selectedFiles);
        }
    };

    const changeFormType = (e, name) => {
        const newFormType =
            listOfTypes && listOfTypes.find((type) => type.value === name);
        setFormType(newFormType);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formattedData = formatData(form);

        const postUriExpanded = `${postUri}?contactType=${contactType}`;

        send(postUriExpanded, configContext, formattedData, setMessage)
            .then((response) => {
                if (response && !files) {
                    setMessage("success");
                } else if (response && files) {
                    const data = {
                        apiUrl: configContext?.contactApiUrl,
                        subscriptionKey: configContext?.apiKey,
                        files,
                        contactFormId: response,
                        setMessage,
                    };
                    sendUploadedFiles(data);
                } else {
                    setMessage("fail");
                }

                if (response) {
                    Analytics.event("Contact", contactType, response);
                }
            })
            .catch(() => setMessage("fail"));

        MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm(formElement.current);
    };

    function renderImage(imageModel: ImageModel) {
        const imgWidth = windowWidth <= MOBILE ? 748 : 1370;

        return (
            <div className={$.imageContainer}>
                {imageModel && imageModel.url && (
                    <Image
                        placeholder={`${imageModel.url}?w=30&q=50&fm=jpg&fl=progressive`}
                        src={`${imageModel.url}?w=${imgWidth}&q=75&fm=jpg&fl=progressive`}
                        alt={imageModel.title}
                        className={$.image}
                        background
                    />
                )}
            </div>
        );
    }

    return (
        <ModuleWrapper background={colorBackground}>
            <div className={$.container} id="contact">
                <div
                    className={classnames([
                        $.textContainer,
                        colorBackground === "Grey"
                            ? undefined
                            : $.textContainerDark,
                    ])}
                >
                    {(!message || message === "fail") && (
                        <>
                            {title && <h2 className={$.title}>{title}</h2>}

                            {text && <Body content={text} className={$.text} />}

                            {isLoading && <Spinner />}
                            {!isLoading && fieldsets && (
                                <form
                                    className={$.form}
                                    onInvalid={(e) =>
                                        scrollElementIntoView(e.target)
                                    }
                                    onSubmit={handleSubmit}
                                    id={id}
                                    ref={formElement}
                                >
                                    {listOfTypes &&
                                        contactType === "Customers" && (
                                            <FormSelect
                                                type={FormFieldType.Select}
                                                title="Waarmee kunnen wij jou helpen?"
                                                placeholder="Waarmee kunnen wij jou helpen?"
                                                required
                                                name="formType"
                                                value={
                                                    formType && formType.value
                                                }
                                                handleChange={changeFormType}
                                                options={listOfTypes}
                                            />
                                        )}
                                    {/* {fieldsets &&
                                    fieldsets.map(field => ( */}
                                    <FormFieldset
                                        fields={fieldsets()}
                                        formData={form}
                                        handleChange={(
                                            value,
                                            name,
                                            changedFiles,
                                        ) =>
                                            handleInputChange(
                                                value,
                                                name,
                                                changedFiles,
                                            )
                                        }
                                    />
                                    {/* ))} */}
                                    <label htmlFor="terms" className={$.terms}>
                                        <input
                                            type="checkbox"
                                            value="terms-and-conditions"
                                            name="terms-and-conditions"
                                            id="terms"
                                            className={$.termsInput}
                                            required
                                        />
                                        <span className={$.termsCheckbox} />
                                        <span className={$.termsLabel}>
                                            {t("form.pcs1")}{" "}
                                            <a
                                                href="/pcs"
                                                className={$.link}
                                                target="_blank"
                                            >
                                                {t("form.pcs2")}
                                            </a>{" "}
                                            {t("form.pcs3")}*
                                        </span>
                                    </label>
                                    {message === "fail" && (
                                        <FormResponse
                                            id={formResponseId}
                                            title="Er is iets fout gegaan"
                                            text="Probeer het later nog eens."
                                            type="error"
                                        />
                                    )}
                                    <button
                                        type="submit"
                                        className={$.submitButton}
                                    >
                                        {t("form.send")}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            enableBackground="new 0 0 24 24"
                                            height="18"
                                            viewBox="0 0 18 24"
                                            width="18"
                                        >
                                            <rect
                                                fill="none"
                                                height="18"
                                                width="18"
                                            />
                                            <path
                                                fill="#FFF"
                                                d="M19,15l-1.41-1.41L13,18.17V2H11v16.17l-4.59-4.59L5,15l7,7L19,15z"
                                            />
                                        </svg>
                                    </button>
                                </form>
                            )}
                        </>
                    )}
                    {message === "loading" && <Spinner />}
                    {message === "success" && contactType !== "Magnets" && (
                        <FormResponse
                            id={formResponseId}
                            title="Verzonden!"
                            text="Bedankt dat je contact met ons hebt opgenomen! We gaan voor je aan de slag en zullen zo spoedig mogelijk reageren."
                            buttonText="Terug naar home"
                            link="/"
                        />
                    )}
                    {message === "success" && contactType === "Magnets" && (
                        <FormResponse
                            id={formResponseId}
                            title="Verzonden!"
                            text="Je vindt de download binnen een paar minuten in je e-mail inbox. Benieuwd wat wij nog meer te bieden hebben?"
                            buttonText="Bekijk onze Horeca Services"
                            link="/voor-ondernemers"
                        />
                    )}
                </div>
                {image && renderImage(image)}
            </div>
        </ModuleWrapper>
    );
};

export default Form;
