import parseJSON from "date-fns/parseJSON";
import { isString, isArray } from "utils/checkType";
import { Document as DocumentEntity } from "../entities";
import { Document } from "../models";

export interface Documents {
    Documents: DocumentEntity[];
}

function createDocument(data: DocumentEntity): Document {
    const id = data.documentId && isString(data.documentId);
    const documentType = data.documentType && isString(data.documentType);
    const name = data.name && isString(data.name);

    const createdOn =
        (data.createdOn &&
            parseJSON(data.createdOn) &&
            new Date(data.createdOn)) ||
        new Date();

    const year = createdOn?.getFullYear();

    const url = data.url && isString(data.url);

    return {
        id,
        name,
        documentType,
        createdOn,
        year,
        url,
        blob: undefined,
    };
}

export default function createDocuments(data: Documents): Document[] {
    const documents = data && isArray(data);

    return (
        documents &&
        documents.map((document: DocumentEntity) => createDocument(document))
    );
}
