import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { endpoints } from "core/constants";
import {
    Document,
    DocumentsOverview as DocumentsOverviewModel,
} from "core/models";
import { saveAs } from "file-saver";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";
import {
    Button,
    GridColumn,
    GridRow,
    ModuleWrapper,
    Spinner,
} from "view/components";
import { AccountContext } from "view/context/AccountContext";
import ConfigContext from "view/context/ConfigContext";
import useDocumentsService from "view/hooks/useDocumentsService";
import useTokenService from "view/hooks/useTokenService";
import $ from "./DocumentsOverview.module.scss";

type Props = DocumentsOverviewModel & RouteComponentProps;

const DocumentsOverview: React.FC<Props> = (props: Props) => {
    const { title, colorBackground, history, location } = props;
    const { user, getAccessTokenSilently } = useAuth0();
    const configContext = React.useContext(ConfigContext);
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { selectedAccount } = React.useContext(AccountContext);

    const { fetchData, data, pendingDownload, downloadDocument, document } =
        useDocumentsService(
            configContext,
            endpoints.documents(selectedAccount?.accountnumber),
            token,
        );

    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";

    const { pending, documents } = data;

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    React.useEffect(() => {
        if (!document || !document.blob) {
            return;
        }

        saveAs(document.blob, document.name);
    }, [document]);

    const [documentsByYear, setDocumentsByYear] =
        React.useState<{ key: string; item: Document[] }[]>();
    const [selectedDocumentType, setSelectedDocumentType] = React.useState<
        string | undefined
    >(undefined);
    const [documentTypes, setDocumentTypes] = React.useState<string[]>([]);

    const groupBy = (list: Document[], groupedKey: string) => {
        const map = new Map();
        // eslint-disable-next-line array-callback-return
        list.map((val) => {
            if (!map.has(val[groupedKey])) {
                map.set(
                    val[groupedKey],
                    list.filter((foo) => foo[groupedKey] === val[groupedKey]),
                );
            }
        });
        return Array.from(map, ([key, item]) => ({
            key,
            item,
        }));
    };

    React.useEffect(() => {
        if (documents) {
            const documentByYear = groupBy(
                documents.filter(
                    (item) =>
                        selectedDocumentType === undefined ||
                        item.documentType === selectedDocumentType,
                ),
                "year",
            );

            setDocumentsByYear(documentByYear);

            const foo = documents
                .map((item) => item.documentType)
                .filter((value, index, self) => self.indexOf(value) === index);

            setDocumentTypes(foo);
        }
    }, [documents, selectedDocumentType]);

    const allItems =
        documentsByYear &&
        documentsByYear
            // Sort by year, latest on top.
            .sort((a, b) => (a[0] > b[0] ? -1 : 1))
            .map((yeargroup) => (
                <div key={yeargroup.key}>
                    <h3>Jaar {yeargroup.key}</h3>
                    {yeargroup.item.map((item: Document) => (
                        <GridRow
                            className={classnames([
                                $.documentLine,
                                lightBackground && $.documentLineDark,
                            ])}
                            key={item.id}
                        >
                            <span className={$.iconContainer}>
                                <img
                                    src="/static/images/ic-invoice.svg"
                                    alt="Document"
                                />
                            </span>

                            <GridColumn className={$.gridColumn}>
                                <div className={$.text}>
                                    <strong>{item.name}</strong>
                                </div>
                                <small className={$.date}>
                                    {item.createdOn.toLocaleDateString("nl-NL")}
                                </small>
                            </GridColumn>

                            <GridColumn className={$.gridColumn}>
                                {item.documentType}
                            </GridColumn>

                            <Button
                                title="Downloaden"
                                color="green"
                                onClick={() => downloadDocument(item)}
                                disabled={pendingDownload}
                            />
                        </GridRow>
                    ))}
                </div>
            ));

    const [tabIndex, setTabIndex] = React.useState(-1);

    const tablist = documentTypes?.map((documentType) => (
        <Tab className={$.reactTabsTab} key={documentType}>
            {documentType}
        </Tab>
    ));

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const filter = params.get("filter");
        if (filter) {
            const filterIndex = documentTypes.findIndex((t) => t === filter);
            if (filterIndex >= 0) {
                setTabIndex(filterIndex);
                setSelectedDocumentType(filter);
            }
        }
    }, [documentTypes, location.search]);

    const setSelectedTab = (index: number, last: number) => {
        const params = new URLSearchParams(location.search);
        if (last === index) {
            setTabIndex(-1);
            setSelectedDocumentType(undefined);
            params.delete("filter");
        } else {
            setTabIndex(index);
            setSelectedDocumentType(documentTypes[index]);
            params.delete("filter");
            params.append("filter", documentTypes[index]);
        }

        history.push({ search: params.toString() });
    };

    return (
        <ModuleWrapper title={title} background={colorBackground}>
            <section className={$.wrapper}>
                <Tabs
                    usePanel={false}
                    selectedIndex={tabIndex}
                    className={$.reactTabs}
                    disabledTabClassName={$.reactTabsTabDisabled}
                    selectedTabClassName={$.reactTabsTabSelected}
                    selectedTabPanelClassName={$.reactTabsTabPanelSelected}
                    onSelect={setSelectedTab}
                >
                    <TabList className={$.reactTabsTabList}>
                        {tablist}
                        {tabIndex > -1 && (
                            <Button
                                narrow
                                color="white"
                                onClick={() => setSelectedTab(-1, -1)}
                                title="Filter wissen"
                            />
                        )}
                    </TabList>
                </Tabs>

                {pending && <Spinner />}
                {!pending && (allItems || <p>Geen documenten beschikbaar.</p>)}
            </section>
        </ModuleWrapper>
    );
};

export default withRouter(DocumentsOverview);
