import { isString, isImage, isButton } from "utils/checkType";
import { ModuleReviewBlock } from "core/entities";
import { ReviewBlock } from "core/models";

export default function createReviewBlock(
    data: ModuleReviewBlock,
): ReviewBlock {
    const { moduleType, showQuote } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const cta =
        data.navigationItemReference && isButton(data.navigationItemReference);
    const title = data.title && isString(data.title);
    const authorLine1 = data.authorLine1 && isString(data.authorLine1);
    const authorLine2 = data.authorLine2 && isString(data.authorLine2);
    const imageDesktop = data.imageDesktop && isImage(data.imageDesktop);
    const imageMobile = data.imageMobile && isImage(data.imageMobile);

    return {
        id,
        contentType,
        cta,
        moduleType,
        title,
        authorLine1,
        authorLine2,
        imageDesktop,
        imageMobile,
        showQuote,
    };
}
