import { isString } from "utils/checkType";
import { AccountOverview } from "../models";
import { ModuleAccountOverview } from "../entities";

export default function createAccountOverview(
    data: ModuleAccountOverview,
): AccountOverview {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        colorBackground,
    };
}
