import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { endpoints } from "core/constants";
import { Notification as NotificationModel } from "core/models";
import fetchNotificationStatus from "core/services/fetchNotificationStatus";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { AccountContext } from "view/context/AccountContext";
import ConfigContext from "view/context/ConfigContext";
import useNotificationsService from "view/hooks/useNotificationsService";
import useTokenService from "view/hooks/useTokenService";
import Notification from "./Notification";
import { Card, Spinner } from "..";
import $ from "./Notifications.module.scss";

function splitIntoSmallerArrays<T>(notifications: T[], size: number) {
    const arrayOfArrays: T[][] = [];
    for (let i = 0; i < notifications.length; i += size) {
        const sliced = notifications.slice(i, i + size);
        arrayOfArrays.push(sliced);
    }
    return arrayOfArrays;
}

type NotificationModelWithHandlers = NotificationModel & {
    handleNotificationClick: () => void;
};

function useBadgeCounterController(notifications: NotificationModel[] | null) {
    const [badgeCounter, setBadgeCounter] = React.useState<number>(0);

    const decrementBadgeCounter = React.useCallback(() => {
        setBadgeCounter((prev) => prev - 1);
    }, []);

    const countOpenNotifications = React.useCallback(() => {
        if (notifications && notifications.length) {
            let count = 0;
            notifications.forEach((notification) => {
                if (notification.clicked === 0) {
                    count += 1;
                }
            });
            setBadgeCounter(count);
        }
    }, [notifications]);

    React.useEffect(() => {
        countOpenNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    return { badgeCounter, decrementBadgeCounter };
}

function usePageIndexController() {
    const [pageIndex, setPageIndex] = React.useState<number>(0);

    const incrementPage = React.useCallback(() => {
        setPageIndex((prev) => prev + 1);
    }, []);

    const decrementPage = React.useCallback(() => {
        setPageIndex((prev) => prev - 1);
    }, []);

    return { pageIndex, incrementPage, decrementPage };
}

const Notifications: React.FC<RouteComponentProps> = (
    props: RouteComponentProps,
) => {
    const { t } = useTranslation();
    const { user, getAccessTokenSilently } = useAuth0();
    // Notifcations
    const configContext = React.useContext(ConfigContext);
    const { selectedAccount } = React.useContext(AccountContext);
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { pending, fetchData, notifications } = useNotificationsService(
        configContext,
        endpoints.inbox(selectedAccount?.accountnumber),
        token,
    );
    const { badgeCounter, decrementBadgeCounter } =
        useBadgeCounterController(notifications);
    const { pageIndex, incrementPage, decrementPage } =
        usePageIndexController();
    const { history } = props;

    React.useEffect(() => {
        fetchData();
    }, [fetchData, badgeCounter]);

    const clickHandler = React.useCallback(
        (notification: NotificationModel) => {
            const { id, action, clicked } = notification;
            // post read to backend
            if (clicked === 0) {
                decrementBadgeCounter();
                fetchNotificationStatus(
                    configContext,
                    endpoints.inboxPost(selectedAccount?.accountnumber),
                    id,
                    token,
                );
            }
            if (action) {
                // Check if action is external Url.
                if (/^https?:\/\//.test(action)) {
                    // external link
                    window.open(action, "_blank");
                } else {
                    // internal link
                    history.push(action);
                }
            }
        },
        [configContext, decrementBadgeCounter, history, token, selectedAccount],
    );
    const notificationArrays = React.useMemo(() => {
        if (!notifications || !notifications.length) {
            return null;
        }

        const notificationsWithHandlers: NotificationModelWithHandlers[] =
            notifications.map((notification) => ({
                ...notification,
                handleNotificationClick: () => clickHandler(notification),
            }));

        return splitIntoSmallerArrays(notificationsWithHandlers, 5);
    }, [clickHandler, notifications]);

    return (
        <Card dark className={$.container}>
            {pending && <Spinner />}
            {!pending && (
                <>
                    <header className={$.header}>
                        <div className={$.titleContainer}>
                            <h3 className={$.title}>{t("notifications")}</h3>
                            {badgeCounter > 0 && (
                                <span className={$.badge}>{badgeCounter}</span>
                            )}
                        </div>
                    </header>

                    {notificationArrays && notificationArrays.length > 0 ? (
                        <>
                            <ul className={$.notifcationsList}>
                                {notificationArrays[pageIndex].map(
                                    (notification) => (
                                        <Notification
                                            key={notification.id}
                                            type={notification.activityType}
                                            title={notification.subject}
                                            text={notification.description}
                                            date={notification.activityDate}
                                            read={notification.clicked > 0}
                                            onClick={
                                                notification.handleNotificationClick
                                            }
                                            important={
                                                notification.priority === "Hoog"
                                            }
                                        />
                                    ),
                                )}
                            </ul>

                            {notificationArrays.length > 1 && (
                                <footer className={$.buttons}>
                                    <button
                                        type="button"
                                        className={classnames(
                                            $.button,
                                            $.buttonPrev,
                                        )}
                                        onClick={decrementPage}
                                        disabled={pageIndex <= 0}
                                    >
                                        <img
                                            src="/static/images/ic-next.svg"
                                            alt="prev"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className={$.button}
                                        onClick={incrementPage}
                                        disabled={
                                            pageIndex >=
                                            notificationArrays.length - 1
                                        }
                                    >
                                        <img
                                            src="/static/images/ic-next.svg"
                                            alt="next"
                                        />
                                    </button>
                                </footer>
                            )}
                        </>
                    ) : (
                        <p className={$.nothingFound}>Geen berichten.</p>
                    )}
                </>
            )}
        </Card>
    );
};

export default withRouter(Notifications);
