import { isString } from "utils/checkType";
import { Faq } from "../models";
import { ModuleFaq } from "../entities";

export default function createFaq(data: ModuleFaq): Faq {
    const { moduleType, faqs } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        colorBackground,
        faqs,
    };
}
