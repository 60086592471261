import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { account } from "core/constants";
import * as React from "react";
import { createRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown, Button, Link } from "view/components";
import { AccountContext } from "view/context/AccountContext";
import ConfigContext from "view/context/ConfigContext";
import SettingsContext from "view/context/SettingsContext";
import useAccountService from "view/hooks/useAccountService";
import useTokenService from "view/hooks/useTokenService";
import AccountSwitch from "../AccountSwitch";
import $ from "./LoginPanel.module.scss";

const LoginPanel = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const dialog = createRef<HTMLDivElement>();
    const [loginPanelOpen, setLoginPanelOpen] = useState(false);
    const { isAuthenticated, logout } = useAuth0();
    const settingsContext = useContext(SettingsContext);

    const { t } = useTranslation();
    const handleClickOutsideLogin = (e) => {
        if (dialog.current && dialog.current.contains(e.target)) {
            return;
        }
        setLoginPanelOpen(false);
    };

    const configContext = useContext(ConfigContext);
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { getAccounts, accounts } = useAccountService(configContext, token);
    const { selectedAccount, setSelectedAccount, clearSelectedAccount } =
        useContext(AccountContext);

    useEffect(() => {
        getAccounts();
    }, [getAccounts]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideLogin);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideLogin);
        };
    });

    useEffect(() => {
        if (selectedAccount == null) {
            const firstaccountnumberfromclaim =
                user && user["https://www.vrumona.nl/crm"]?.accountnumber[0];

            // If no account is selected yet, select the first account from the claim.
            setSelectedAccount(
                accounts.find(
                    (a) => a.accountnumber === firstaccountnumberfromclaim,
                ) || accounts[0],
            );
        }
    }, [user, accounts, selectedAccount, setSelectedAccount]);

    return (
        <section className={$.panel} ref={dialog}>
            <button
                type="submit"
                className={classnames([
                    $.toggleButton,
                    loginPanelOpen && $.toggleButtonActive,
                ])}
                onClick={() => {
                    setLoginPanelOpen(!loginPanelOpen);
                }}
            >
                <span>
                    {selectedAccount ? selectedAccount.name : t("welcome")}
                </span>
                <ArrowDown
                    active={loginPanelOpen}
                    baseColor="white"
                    activeColor="turquoise"
                />
            </button>
            {loginPanelOpen && (
                <div className={$.login}>
                    {isAuthenticated && (
                        <>
                            <ul className={$.linksList}>
                                {settingsContext &&
                                    settingsContext.accountMenu.map((item) => (
                                        <li
                                            key={item.id}
                                            className={$.listItem}
                                        >
                                            <Link
                                                to={
                                                    item.path ||
                                                    item.externalUrl ||
                                                    item.slug
                                                }
                                                className={$.link}
                                            >
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                            {accounts && accounts.length > 0 && (
                                <AccountSwitch Accounts={accounts} />
                            )}
                        </>
                    )}
                    {isAuthenticated && account.logoutButton && (
                        <div className={$.loginButtons}>
                            <Button
                                {...account.logoutButton}
                                color="white"
                                // small
                                onClick={(event) => {
                                    event.preventDefault();
                                    clearSelectedAccount();
                                    logout({
                                        returnTo: window.location.origin,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};

export default LoginPanel;
