import { isImage, isString } from "utils/checkType";
import createMenuItem from "core/mappers/createMenuItem";
import { Init } from "../models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function createInit(data: Entity): Init {
    if (!data) throw new Error("no data");

    const title =
        data.configuration &&
        data.configuration.title &&
        isString(data.configuration.title);
    const seoDescription =
        data.configuration &&
        data.configuration.seoDescription &&
        isString(data.configuration.seoDescription);
    const seoKeywords =
        data.configuration &&
        data.configuration.seoKeywords &&
        isString(data.configuration.seoKeywords);
    const homepageSlug =
        data.configuration &&
        data.configuration.homepageSlug &&
        isString(data.configuration.homepageSlug);
    const categoryParentSlug =
        data.configuration &&
        data.configuration.categoryParentSlug &&
        isString(data.configuration.categoryParentSlug);
    const uspBar =
        data.usbBarReferences &&
        data.usbBarReferences.map((usp) => ({
            id: isString(usp.id),
            title: usp.title && isString(usp.title),
            image: usp.icon && isImage(usp.icon),
        }));

    return {
        configuration: {
            title,
            seoDescription,
            seoKeywords,
            homepageSlug,
            categoryParentSlug,
        },
        mainMenu:
            data.mainMenu &&
            data.mainMenu.map((menuItem) => createMenuItem(menuItem)),
        footerMenu:
            data.footerMenu &&
            data.footerMenu.map((menuItem) => createMenuItem(menuItem)),
        socialMenu:
            data.socialMenu &&
            data.socialMenu.map((menuItem) => createMenuItem(menuItem)),
        topMenu:
            data.topMenu &&
            data.topMenu.map((menuItem) => createMenuItem(menuItem)),
        accountMenu:
            data.accountMenu &&
            data.accountMenu.map((menuItem) => createMenuItem(menuItem)),
        uspBar,
    };
}
