import * as React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import $ from "./ArrowDown.module.scss";

interface Props {
    active: boolean;
    baseColor: string;
    activeColor: string;
    onClick?: () => void;
    className?: string;
}

const ArrowDown = (props: Props) => {
    const { active, baseColor, activeColor, onClick, className } = props;
    const wrapStyles = classnames([$.wrap, active && $.wrapActive]);
    const svgStyles = classnames([
        $.svg,
        baseColor === "white" && $.white,
        baseColor === "dark-blue" && $.darkBlue,
        baseColor === "turquoise" && $.turquoise,
        active && activeColor === "white" && $.whiteActive,
        active && activeColor === "dark-blue" && $.darkBlueActive,
        active && activeColor === "turquoise" && $.turquoiseActive,
        className && className,
    ]);
    const { t } = useTranslation();

    const arrow = (
        <svg viewBox="0 0 11 6" className={svgStyles}>
            <path
                d="M10.887.116a.384.384 0 0 0-.55 0L5.506 5.053.663.116a.384.384 0 0 0-.55 0 .402.402 0 0 0 0 .56l5.108 5.208c.076.077.17.116.275.116a.392.392 0 0 0 .275-.116L10.878.676a.394.394 0 0 0 .01-.56z"
                fillRule="nonzero"
            />
        </svg>
    );

    if (onClick) {
        return (
            <button
                className={wrapStyles}
                onClick={onClick}
                type="button"
                aria-label={t("openMenu")}
            >
                {arrow}
            </button>
        );
    }

    return <div className={wrapStyles}>{arrow}</div>;
};

export default ArrowDown;
