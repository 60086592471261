import {
    isString,
    isCategory,
    isImage,
    isButton,
    isObject,
} from "utils/checkType";
import { Article } from "../models";
import { Article as ArticleEntity } from "../entities";
import createModules from "./createModules";

export default function createArticle(data: ArticleEntity): Article {
    if (!data) throw new Error("no data");

    const id = isString(data.id);
    const title = isString(data.title);
    const slug = isString(data.slug);
    const path = data.path && isString(data.path);
    const seoDescription = data.seoDescription && isString(data.seoDescription);
    const seoKeywords = data.seoKeywords && isString(data.seoKeywords);
    const readTime = data.readTime && isString(data.readTime);
    const createdAt = data.createdAt && isString(data.createdAt);
    const intro = data.intro && isString(data.intro);
    const text = data.text && isString(data.text);
    const image = data.image && isImage(data.image);
    const categoryPrimary =
        data.categoryPrimary && isCategory(data.categoryPrimary);
    const categories =
        data.categories &&
        data.categories.map((category) => isCategory(category));
    const ctaPrimary = data.ctaPrimary && isButton(data.ctaPrimary);
    const ctaSecondary = data.ctaSecondary && isButton(data.ctaSecondary);
    const links = data.links && data.links.map((link) => isButton(link));
    const moduleReferences = data.modules && isObject(data.modules);
    const modules = createModules(moduleReferences);

    return {
        id,
        title,
        slug,
        path,
        seoDescription,
        seoKeywords,
        readTime,
        createdAt,
        intro,
        text,
        image,
        categoryPrimary,
        categories,
        ctaPrimary,
        ctaSecondary,
        links,
        modules,
    };
}
