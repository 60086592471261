import * as React from "react";
import { Video as VideoModel } from "core/models";
import { ModuleWrapper } from "../../components";
import $ from "./Video.module.scss";

const Video: React.FC<VideoModel> = (props: VideoModel) => {
    const { title, youtubeId, colorBackground } = props;
    if (!youtubeId) {
        return null;
    }

    return (
        <ModuleWrapper
            title={title}
            background={colorBackground}
            anchor="video"
        >
            <div className={$.container}>
                <div className={$.videoWrapper}>
                    <iframe
                        data-testid="youtubeVideo"
                        title={`Vrumona video - ${title}`}
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${youtubeId}?autoplay=0&enablejs=1&rel=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </div>
        </ModuleWrapper>
    );
};

export default Video;
