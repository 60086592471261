import { isString, isImage, isButton } from "utils/checkType";
import { Dashboard } from "../models";
import { ModuleDashboard } from "../entities";

export default function createDashboard(data: ModuleDashboard): Dashboard {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);
    const text = data.text && isString(data.text);
    const ctaTitle = data.ctaTitle && isString(data.ctaTitle);
    const ctaText = data.ctaText && isString(data.ctaText);
    const ctaImage = data.ctaImage && isImage(data.ctaImage);
    const ctaPrimary = data.ctaPrimary && isButton(data.ctaPrimary);
    const dataUri = data.dataUri && isString(data.dataUri);
    const postUri = data.postUri && isString(data.postUri);

    return {
        id,
        contentType,
        moduleType,
        title,
        text,
        ctaTitle,
        ctaText,
        ctaImage,
        ctaPrimary,
        dataUri,
        postUri,
    };
}
