import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import $ from "./Dialog.module.scss";

type Choice = {
    label: string;
    primary?: true;
    danger?: true;
    white?: true;
    onClick(): void;
};
type Props = {
    title: string;
    choices: Choice[];
    onCancel?: () => void;
};

const Dialog: React.FC<Props> = ({ title, children, choices, onCancel }) => {
    function getColor(choice: Choice) {
        if (choice.primary) {
            return "turquiose";
        }
        if (choice.danger) {
            return "red";
        }
        if (choice.white) {
            return "white";
        }
        return "green";
    }

    return (
        <Modal onCancel={onCancel}>
            <div className={$.dialog}>
                <header>
                    <h2 className={$.title}>{title}</h2>
                </header>
                <div className={$.body}>{children}</div>
                <footer className={$.choices}>
                    {choices.map((choice, i) => (
                        <Button
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            title={choice.label}
                            color={getColor(choice)}
                            onClick={choice.onClick}
                        />
                    ))}
                </footer>
            </div>
        </Modal>
    );
};
export default Dialog;
