import ModuleBase from "../entities/ModuleBase";
import { Image } from "./Image";

export interface FormOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    label: string;
}

export enum FormFieldType {
    Text = "text",
    Iban = "iban",
    Tel = "tel",
    Mobile = "mobile",
    Email = "email",
    Label = "label",
    Radio = "radio",
    Number = "number",
    Postalcode = "postalcode",
    Select = "select",
    Checkbox = "checkbox",
    Hidden = "hidden",
    Textarea = "textarea",
    File = "file",
    Date = "date",
    Paragraph = "paragraph",
    Info = "info",
}

export interface FormFields {
    name: string;
    type: FormFieldType;
    options?: FormOptions[];
    placeholder: string;
    label?: string;
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    visible?: boolean;
    autocomplete?: string;
    disabled?: boolean;
    newline?: boolean;
    customValidation?: (input: string) => string;
}

export interface Form extends ModuleBase {
    moduleType: "moduleContact";
    text: string;
    contactType:
        | "Customers"
        | "Leads"
        | "HorecaHeatmap"
        | "Contactform"
        | "Magnets";
    contactSubType?: string;
    postUri: string;
    fields?: FormFields[];
    image: Image;
}
