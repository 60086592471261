/* eslint-disable camelcase */
import { ThtData } from "core/models";

interface ThtProduct {
    amount: number;
    BestBeforeId: number;
    locationId: string;
    productId: string;
}

interface Wholesaler {
    locationId: string;
    name: string;
}

interface PutCoronaEntity {
    productsToReturns: ThtProduct[];
    wholesalers?: Wholesaler[];
}

export default function formatThtData(thtData: ThtData): PutCoronaEntity {
    const formattedProducts = thtData.productsToReturn
        .map((product) => {
            const hasLocation = thtData.locations.some(
                (location) => location.id === product.locationId,
            );

            if (!hasLocation || product.amount === 0) {
                return null;
            }

            return {
                amount: product.amount,
                locationId: product.locationId,
                BestBeforeId: product.bestBefore.id,
                productId: product.productId,
                final: product.final,
            };
        })
        .filter(Boolean) as ThtProduct[];

    const payload: PutCoronaEntity = {
        productsToReturns: formattedProducts,
    };

    const editedWholesalers = thtData.locations
        .filter(
            (location) =>
                !!location.editedWholesaler ||
                location.wholesaler !== location.editedWholesaler,
        )
        .map((location) => ({
            locationId: location.id,
            name: location.editedWholesaler,
        }));

    if (editedWholesalers.length > 0) {
        payload.wholesalers = editedWholesalers;
    }

    return payload;
}
