import { createContext } from "react";

export interface Background {
    backgroundColor?: string;
}

export type BackgroundColorType = Background | undefined;

const BackgroundColorContext = createContext<BackgroundColorType>(undefined);

export default BackgroundColorContext;

/* 
    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";


          <div
                    className={classnames([
                        $.textContainer,
                        colorBackground === "Grey"
                            ? undefined
                            : $.textContainerDark,
                    ])}
                >
*/
