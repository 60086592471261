import { FormFields, FormFieldType } from "core/models";

export function leadsFields(contactSubType?: string): FormFields[] {
    return [
        {
            type: FormFieldType.Hidden,
            name: "contactSubType",
            placeholder: contactSubType || "",
        },
        {
            type: FormFieldType.Label,
            name: "bedrijfsGegevensLabel",
            placeholder: "Bedrijfsgegevens",
        },
        {
            type: FormFieldType.Text,
            name: "companyname",
            placeholder: "Bedrijfsnaam",
            maxLength: 100,
            required: false,
            autocomplete: "organization",
        },
        {
            type: FormFieldType.Text,
            name: "street",
            placeholder: "Straat",
            maxLength: 250,
            required: false,
            autocomplete: "street-address",
        },
        {
            type: FormFieldType.Text,
            name: "housenumber",
            placeholder: "Huisnummer",
            maxLength: 250,
            required: false,
        },
        {
            type: FormFieldType.Postalcode,
            name: "postalcode",
            placeholder: "Postcode",
            maxLength: 7,
            required: true,
            autocomplete: "postal-code",
        },
        {
            type: FormFieldType.Text,
            name: "city",
            placeholder: "Plaats",
            maxLength: 100,
            required: false,
            autocomplete: "address-level2",
        },
        {
            type: FormFieldType.Label,
            name: "jouwGegevensLabel",
            placeholder: "Jouw gegevens",
        },
        {
            type: FormFieldType.Text,
            name: "firstname",
            placeholder: "Voornaam",
            maxLength: 100,
            required: true,
            autocomplete: "given-name",
        },
        {
            type: FormFieldType.Text,
            name: "lastname",
            placeholder: "Achternaam",
            maxLength: 100,
            required: false,
            autocomplete: "family-name",
        },
        {
            type: FormFieldType.Tel,
            name: "phone",
            placeholder: "Telefoonnummer",
            required: true,
            autocomplete: "tel",
        },
        {
            type: FormFieldType.Email,
            name: "email",
            placeholder: "E-mailadres",
            maxLength: 100,
            required: true,
            autocomplete: "email",
        },
        {
            type: FormFieldType.Textarea,
            name: "description",
            placeholder: "Ik heb interesse in ...",
            maxLength: 2000,
            required: false,
        },
        {
            type: FormFieldType.Checkbox,
            name: "agreement",
            placeholder:
                "Ik ga ermee akkoord dat Vrumona B.V. mij op basis van mijn voorkeuren en gedrag via online media en per e-mail van nieuws, acties en aanbiedingen voorziet over de merken en evenementen van Vrumona B.V. Ik kan mij op elk gewenst moment hiervoor afmelden. Zie meer info in de privacy voorwaarden van Vrumona.",
            required: false,
        },
    ];
}
