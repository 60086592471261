import { isString, isBool, isObject } from "utils/checkType";
import { MenuItemEntity } from "core/entities";
import { MenuItem } from "core/models/MenuItem";
// eslint-disable-next-line import/no-cycle
import createSubMenu from "./createSubMenu";

export default function createMenuItem(data: MenuItemEntity): MenuItem {
    const id = data.id && isString(data.id);
    const title = isString(data.title);
    const slug = data.slug && isString(data.slug);
    const path = data.path && isString(data.path);
    const icon = data.icon && isObject(data.icon);
    const externalUrl = data.externalUrl && isString(data.externalUrl);
    const menuHeading = data.header && isBool(data.header);
    const visibleTo = data.visibleTo && isObject(data.visibleTo);

    let subMenu;

    if (data.subMenu && data.subMenu.length > 0) {
        subMenu = createSubMenu(data.subMenu);
    }

    if (subMenu) {
        return {
            id,
            title,
            slug,
            path,
            externalUrl,
            menuHeading,
            subMenu,
            icon,
            contentType: data.contentType,
            visibleTo,
        };
    }

    return {
        id,
        title,
        slug,
        path,
        externalUrl,
        menuHeading,
        icon,
        contentType: data.contentType,
        visibleTo,
    };
}
