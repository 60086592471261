import * as React from "react";
import { useTranslation } from "react-i18next";
import { ModuleWrapper, Button, Head } from "..";
import $ from "./NotFound.module.scss";

const NotFound: React.FC = () => {
    const { t } = useTranslation();
    return (
        <ModuleWrapper title={t("pageNotFound")}>
            <>
                <Head subTitle={t("pageNotFound")} pageNotFound />
                <p>{t("youAreOnANonExistingPage")}</p>
                <Button
                    path="/"
                    title={t("goToHome")}
                    color="turquiose"
                    className={$.button}
                />
            </>
        </ModuleWrapper>
    );
};

export default NotFound;
