// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dataLayer: any;

const Analytics = {
    pageView(path: string, title?: string) {
        // TAG MANAGER
        dataLayer.push({
            event: "pageview",
            page: {
                path,
                title,
            },
        });
    },

    event(event, eventAction, eventLabel, eventValue?, eventCategory?) {
        // TAG MANAGER
        dataLayer.push({
            event,
            eventAction,
            eventLabel,
            eventValue,
            eventCategory,
        });
    },
};

export default Analytics;
