import { FormFields, FormFieldType, FormOptions } from "core/models";

export function customerFields(
    listOfBrands: FormOptions[],
    listOfTastes: FormOptions[],
    listOfPackaging: FormOptions[],
    showPersonalDetails = false,
    formType = "general",
    contactSubType?: string,
): FormFields[] {
    return [
        {
            type: FormFieldType.Hidden,
            name: "contactSubType",
            placeholder: contactSubType || "",
        },
        {
            type: FormFieldType.Text,
            name: "specialoffer",
            placeholder: "Over welke actie heb je een vraag?",
            required: true,
            visible: formType === "specialOffer",
        },
        {
            type: FormFieldType.Textarea,
            name: "description",
            placeholder: "Wat is je vraag of opmerking?",
            required: true,
            visible: formType === "specialOffer",
        },
        {
            type: FormFieldType.Textarea,
            name: "description",
            placeholder: "Wat is je vraag, tip of opmerking?",
            required: true,
            visible: formType === "general",
        },
        {
            type: FormFieldType.Select,
            name: "product",
            label: "Om welk merk gaat het?",
            placeholder: "Om welk merk gaat het?",
            required: true,
            options: listOfBrands,
            visible: formType === "product",
        },
        {
            type: FormFieldType.Select,
            name: "taste",
            placeholder: "Om welke smaak gaat het?",
            required: true,
            options: listOfTastes,
            visible: formType === "product",
        },
        {
            type: FormFieldType.Select,
            name: "packagingsize",
            placeholder: "Om welke verpakking gaat het?",
            required: true,
            options: listOfPackaging,
            visible: formType === "product",
        },
        {
            type: FormFieldType.Text,
            name: "expirationdate",
            placeholder: "Houdbaarheidsdatum",
            required: false,
            visible: formType === "product",
        },
        {
            type: FormFieldType.Text,
            name: "productioncode",
            placeholder:
                "Productiecode (deze is te vinden bij de houdbaarheidsdatum)",
            required: false,
            visible: formType === "product",
        },
        {
            type: FormFieldType.Text,
            name: "purchasespot",
            placeholder: "Waar is het product gekocht?",
            required: false,
            visible: formType === "product",
        },
        {
            type: FormFieldType.Textarea,
            name: "description",
            placeholder: "Opmerking",
            visible: formType === "product",
            required: true,
        },
        {
            type: FormFieldType.File,
            name: "attachment",
            placeholder: "Bijlage(s) toevoegen",
            required: false,
        },
        {
            type: FormFieldType.Radio,
            name: "pickupproduct",
            placeholder:
                "Ik heb dit product en deze mag kosteloos bij mij worden opgehaald.",
            required: true,
            options: [
                {
                    label: "Nee",
                    value: "no",
                },
                {
                    label: "Ja",
                    value: "yes",
                },
            ],
            visible: formType === "product",
        },
        {
            type: FormFieldType.Label,
            name: "labelPerson",
            placeholder: "Jouw gegevens",
        },
        {
            type: FormFieldType.Radio,
            name: "gender",
            placeholder: "Aanhef",
            required: true,
            options: [
                {
                    label: "De heer",
                    value: "Man",
                },
                {
                    label: "Mevrouw",
                    value: "Vrouw",
                },
            ],
        },
        {
            type: FormFieldType.Text,
            name: "firstname",
            placeholder: "Voornaam",
            required: true,
        },
        {
            type: FormFieldType.Text,
            name: "lastname",
            placeholder: "Achternaam",
            required: true,
        },
        {
            type: FormFieldType.Email,
            name: "email",
            placeholder: "E-mailadres",
            required: true,
        },
        {
            type: FormFieldType.Tel,
            name: "phone",
            placeholder: "Telefoonnummer",
            required: false,
        },
        {
            type: FormFieldType.Text,
            name: "street",
            placeholder: "Straat",
            required: true,
            visible: formType === "product" && showPersonalDetails,
        },
        {
            type: FormFieldType.Text,
            name: "housenumber",
            placeholder: "Huisnummer",
            required: true,
            visible: formType === "product" && showPersonalDetails,
        },
        {
            type: FormFieldType.Text,
            name: "housenumberaddition",
            placeholder: "Toevoeging",
            required: false,
            visible: formType === "product" && showPersonalDetails,
        },
        {
            type: FormFieldType.Postalcode,
            name: "postalcode",
            placeholder: "Postcode",
            maxLength: 7,
            required: true,
            visible: formType === "product" && showPersonalDetails,
        },
        {
            type: FormFieldType.Text,
            name: "city",
            placeholder: "Woonplaats",
            required: true,
            visible: formType === "product" && showPersonalDetails,
        },
        {
            type: FormFieldType.Text,
            name: "country",
            placeholder: "Land",
            required: true,
            visible: formType === "product" && showPersonalDetails,
        },
    ];
}
