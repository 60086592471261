import { ArticleReferenceEntity } from "../entities";
import { ArticleReference } from "../models";
import createArticleReference from "./createArticleReference";

export default function createArticles(
    data: ArticleReferenceEntity[] | null,
): ArticleReference[] {
    let articleReferences: ArticleReference[] = [];

    if (data && data.length) {
        articleReferences = data.map((article) =>
            createArticleReference(article),
        );
    }

    return articleReferences;
}
