import axios from "axios";

export default async function fetchContactProducts(
    apiUrl,
    subscriptionKey,
    website,
) {
    const url = `${apiUrl}/contacts/products?website=${website}`;
    const contactProducts = await axios(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey,
        },
    });
    return contactProducts;
}
