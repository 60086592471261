import { useCallback } from "react";
import { useLocation } from "react-router";
import history from "utils/history";

export type ParamOptions = {
    push?: boolean;
};

export function replaceParam(
    name: string,
    value: string,
    options: ParamOptions = {},
) {
    const search = new URLSearchParams(window.location.search);
    search.set(name, value);
    const method = options.push ? "push" : "replace";
    history[method](`${window.location.pathname}?${search}`);
}

export function removeParam(name: string, options: ParamOptions = {}) {
    const search = new URLSearchParams(window.location.search);
    search.delete(name);
    const method = options.push ? "push" : "replace";
    history[method](`${window.location.pathname}?${search}`);
}

/**
 * API is similar to `useState<string>()` but stores the state in the url.
 * When the parameter is not in the url the value is `undefined` unless a defaultValue is provided.
 *
 * Usage:
 *  const [query, setQuery] = useParam('q', '')
 *
 * or
 *
 * const [flag, setFlag, removeFlag] = useUrlParam('flag')
 *
 */
export default function useParam<T extends string | undefined>(
    name: string,
    defaultValue?: T,
): [
    string | T,
    (val: string, opts?: ParamOptions) => void,
    (opts?: ParamOptions) => void,
] {
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const value = search.get(name);

    return [
        typeof value === "string" ? value : (defaultValue as T),
        useCallback((val, options) => replaceParam(name, val, options), [name]),
        useCallback((options) => removeParam(name, options), [name]),
    ];
}
