import classnames from "classnames";
import { MOBILE } from "core/constants";
import { ReviewBlock as ReviewBlockModel } from "core/models";
import * as React from "react";
import { useWindowWidth } from "utils/useWindowWidth";
import { Button, Image, ModuleWrapper } from "view/components";
import $ from "./ReviewBlock.module.scss";

export function CurvedTriangleClipPath() {
    return (
        <svg width="0" height="0">
            <defs>
                <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
                    <path
                        d="M 0,1
                                    C 0,1  0.15,1  0.5,0.5
                                    C 0.5,0.5  0.85,0  1,0
                                    L 1,1
                                    Z
                                    "
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

const ReviewBlock: React.FC<ReviewBlockModel> = (props) => {
    const {
        authorLine1,
        authorLine2,
        cta,
        imageDesktop,
        imageMobile,
        showQuote,
        title,
    } = props;

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE;
    const imgWidth = isMobile ? 750 : 1272;
    const image = isMobile ? imageMobile : imageDesktop;

    return (
        <ModuleWrapper>
            <article className={$.container}>
                {image && image.url && (
                    <div className={$.imageContainer}>
                        <Image
                            placeholder={`${image.url}?w=30&q=50&fm=jpg&fl=progressive`}
                            src={`${image.url}?w=${imgWidth}&q=75&fm=jpg&fl=progressive`}
                            className={$.image}
                            alt={image.title}
                        />
                    </div>
                )}
                <CurvedTriangleClipPath />

                <span className={$.backgroundBubble} />
                <span className={$.backgroundBubble} />

                <div
                    className={classnames(
                        $.textContainer,
                        showQuote && $.textContainerHasQuote,
                    )}
                >
                    <div className={$.margin}>
                        {title && (
                            <h2
                                className={classnames(
                                    $.title,
                                    showQuote && $.titleHasQuote,
                                )}
                            >
                                {title}
                            </h2>
                        )}

                        {cta && (
                            <Button
                                color="turquiose"
                                title={cta.title}
                                path={cta.slug || cta.path || cta.externalUrl}
                                className={$.button}
                            />
                        )}
                    </div>

                    {(authorLine1 || authorLine2) && (
                        <p>
                            {authorLine1 && (
                                <span className={$.label}>{authorLine1}</span>
                            )}
                            {authorLine2 && (
                                <span
                                    className={classnames($.label, $.labelBold)}
                                >
                                    {authorLine2}
                                </span>
                            )}
                        </p>
                    )}
                </div>
            </article>
        </ModuleWrapper>
    );
};

export default ReviewBlock;
