/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormBackEndModel } from "core/models/FormBackEndModel";
import { ConfigContextType } from "view/context/ConfigContext";
import fetchUrl from "./fetchUrl";

export async function sendForm(
    config: ConfigContextType,
    endpoint: string,
    body: FormBackEndModel | any,
): Promise<any> {
    try {
        const response = await fetchUrl<any>({
            config,
            endpoint,
            body,
            method: "POST",
        });

        if (
            response.status &&
            response.status !== 200 &&
            response.status !== 201 &&
            response.status !== 204
        ) {
            const { statusText } = response;
            return {
                ok: false,
                error: statusText,
            };
        }
        return {
            ok: response || true,
            error: undefined,
        };
    } catch (err) {
        return {
            ok: false,
            error: err,
        };
    }
}
