import { useAuth0 } from "@auth0/auth0-react";
import { account, paths } from "core/constants";
import { ModuleType } from "core/models";
import { Location } from "history";
import * as React from "react";
import { useLocation } from "react-router";
import { Button, ModuleWrapper } from "view/components";
import $ from "./Login.module.scss";

type MyLocationState = Location & {
    from: string;
    search: string;
};

const Login: React.FC<ModuleType> = (props: ModuleType) => {
    const { title } = props;
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    const location = useLocation<MyLocationState>();

    // Get the requested location from the state, that was stored before the redirect to login page.
    const { from, search } = location.state || {
        from: paths.dashboard,
        search: "",
    };
    const targetUrl = [from, search].join("");

    return (
        <ModuleWrapper title={title}>
            <>
                {isAuthenticated && (
                    <Button
                        {...account.dashboardButton}
                        color="turquiose"
                        className={$.button}
                    />
                )}
                {!isAuthenticated && (
                    <Button
                        {...account.loginButton}
                        color="turquiose"
                        className={$.button}
                        onClick={(event) => {
                            event.preventDefault();

                            // Store the requested location uri in the state, so we can redirect the user to the requested page.
                            loginWithRedirect({
                                appState: { targetUrl },
                            });
                        }}
                    />
                )}
            </>
        </ModuleWrapper>
    );
};

export default Login;
