import { Button as ButtonModel, Support as SupportModel } from "core/models";
import * as React from "react";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { Body, Button, CardColumn, ModuleWrapper } from "view/components";
import { GridRow } from "view/components/Grid";
import $ from "./Support.module.scss";

function getColumnContent(
    text: string,
    cta: ButtonModel | undefined,
    colorBackground: string | undefined,
) {
    if (!text) {
        return null;
    }

    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";

    return (
        <CardColumn stretch spaceBetween dark={lightBackground}>
            <>
                <Body content={text} />
                {cta && cta.title && MenuItemIsVisible(cta) && (
                    <Button
                        title={cta.title}
                        path={cta.path || cta.slug || cta.externalUrl}
                        color="turquiose"
                        className={$.button}
                    />
                )}
            </>
        </CardColumn>
    );
}

const Support: React.FC<SupportModel> = (props: SupportModel) => {
    const {
        title,
        text1,
        text2,
        text3,
        text1Cta,
        text2Cta,
        text3Cta,
        colorBackground,
    } = props;

    const contentColumn1 = getColumnContent(text1, text1Cta, colorBackground);
    const contentColumn2 = getColumnContent(text2, text2Cta, colorBackground);
    const contentColumn3 = getColumnContent(text3, text3Cta, colorBackground);

    return (
        <ModuleWrapper
            title={title}
            background={colorBackground}
            anchor="support"
        >
            <GridRow>
                <>
                    {contentColumn1}
                    {contentColumn2}
                    {contentColumn3}
                </>
            </GridRow>
        </ModuleWrapper>
    );
};

export default Support;
