import { isString } from "utils/checkType";
import { ModuleHtmlCode } from "../entities";
import { HtmlCode } from "../models";

export default function createHtmlCode(data: ModuleHtmlCode): HtmlCode {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const html = data.html && isString(data.html);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        html,
        colorBackground,
    };
}
