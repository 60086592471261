import parseJSON from "date-fns/parseJSON";
import { isNum, isObject, isString } from "utils/checkType";
import {
    SwoDocument,
    IndirectDiscount,
    Location,
    PromotionalBudget,
    SalesContract,
    SalesContractData,
    SalesdependantContribution,
    SalesindependentContribution,
    VendingEquipment,
} from "../entities";
import {
    SwoDocument as SwoDocumentModel,
    IndirectDiscount as IndirectDiscountModel,
    IndirectDiscountLine as IndirectDiscountLineModel,
    Location as LocationModel,
    PromotionalBudget as PromotionalBudgetModel,
    SalesContract as SalesContractModel,
    SalesContractData as SalesContractDataModel,
    SalesdependantContribution as SalesdependantContributionModel,
    SalesindependentContribution as SalesindependentContributionModel,
    VendingEquipment as VendingEquipmentModel,
    GraduatedDiscount as GraduatedDiscountModel,
    GraduatedContribution as GraduatedContributionModel,
} from "../models";
import {
    IndirectDiscountLine,
    GraduatedDiscount,
} from "../entities/IndirectDiscount";
import { GraduatedContribution } from "../entities/SalesdependantContribution";

const createLocation = (data: Location): LocationModel => ({
    id: (data.id && isString(data.id)) || "0",
    accountnumber: data.accountnumber && isString(data.accountnumber),
    name: data.name && isString(data.name),
    address1Number: data.address1Number && isString(data.address1Number),
    address1PostalCode:
        data.address1PostalCode && isString(data.address1PostalCode),
    address1Street: data.address1Street && isString(data.address1Street),
    address1City: data.address1City && isString(data.address1City),
    address1Phone: data.address1Phone && isString(data.address1Phone),
    address1Latitude: data.address1Latitude && isString(data.address1Latitude),
    address1Longitude:
        data.address1Longitude && isString(data.address1Longitude),
    wholesaler: data.wholesaler && isString(data.wholesaler),
});

const createDocument = (data: SwoDocument): SwoDocumentModel => ({
    filename: data.filename && isString(data.filename),
    id: (data.id && isString(data.id)) || "0",
    name: data.name && isString(data.name),
    url: data.url && isString(data.url),
});

const createVendingEquipment = (
    data: VendingEquipment,
): VendingEquipmentModel => ({
    id: data.id && isString(data.id),
    contractValue: data.contractValue && isNum(data.contractValue),
    dateOfPlacement:
        (data.dateOfPlacement &&
            parseJSON(data.dateOfPlacement) &&
            new Date(data.dateOfPlacement)) ||
        new Date(),
    serialkey: data.serialkey && isString(data.serialkey),
    equipmentcategory:
        data.equipmentcategory && isString(data.equipmentcategory),
    posReference: data.posReference && isString(data.posReference),
    servicepartner: data.servicepartner && isString(data.servicepartner),
    reference: data.reference && isString(data.reference),
    typeOfContractname:
        data.typeOfContractname && isString(data.typeOfContractname),
    vendingequipmentType:
        data.vendingequipmentType && isString(data.vendingequipmentType),
});

const createGraduates = (data: GraduatedDiscount): GraduatedDiscountModel => ({
    id: data.id && isString(data.id),
    discountAmount: data.discountAmount && isNum(data.discountAmount),
    numberUntill: data.numberUntill && isString(data.numberUntill),
});

const createIndirectDiscountLine = (
    data: IndirectDiscountLine,
): IndirectDiscountLineModel => ({
    discountamount: data.discountamount && isNum(data.discountamount),
    id: data.id && isString(data.id),
    levelidName: data.levelidName && isString(data.levelidName),
    sublevelidName: data.sublevelidName && isString(data.sublevelidName),
    graduates:
        data.graduates &&
        isObject(data.graduates) &&
        data.graduates.map((graduates) => createGraduates(graduates)),
});

const createIndirectDiscount = (
    data: IndirectDiscount,
): IndirectDiscountModel => ({
    id: data.id && isString(data.id),
    durationName: data.durationName && isString(data.durationName),
    endDate:
        (data.endDate && parseJSON(data.endDate) && new Date(data.endDate)) ||
        new Date(),
    hlOrColloName: data.hlOrColloName && isString(data.hlOrColloName),
    indirectDiscountLines:
        data.indirectDiscountLines &&
        isObject(data.indirectDiscountLines) &&
        data.indirectDiscountLines.map((indirectDiscountLine) =>
            createIndirectDiscountLine(indirectDiscountLine),
        ),
    number: data.number && isString(data.number),
    name: data.name && isString(data.name),
    settledTill:
        (data.settledTill &&
            parseJSON(data.settledTill) &&
            new Date(data.settledTill)) ||
        new Date(),
    startDate:
        (data.startDate &&
            parseJSON(data.startDate) &&
            new Date(data.startDate)) ||
        new Date(),
    pointofsale: data.pointofsale && isString(data.pointofsale),
});

const createPromotionalBudget = (
    data: PromotionalBudget,
): PromotionalBudgetModel => ({
    id: data.id && isString(data.id),
    name: data.name && isString(data.name),
    reference: data.reference && isString(data.reference),
    amount: data.amount && isNum(data.amount),
    claimablePerName: data.claimablePerName && isString(data.claimablePerName),
    durationName: data.durationName && isString(data.durationName),
    endDate:
        (data.endDate && parseJSON(data.endDate) && new Date(data.endDate)) ||
        new Date(),
    startDate:
        (data.startDate &&
            parseJSON(data.startDate) &&
            new Date(data.startDate)) ||
        new Date(),
});

const createContributionGraduates = (
    data: GraduatedContribution,
): GraduatedContributionModel => ({
    id: data.id && isString(data.id),
    amount: data.amount && isNum(data.amount),
    numberUntill: data.numberUntill && isString(data.numberUntill),
});

const createSalesdependantContribution = (
    data: SalesdependantContribution,
): SalesdependantContributionModel => ({
    id: data.id && isString(data.id),
    name: data.name && isString(data.name),
    reference: data.reference && isString(data.reference),
    amount: data.amount && isNum(data.amount),
    endDate:
        (data.endDate && parseJSON(data.endDate) && new Date(data.endDate)) ||
        new Date(),
    startDate:
        (data.startDate &&
            parseJSON(data.startDate) &&
            new Date(data.startDate)) ||
        new Date(),
    settledTill:
        (data.settledTill &&
            parseJSON(data.settledTill) &&
            new Date(data.settledTill)) ||
        new Date(),
    graduates:
        data.graduates &&
        isObject(data.graduates) &&
        data.graduates.map((graduates) =>
            createContributionGraduates(graduates),
        ),
});

const createSalesindependentContribution = (
    data: SalesindependentContribution,
): SalesindependentContributionModel => ({
    id: data.id && isString(data.id),
    name: data.name && isString(data.name),
    reference: data.reference && isString(data.reference),
    amount: data.amount && isNum(data.amount),
    endDate:
        (data.endDate && parseJSON(data.endDate) && new Date(data.endDate)) ||
        new Date(),
    startDate:
        (data.startDate &&
            parseJSON(data.startDate) &&
            new Date(data.startDate)) ||
        new Date(),
    durationName: data.durationName && isString(data.durationName),
    invoicePerName: data.invoicePerName && isString(data.invoicePerName),
    paymentPerName: data.paymentPerName && isString(data.paymentPerName),
});

function createSalesContract(data: SalesContract): SalesContractModel {
    const id = data.id && isString(data.id);
    const salescontractnumber =
        data.salescontractnumber && isString(data.salescontractnumber);
    const contractor1 = data.contractor1 && isString(data.contractor1);
    const contractor2 = data.contractor2 && isString(data.contractor2);
    const contractor3 = data.contractor3 && isString(data.contractor3);

    const documents =
        data.documents &&
        isObject(data.documents) &&
        data.documents.map((document) => createDocument(document));

    const endDate =
        (data.endDate && parseJSON(data.endDate) && new Date(data.endDate)) ||
        new Date();
    const startDate =
        (data.startDate &&
            parseJSON(data.startDate) &&
            new Date(data.startDate)) ||
        new Date();

    const indirectDiscounts =
        data.indirectDiscount &&
        isObject(data.indirectDiscount) &&
        data.indirectDiscount.map((indirectDiscount) =>
            createIndirectDiscount(indirectDiscount),
        );

    const locations =
        data.location &&
        isObject(data.location) &&
        data.location.map((location) => createLocation(location));

    const vendingEquipments =
        data.vendingEquipment &&
        isObject(data.vendingEquipment) &&
        data.vendingEquipment.map((vendingEquipment) =>
            createVendingEquipment(vendingEquipment),
        );

    const promotionalBudgets =
        data.promotionalBudget &&
        isObject(data.promotionalBudget) &&
        data.promotionalBudget.map((promotionalBudget) =>
            createPromotionalBudget(promotionalBudget),
        );

    const salesdependantContributions =
        data.salesdependantContribution &&
        isObject(data.salesdependantContribution) &&
        data.salesdependantContribution.map((salesdependantContribution) =>
            createSalesdependantContribution(salesdependantContribution),
        );

    const salesindependentContributions =
        data.salesindependentContribution &&
        isObject(data.salesindependentContribution) &&
        data.salesindependentContribution.map((salesindependentContribution) =>
            createSalesindependentContribution(salesindependentContribution),
        );

    const name = data.name && isString(data.name);

    return {
        salescontractnumber,
        contractor1,
        contractor2,
        contractor3,
        documents,
        endDate,
        id,
        indirectDiscount: indirectDiscounts,
        location: locations,
        name,
        startDate,
        vendingEquipment: vendingEquipments,
        promotionalBudget: promotionalBudgets,
        salesdependantContribution: salesdependantContributions,
        salesindependentContribution: salesindependentContributions,
    };
}

export default function createSalesContractData(
    data: SalesContractData,
): SalesContractDataModel {
    const { swo } = data;

    const id = isString(data.id);

    const salesContracts: SalesContractModel[] =
        (swo &&
            Array.isArray(swo) &&
            swo.map((salesContract) => createSalesContract(salesContract))) ||
        [];

    return {
        id,
        swo: salesContracts,
    };
}
