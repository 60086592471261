/* eslint-disable jsx-a11y/label-has-for */
import * as React from "react";
import classnames from "classnames";
import { Fragment } from "react";
import { FormFields, FormFieldType } from "core/models";
import $ from "./ReadOnlyFormFieldset.module.scss";
import { GridRow, GridColumn } from "../Grid";

interface Props {
    title?: string;
    fields: FormFields[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSource: any;
}

const ReadOnlyFormFieldset = (props: Props) => {
    const { dataSource, title, fields } = props;

    return (
        <fieldset className={$.base}>
            {title && <h3 className={$.titleDisplay}>{title}</h3>}

            <dl>
                {Array.isArray(fields) &&
                    fields
                        .filter(
                            (field) =>
                                field.type === FormFieldType.Label ||
                                field.type === FormFieldType.Info ||
                                field.type === FormFieldType.Paragraph ||
                                dataSource[field.name] !== undefined,
                        )
                        .map((field) => {
                            const {
                                type,
                                options,
                                name,
                                placeholder,
                                visible,
                                newline,
                            } = field;
                            const isVisible =
                                visible === undefined ? true : visible;

                            if (
                                type === FormFieldType.Radio &&
                                options &&
                                isVisible
                            ) {
                                return (
                                    <Fragment key={name}>
                                        <dt
                                            className={classnames([
                                                newline && "newline",
                                            ])}
                                        >
                                            {field.placeholder}
                                        </dt>

                                        <dd
                                            className={classnames([
                                                newline && "newline",
                                            ])}
                                        >
                                            {
                                                options.find(
                                                    (o) =>
                                                        o.value ===
                                                        dataSource[name],
                                                )?.label
                                            }
                                        </dd>
                                    </Fragment>
                                );
                            }
                            if (type === FormFieldType.Label && isVisible) {
                                return (
                                    <GridRow
                                        key={name}
                                        className={$.inputWrapDisplay}
                                    >
                                        <GridColumn className={$.noPadding}>
                                            <h3 className={$.subtitle}>
                                                {placeholder}
                                            </h3>
                                        </GridColumn>
                                    </GridRow>
                                );
                            }
                            if (type === FormFieldType.Info && isVisible) {
                                return (
                                    <GridRow
                                        key={name}
                                        className={$.inputWrapDisplay}
                                    >
                                        <GridColumn className={$.noPadding}>
                                            <div className={$.infoWrapper}>
                                                <img
                                                    aria-label="success"
                                                    alt="success"
                                                    src="/static/images/ic-checkmark.svg"
                                                />
                                                <p className={$.infoText}>
                                                    {placeholder}
                                                </p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                );
                            }
                            if (type === FormFieldType.Paragraph && isVisible) {
                                return (
                                    <GridRow
                                        key={name}
                                        className={$.inputWrapDisplay}
                                    >
                                        <GridColumn className={$.noPadding}>
                                            <p className={$.infoText}>
                                                {placeholder}
                                            </p>
                                        </GridColumn>
                                    </GridRow>
                                );
                            }
                            if (!isVisible) {
                                return null;
                            }
                            return (
                                <Fragment key={name}>
                                    <dt
                                        className={classnames([
                                            newline && "newline",
                                        ])}
                                    >
                                        {field.placeholder}
                                    </dt>
                                    <dd
                                        className={classnames([
                                            newline && "newline",
                                        ])}
                                        data-type={type}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: dataSource[name],
                                        }}
                                    />
                                </Fragment>
                            );
                        })}
            </dl>
        </fieldset>
    );
};

export default ReadOnlyFormFieldset;
