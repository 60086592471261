import * as React from "react";
import classnames from "classnames";
import $ from "./Notification.module.scss";

type Props = {
    type: string;
    title: string;
    text: string;
    date: Date;
    read?: boolean;
    important?: boolean;
    onClick: () => void;
};

function getTypeIconSrc(notificationType: string) {
    switch (notificationType) {
        case "appointment":
            return "/static/images/ic-calendar.svg";
        case "iban_wijziging":
        case "profile":
            return "/static/images/ic-edit-profile.svg";
        case "mijnvrumona_bericht":
            return "/static/images/ic-message.svg";
        case "invoice":
            return "/static/images/ic-invoice.svg";
        case "ava_bonusapprovalcustomer":
        case "bonus":
            return "/static/images/ic-bonus.svg";
        default:
            return "/static/images/ic-message.svg";
    }
}

const Notification: React.FC<Props> = (props: Props) => {
    const { type, title, onClick, text, date, read, important } = props;
    const [readState, setReadState] = React.useState<boolean>(!!read);
    const notificationStyle = classnames([
        $.notification,
        !readState && $.notificationHighlight,
    ]);

    const notificationClickHandler = React.useCallback(() => {
        setReadState(true);
        onClick();
    }, [onClick]);

    return (
        <li className={notificationStyle}>
            <button
                onClick={notificationClickHandler}
                className={$.link}
                type="button"
            >
                {!readState && <span className={$.highlight} />}
                <span className={$.iconContainer}>
                    <img src={getTypeIconSrc(type)} alt={type} />
                </span>
                <span className={$.textContainer}>
                    <h4>{title}</h4>
                    {text && <p className={$.text}>{text}</p>}
                    {date && (
                        <small className={$.date}>
                            {date.toLocaleDateString("nl-NL")}
                        </small>
                    )}
                </span>
                <span
                    className={classnames([
                        $.iconContainer,
                        $.iconContainerReverse,
                    ])}
                >
                    {important && !read && (
                        <img src="/static/images/ic-warning.svg" alt="" />
                    )}

                    {/* temp: disable appointment download icon */}
                    {/* {type === 'appointment' && (
                        <img src="/static/images/ic-download.svg" alt="" />
                    )} */}

                    {/* TODO: do we need to enable the checkmark again or remove it? */}
                    {/* {read && <img src="/static/images/ic-check.svg" alt="" />} */}
                </span>
            </button>
        </li>
    );
};

export default Notification;
