import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";
import { createNotifications } from "../mappers";
import { Notification } from "../models";

export default async function fetchNotifications(
    config: ConfigContextType,
    endpoint: string,
    token: string,
) {
    const customHeaders = { "Cache-Control": "no-cache" };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await fetchUrl<any>({
        config,
        endpoint,
        body: undefined,
        token,
        customHeaders,
    });

    if (result && result.inboxpointers) {
        return createNotifications(result) as Notification[];
    }

    return result;
}
