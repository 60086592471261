import { useAuth0 } from "@auth0/auth0-react";
import { MOBILE } from "core/constants/global";
import { Dashboard as DashboardType } from "core/models";
import * as React from "react";
import { useWindowWidth } from "utils/useWindowWidth";
import {
    Body,
    ModuleWrapper,
    Notifications,
    Button,
    Card,
    Image,
} from "view/components";
import $ from "./Dashboard.module.scss";

const Dashboard: React.FC<DashboardType> = (props: DashboardType) => {
    const { user } = useAuth0();
    const { text, ctaImage, ctaPrimary, ctaText, ctaTitle } = props;

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE;
    const heroImage = isMobile
        ? "/static/images/mijnvrumona_dashboard_mobile.png"
        : "/static/images/mijnvrumona_dashboard_desktop.png";

    return (
        <ModuleWrapper noMargin>
            <section className={$.wrapper}>
                {heroImage && (
                    <Card dark className={$.welcome}>
                        <Image src={heroImage} alt="Alt" className={$.image} />
                    </Card>
                )}

                <section className={$.flex}>
                    <Card padding dark className={$.welcome}>
                        <h3>
                            Welkom
                            {user && user.given_name && ` ${user.given_name}`},
                        </h3>
                        {text && <Body content={text} />}
                    </Card>

                    <Card padding dark className={$.special}>
                        <div className={$.textContainer}>
                            {ctaTitle && <h3>{ctaTitle}</h3>}
                            {ctaText && (
                                <Body content={ctaText} className={$.body} />
                            )}
                            {ctaPrimary && (
                                <Button
                                    className={$.button}
                                    title={ctaPrimary.title}
                                    path={ctaPrimary.path}
                                    color="turquiose"
                                />
                            )}
                        </div>
                        <div className={$.imageContainer}>
                            {ctaImage && (
                                <Image
                                    src={ctaImage.url}
                                    alt={ctaImage.title}
                                    className={$.image}
                                />
                            )}
                        </div>
                    </Card>
                </section>

                <Notifications />
            </section>
        </ModuleWrapper>
    );
};

export default Dashboard;
