import { isString, isImage, isButton } from "utils/checkType";
import { ModuleBrand } from "../entities";
import { Brand as BrandModel } from "../models";

export default function createBrand(data: ModuleBrand): BrandModel {
    const { id, moduleType } = data;
    const bottleImages = [
        {
            image: data.imageBottle1 && isImage(data.imageBottle1),
            title: data.titleBottle1 && isString(data.titleBottle1),
        },
        {
            image: data.imageBottle2 && isImage(data.imageBottle2),
            title: data.titleBottle2 && isString(data.titleBottle2),
        },
        {
            image: data.imageBottle3 && isImage(data.imageBottle3),
            title: data.titleBottle3 && isString(data.titleBottle3),
        },
    ];
    const colorBackground =
        data.colorBackground && data.colorBackground.toLowerCase();
    const ctaPrimary =
        data.ctaPrimaryReference && isButton(data.ctaPrimaryReference);
    const ctaSecondary =
        data.ctaSecondaryReference && isButton(data.ctaSecondaryReference);
    const imageBackground =
        data.imageBackground && isImage(data.imageBackground);
    const imageMain = data.imageMain && isImage(data.imageMain);
    const imageSub = {
        image: data.imageSmallBottle && isImage(data.imageSmallBottle),
        title: data.textSmallBottle && isString(data.textSmallBottle),
    };
    const nutritionalValuesTable = data.textRight && data.textRight.tableData;
    const nutritionalValuesTitle = data.titleRight && isString(data.titleRight);
    const text = data.text && isString(data.text);
    const title = data.title && isString(data.title);
    const overview = {
        image: data.imageBrandoverview && isImage(data.imageBrandoverview),
        text: data.textBrandoverview && isString(data.textBrandoverview),
        title: data.titleBrandoverview && isString(data.titleBrandoverview),
        cta:
            data.ctaReferenceBrandoverview &&
            isButton(data.ctaReferenceBrandoverview),
    };

    return {
        bottleImages,
        colorBackground,
        ctaPrimary,
        ctaSecondary,
        id,
        imageBackground,
        imageMain,
        imageSub,
        moduleType,
        nutritionalValuesTable,
        nutritionalValuesTitle,
        overview,
        title,
        text,
    };
}
