import classNames from "classnames";
import {
    FormEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import $ from "./NumberInput.module.scss";
import NumberInputButton from "./NumberInputButton";

function validate(
    value: string,
    min: number | undefined,
    max: number | undefined,
) {
    if (value === "") {
        return false;
    }
    const numericValue = parseFloat(value);
    if (typeof min === "number" && numericValue < min) {
        return false;
    }
    if (typeof max === "number" && numericValue > max) {
        return false;
    }
    return true;
}

export type NumberInputComponent = {
    focus(): void;
};
type Props = {
    value: number;
    min?: number;
    max?: number;
    stretched?: boolean;
    onChange: (value: number) => void;
};

const NumberInput = forwardRef<NumberInputComponent, Props>(
    ({ value, min, max, stretched, onChange }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null);
        useImperativeHandle(ref, () => ({
            focus() {
                const el = inputRef.current;
                if (el) {
                    el.focus();
                    el.select();
                    el.scrollIntoView({ block: "center" });
                }
            },
        }));

        const [localValue, setLocalValue] = useState(value.toString());
        const numericValue = Number(localValue);
        useEffect(() => {
            setLocalValue(value.toString());
        }, [value]);

        const [focused, setFocused] = useState(false);

        function onInput(e: FormEvent<HTMLInputElement>) {
            const el = e.target as HTMLInputElement;
            setLocalValue(el.value);
            if (validate(el.value, min, max)) {
                const val = Number(el.value);
                if (val !== value) {
                    onChange(val);
                }
            }
        }
        function setValue(val: number) {
            if (typeof min !== "undefined") {
                // eslint-disable-next-line no-param-reassign
                val = Math.max(min, val);
            }
            if (typeof max !== "undefined") {
                // eslint-disable-next-line no-param-reassign
                val = Math.min(max, val);
            }
            if (val !== value && Number.isNaN(val) === false) {
                onChange(val);
            }
        }
        function onInputBlur() {
            if (localValue === "") {
                setLocalValue(value.toString()); // restore value when input was invalid
            } else {
                setValue(numericValue);
            }
            setFocused(false);
        }
        const style: React.CSSProperties = {
            width: `${Math.max(2, localValue.length) * 0.65 + 1.2}rem`,
        };
        if (stretched) {
            style.minWidth = style.width;
            style.width = `calc(100% - 5.6rem)`;
        }

        const invalid = validate(localValue, min, max) === false;

        return (
            <div
                className={classNames({
                    [$.numberInput]: true,
                    [$.focused]: focused,
                    [$.invalid]: invalid,
                    [$.stretched]: stretched,
                })}
            >
                <NumberInputButton
                    type="-"
                    focused={focused}
                    invalid={invalid}
                    attached
                    onClick={() => setValue(numericValue - 1)}
                />
                <input
                    ref={inputRef}
                    className={$.numberInputInput}
                    style={style}
                    type="number"
                    min={min}
                    max={max}
                    step="0"
                    value={localValue}
                    onFocus={() => setFocused(true)}
                    onBlur={onInputBlur}
                    onInput={onInput}
                />
                <NumberInputButton
                    type="+"
                    focused={focused}
                    invalid={invalid}
                    attached
                    onClick={() => setValue(numericValue + 1)}
                />
            </div>
        );
    },
);

export default NumberInput;
