import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";

function replaceGuid(str: string, id: string) {
    return str.replace("--guid--", id);
}

export default async function fetchNotificationStatus(
    config: ConfigContextType | null,
    postUri: string,
    id: string,
    token?: string,
) {
    if (!config || !token || !postUri) {
        return null;
    }
    const method = "POST";
    const endpoint = replaceGuid(postUri, id);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await fetchUrl<any>({
        config,
        endpoint,
        body: undefined,
        token,
        method,
    });

    return result;
}
