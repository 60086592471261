import { isString } from "utils/checkType";
import { DocumentsOverview } from "../models";
import { ModuleDocumentsOverview } from "../entities";

export default function createDocumentsOverview(
    data: ModuleDocumentsOverview,
): DocumentsOverview {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        colorBackground,
    };
}
