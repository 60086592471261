import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { Container, Link, Logo } from "view/components";
import SettingsContext from "view/context/SettingsContext";
import $ from "./Footer.module.scss";

const Footer = () => {
    const settingsContext = useContext(SettingsContext);
    const { t } = useTranslation();

    const footerMenu = settingsContext?.footerMenu.filter((item) =>
        MenuItemIsVisible(item),
    );

    const socialMenu = settingsContext?.socialMenu.filter((item) =>
        MenuItemIsVisible(item),
    );

    return (
        <footer className={$.wrap}>
            <Container className={$.container}>
                <div className={$.logoWrap}>
                    <Logo />
                </div>
                <nav className={$.menu}>
                    <h3 className={$.srOnly}>{t("Menu")}</h3>
                    <ul className={$.menuList}>
                        {footerMenu &&
                            // We split it at 3, as that was per request of Vrumona
                            footerMenu.slice(0, 3).map((link) => (
                                <li key={link.id} className={$.menuItem}>
                                    <Link
                                        to={
                                            link.externalUrl ||
                                            link.path ||
                                            link.slug
                                        }
                                        className={$.menuLink}
                                    >
                                        {link.title}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </nav>
                <nav className={$.menu}>
                    <h3 className={$.srOnly}>{t("Menu")}</h3>
                    <ul className={$.menuList}>
                        {footerMenu &&
                            footerMenu
                                .slice(3, footerMenu.length)
                                .map((link) => (
                                    <li key={link.id} className={$.menuItem}>
                                        <Link
                                            to={
                                                link.externalUrl ||
                                                link.path ||
                                                link.slug
                                            }
                                            className={$.menuLink}
                                        >
                                            {link.title}
                                        </Link>
                                    </li>
                                ))}
                    </ul>
                </nav>
                <section className={$.socials}>
                    <h3 className={$.srOnly}>{t("social_media")}</h3>
                    <h3 className={$.socialsTitle}>
                        {t("social_media_follow_us")}
                    </h3>
                    <ul className={$.socialsList}>
                        {socialMenu &&
                            socialMenu.map((link) => (
                                <li key={link.id} className={$.socialsItem}>
                                    <Link
                                        to={
                                            link.externalUrl ||
                                            link.path ||
                                            link.slug
                                        }
                                        className={$.socialsLink}
                                    >
                                        {link.icon && (
                                            <img
                                                src={link.icon.url}
                                                alt={link.title}
                                                title={link.title}
                                                className={$.socialsIcon}
                                            />
                                        )}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </section>
            </Container>
        </footer>
    );
};

export default Footer;
