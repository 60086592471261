/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigContextType } from "view/context/ConfigContext";
import fetchUrl from "./fetchUrl";

export default async function putData(
    config: ConfigContextType,
    endpoint: string,
    token: string,
    body: any,
): Promise<any> {
    const response = await fetchUrl<any>({
        config,
        endpoint,
        body,
        method: "PUT",
        token,
    });

    if (response.status && response.status !== 200) {
        return false;
    }

    return true;
}
