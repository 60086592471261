/* eslint-disable no-restricted-globals */
import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "core/constants";
import { Spinner } from "..";

const PrivateRoute = (props) => {
    const { path, children, ...rest } = props;
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <Spinner />;
    }

    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: paths.login,
                    // Store the requested location uri in the state, so we can redirect the user to the requested page.
                    state: {
                        from: location.pathname,
                        search: location.search,
                    },
                }}
            />
        );
    }

    return <Route path={path} render={() => children} {...rest} />;
};

export default PrivateRoute;
