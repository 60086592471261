import classnames from "classnames";
import { MOBILE } from "core/constants/global";
import { Category } from "core/models";
import * as React from "react";
import { useWindowWidth } from "utils/useWindowWidth";
import SettingsContext from "view/context/SettingsContext";
import { Link } from "..";
import $ from "./ArchiveMenu.module.scss";

type Props = {
    activeCategorySlug: string;
    categories?: Category[] | null;
};

const ArchiveMenu: React.FC<Props> = (props) => {
    const settingsContext = React.useContext(SettingsContext);
    const { activeCategorySlug, categories } = props;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE;

    if (!settingsContext || !settingsContext.configuration) {
        return null;
    }

    return (
        <ul className={$.categories}>
            {settingsContext.configuration.categoryParentSlug && (
                <li
                    className={classnames(
                        $.category,
                        !activeCategorySlug ? $.active : undefined,
                    )}
                >
                    <Link
                        to={`/${settingsContext.configuration.categoryParentSlug}`}
                        className={$.categoryLink}
                    >
                        Alle artikelen
                    </Link>
                </li>
            )}
            {categories &&
                categories.length > 0 &&
                categories.map((category) => {
                    const isActive = activeCategorySlug === category.slug;

                    if (isMobile) {
                        return (
                            <li
                                key={category.slug}
                                className={classnames(
                                    $.category,
                                    isActive ? $.active : undefined,
                                )}
                            >
                                <Link
                                    to={category.path}
                                    className={$.categoryLink}
                                >
                                    {category.title}
                                </Link>
                            </li>
                        );
                    }

                    return (
                        <li
                            key={category.slug}
                            className={classnames(
                                $.category,
                                isActive ? $.active : undefined,
                            )}
                        >
                            <Link to={category.path} className={$.categoryLink}>
                                {category.title}
                            </Link>
                        </li>
                    );
                })}
        </ul>
    );
};

export default ArchiveMenu;
