import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";

export default async function fetchInvoice(
    config: ConfigContextType,
    endpoint: string,
    token: string,
) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await fetchUrl<any>({
        config,
        endpoint,
        body: undefined,
        token,
        params: { responseType: "blob" },
    });

    if (response && response instanceof Blob) {
        return response;
    }

    return undefined;
}
