import * as React from "react";
import classnames from "classnames";
import { Link } from "view/components";
import $ from "./Button.module.scss";

interface Props {
    disabled?: boolean;
    path?: string;
    title: string;
    icon?: {
        url: string;
        title: string;
    };
    color: "white" | "turquiose" | "green" | "red";
    small?: boolean;
    narrow?: boolean;
    bottom?: boolean;
    end?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: (event?: any) => void;
    className?: string | undefined;
}

const Button = (props: Props) => {
    const {
        disabled = false,
        path,
        title,
        icon,
        onClick,
        color,
        small,
        narrow,
        bottom,
        end,
        className,
    } = props;
    const buttonStyles = classnames(
        $.button,
        color === "white" && $.buttonWhite,
        color === "turquiose" && $.buttonTurquiose,
        color === "green" && $.buttonGreen,
        color === "red" && $.buttonRed,
        color, // Add color as hardcoded class for override the styling in contentful
        small && $.buttonSmall,
        narrow && $.buttonNarrow,
        bottom && $.buttonBottom,
        end && $.buttonEnd,
        disabled && $.buttonDisabled,
        className && className,
    );

    return (
        <Link
            to={path}
            className={buttonStyles}
            onClick={onClick}
            disabled={disabled}
        >
            {icon && icon.url && (
                <img
                    src={icon.url}
                    alt={icon.title}
                    title={icon.title}
                    className={$.icon}
                />
            )}
            {title}
        </Link>
    );
};

export default Button;
