/* eslint-disable @typescript-eslint/no-explicit-any */
import classnames from "classnames";
import { Account, Contact, FormFields } from "core/models";
import { FormFieldset, ReadOnlyFormFieldset } from "..";
import $ from "./MasterDataSetEditor.module.scss";

export interface MasterDataSetEditorProps {
    id?: string;
    title?: string;
    viewModelFieldset: FormFields[];
    viewModel: any;
    editFieldset: FormFields[];
    showEditor: boolean;
    dataSource?: Account | Contact;
    handleInputChange: (e: boolean | string, name: string, files?: any) => void;
}

const MasterDataSetEditor = (props: MasterDataSetEditorProps) => {
    const {
        id,
        viewModelFieldset,
        editFieldset,
        viewModel,
        dataSource,
        handleInputChange,
        showEditor,
        title,
    } = props;

    return (
        <div
            key={id}
            id={id}
            data-testid={id}
            className={classnames([
                showEditor ? $.editMode : $.readOnlyMode,
                $.editor,
            ])}
        >
            {viewModel && (
                <>
                    {editFieldset && showEditor && (
                        <FormFieldset
                            title={title}
                            fields={editFieldset}
                            formData={dataSource}
                            handleChange={handleInputChange}
                        />
                    )}
                    {viewModelFieldset && !showEditor && (
                        <ReadOnlyFormFieldset
                            title={title}
                            fields={viewModelFieldset}
                            dataSource={viewModel}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default MasterDataSetEditor;
