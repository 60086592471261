import * as React from "react";
import { Faq as FaqProps } from "core/models/Faq";
import { ModuleWrapper } from "view/components";
import FaqItem from "view/components/FaqItem";
import $ from "./Faq.module.scss";

const Faq = (props: FaqProps) => {
    const { title, colorBackground, faqs } = props;
    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";

    return (
        <ModuleWrapper title={title} background={colorBackground} anchor="faq">
            <>
                {faqs && faqs.length > 0 && (
                    <div className={$.faqItems} data-testid="faq-items">
                        {faqs.map((item) => (
                            <FaqItem
                                {...item}
                                key={item.question}
                                dark={lightBackground}
                            />
                        ))}
                    </div>
                )}
                {(!faqs || faqs.length === 0) && (
                    <p data-testid="message">
                        Er zijn op dit moment geen veelgestelde vragen.
                    </p>
                )}
            </>
        </ModuleWrapper>
    );
};

export default Faq;
