import { isString, isButton } from "utils/checkType";
import { createBrand } from ".";
import { ModuleBrandsOverview } from "../entities";
import { BrandsOverview } from "../models";

export default function createBrandsOverview(
    data: ModuleBrandsOverview,
): BrandsOverview {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const text = data.text && isString(data.text);
    const ctaPrimary =
        data.ctaPrimaryReference && isButton(data.ctaPrimaryReference);

    return {
        id,
        contentType,
        moduleType,
        title,
        text,
        ctaPrimary,
        brands: data.brands.map((brand) => createBrand(brand)),
    };
}
