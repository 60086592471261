import classNames from "classnames";
import { useEffect } from "react";
import useEsc from "view/hooks/useEsc";
import $ from "./Modal.module.scss";

type Props = {
    withCloseLabel?: boolean;
    onCancel?: () => void;
};

const Modal: React.FC<Props> = ({ withCloseLabel, children, onCancel }) => {
    useEsc(() => {
        if (onCancel) {
            onCancel();
        }
    });
    useEffect(() => {
        document.body.classList.add($.disableBodyScroll);
        return () => {
            document.body.classList.remove($.disableBodyScroll);
        };
    }, []);
    return (
        <div className={$.modal}>
            <div className={$.backdrop} onClick={onCancel} />
            <div className={$.window}>
                {children}
                {onCancel && (
                    <button
                        className={classNames({
                            [$.close]: true,
                            [$.withLabel]: withCloseLabel,
                        })}
                        type="button"
                        onClick={onCancel}
                    >
                        Sluiten
                        <span className={$.closeIcon} />
                    </button>
                )}
            </div>
        </div>
    );
};
export default Modal;
