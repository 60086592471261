import { isString, isImage, isButton } from "utils/checkType";
import { BrandThumb } from "../entities";
import { BrandThumb as BrandModel } from "../models";

export default function createBrandThumb(data: BrandThumb): BrandModel {
    const title = data.title && isString(data.title);
    const slug = data.slug && isString(data.slug);
    const text = data.text && isString(data.text);
    const image = data.logo && isImage(data.logo);
    const link = data.link && isButton(data.link);

    return {
        title,
        slug,
        text,
        image,
        link,
    };
}
