import {
    createAccountOverview,
    createArticleList,
    createBonusTool,
    createBonusToolOverview,
    createBrand,
    createBrands,
    createBrandsOverview,
    createCtaLists,
    createDashboard,
    createDocumentsOverview,
    createFaq,
    createForm,
    createHero,
    createHtmlCode,
    createInvoiceOverview,
    createLogin,
    createMasterDataOverview,
    createContactpersonOverview,
    createReviewBlock,
    createSupport,
    createTextImage,
    createUspList,
    createVideo,
    createGenesys,
} from ".";

export default function createModules(data) {
    if (!data) {
        return null;
    }
    return data.reduce((result, module) => {
        switch (module.moduleType) {
            case "moduleHero":
                result.push(createHero(module));
                break;
            case "moduleBrands":
                result.push(createBrands(module));
                break;
            case "moduleTextImage":
                result.push(createTextImage(module));
                break;
            case "moduleArticleList":
                result.push(createArticleList(module));
                break;
            case "moduleCtaLists":
                result.push(createCtaLists(module));
                break;
            case "moduleFaq":
                result.push(createFaq(module));
                break;
            case "moduleSupport":
                result.push(createSupport(module));
                break;
            case "moduleVideo":
                result.push(createVideo(module));
                break;
            case "moduleText":
            case "moduleHtmlCode":
                result.push(createHtmlCode(module));
                break;
            case "moduleContact":
                result.push(createForm(module));
                break;
            case "moduleLogin":
                result.push(createLogin(module));
                break;
            case "moduleDashboard":
                result.push(createDashboard(module));
                break;
            case "moduleAccountOverview":
                result.push(createAccountOverview(module));
                break;
            case "moduleInvoiceOverview":
                result.push(createInvoiceOverview(module));
                break;
            case "moduleDocumentsOverview":
                result.push(createDocumentsOverview(module));
                break;
            case "moduleMasterDataOverview":
                result.push(createMasterDataOverview(module));
                break;
            case "moduleContactpersonOverview":
                result.push(createContactpersonOverview(module));
                break;
            case "moduleBonusTool":
                result.push(createBonusTool(module));
                break;
            case "moduleBonusToolOverview":
                result.push(createBonusToolOverview(module));
                break;
            case "moduleBrandsOverview":
                result.push(createBrandsOverview(module));
                break;
            case "moduleBrand":
                result.push(createBrand(module));
                break;
            case "moduleReviewBlock":
                result.push(createReviewBlock(module));
                break;
            case "moduleUspList":
                result.push(createUspList(module));
                break;
            case "moduleGenesys":
                result.push(createGenesys(module));
                break;
            default:
                // eslint-disable-next-line no-console
                console.debug("Module not defined: ", module.moduleType);
                break;
        }
        return result;
    }, []);
}
