import { MOBILE } from "core/constants/global";
import { ArticleReference } from "core/models";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "view/components";
import Image from "../Image";
import $ from "./ArticleCard.module.scss";

const ArticleCard = (props: ArticleReference) => {
    const { image, title, intro, category, readTime, path } = props;
    const { t } = useTranslation();

    const imgWidth = window.innerWidth <= MOBILE ? 748 : 332;

    return (
        <article className={$.wrap} data-testid="article">
            <Link to={path}>
                <div>
                    <div className={$.imgWrap}>
                        {image && image.url && (
                            <Image
                                placeholder={`${image.url}?w=30&q=50&fm=jpg&fl=progressive`}
                                src={`${image.url}?w=${imgWidth}&q=75&fm=jpg&fl=progressive`}
                                className={$.img}
                                alt={image.title}
                            />
                        )}
                    </div>
                    {category && (
                        <p className={$.categoryLabel}>{category.title}</p>
                    )}
                </div>
                <div className={$.content}>
                    <h1 className={$.title} data-testid="title">
                        {title}
                    </h1>

                    {intro && <p className={$.text}>{intro}</p>}

                    <footer className={$.footer}>
                        <span
                            className={$.readingTime}
                            data-testid="reading-time"
                        >
                            {readTime}
                        </span>
                        <span className={$.readMore} data-testid="link">
                            {t("read_more")}
                        </span>
                    </footer>
                </div>
            </Link>
        </article>
    );
};

export default ArticleCard;
