import { isString } from "utils/checkType";
import { ModuleVideo } from "../entities";
import { Video } from "../models";

export default function createVideo(data: ModuleVideo): Video {
    const { moduleType } = data;
    const id = isString(data.id);
    const title = data.title && isString(data.title);
    const youtubeId = data.youtubeId && isString(data.youtubeId);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        moduleType,
        title,
        youtubeId,
        colorBackground,
    };
}
