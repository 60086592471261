import { isString, isObject, isBool, isImage } from "utils/checkType";
import { Form, FormFields, FormFieldType, FormOptions } from "../models";
import { FormEntity } from "../entities";

function createOptions(data): FormOptions {
    const value = isString(data.value);
    const label = isString(data.label);

    return {
        value,
        label,
    };
}

function createFormField(data): FormFields {
    const name = isString(data.name);
    const type = FormFieldType[isString(data.type)];
    const placeholder = isString(data.placeholder);
    const required = isBool(data.required);
    const options =
        data.options &&
        isObject(data.options) &&
        data.options.map((option) => createOptions(option));

    return {
        name,
        type,
        placeholder,
        required,
        options,
    };
}

export default function createForm(data: FormEntity): Form {
    const { moduleType, contactType, contactSubType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const postUri = data.postUri && isString(data.postUri);
    const title = data.title && isString(data.title);
    const text = data.text && isString(data.text);
    const fields =
        data.fields &&
        isObject(data.fields) &&
        data.fields.map((field) => createFormField(field));
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);
    const image = data.image && isImage(data.image);

    return {
        moduleType,
        contentType,
        contactType,
        contactSubType,
        postUri,
        title,
        text,
        id,
        fields,
        colorBackground,
        image,
    };
}
