import * as React from "react";
import classnames from "classnames";
import { Brand as BrandModel } from "core/models";
import { Button, Container, Image, Link } from "view/components";
import $ from "./Brand.module.scss";
import { CurvedTriangleClipPath } from "../ReviewBlock/ReviewBlock";

const Brand: React.FC<BrandModel> = (props) => {
    const {
        ctaPrimary,
        ctaSecondary,
        colorBackground,
        bottleImages,
        imageBackground,
        imageMain,
        imageSub,
        nutritionalValuesTable,
        nutritionalValuesTitle,
        text,
        title,
    } = props;

    return (
        <article
            className={classnames(
                $.container,
                colorBackground === "blue" && $.containerBlue,
                colorBackground === "lightgreen" && $.containerLightGreen,
                colorBackground === "darkgreen" && $.containerDarkGreen,
            )}
        >
            <div className={$.textWrapper}>
                {imageBackground && imageBackground.url && (
                    <div className={$.imageContainer}>
                        <Image
                            placeholder={`${imageBackground.url}?w=30&q=50&fm=jpg&fl=progressive`}
                            src={`${imageBackground.url}?w=1200&q=75&fm=jpg&fl=progressive`}
                            className={$.image}
                            alt={imageBackground.title}
                        />
                    </div>
                )}
                <CurvedTriangleClipPath />

                <Container className={$.textContainer}>
                    <div className={$.descriptionContainer}>
                        {title && <h2 className={$.title}>{title}</h2>}
                        {text && <p className={$.text}>{text}</p>}

                        <ul className={$.productList}>
                            {bottleImages &&
                                bottleImages.map((bottleImage) => {
                                    const {
                                        image: bottle,
                                        title: bottleTitle,
                                    } = bottleImage;

                                    return (
                                        <li
                                            key={bottleTitle}
                                            className={$.productListItem}
                                        >
                                            {bottle && bottle.url && (
                                                <span
                                                    className={$.productImage}
                                                    style={{
                                                        backgroundImage: `url(${bottle.url}?w=85&q=80)`,
                                                    }}
                                                />
                                            )}
                                            <span className={$.productTitle}>
                                                {bottleTitle}
                                            </span>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                    <div className={$.nutritionalValues}>
                        {nutritionalValuesTitle && (
                            <h3 className={$.nutritionalValuesTitle}>
                                {nutritionalValuesTitle}
                            </h3>
                        )}
                        {nutritionalValuesTable &&
                            nutritionalValuesTable.length > 0 && (
                                <ul className={$.nutritionalList}>
                                    {nutritionalValuesTable.map((row) => (
                                        <li
                                            key={row.key}
                                            className={$.nutritionalListItem}
                                        >
                                            <span>{row.key}</span>
                                            <span>{row.value}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                    </div>
                </Container>
            </div>

            <div className={$.ctaContainer}>
                <Container className={$.buttons}>
                    {ctaPrimary && (
                        <Button
                            title={ctaPrimary.title}
                            color="turquiose"
                            className={$.button}
                            path={
                                ctaPrimary.slug ||
                                ctaPrimary.path ||
                                ctaPrimary.externalUrl
                            }
                        />
                    )}
                    {ctaSecondary && (
                        <Link
                            to={
                                ctaSecondary.externalUrl ||
                                ctaSecondary.path ||
                                ctaSecondary.slug
                            }
                            className={$.link}
                        >
                            {ctaSecondary.title}
                        </Link>
                    )}
                </Container>
            </div>

            <div className={$.mainImageContainer}>
                <div className={$.subImage}>
                    {imageSub && imageSub.image && imageSub.image.url && (
                        <span
                            className={$.productImage}
                            style={{
                                backgroundImage: `url(${imageSub.image.url}?w=85&q=80)`,
                            }}
                        />
                    )}
                    {imageSub && imageSub.title && (
                        <span className={$.productTitle}>{imageSub.title}</span>
                    )}
                </div>
                {imageMain && imageMain.url && (
                    <Image
                        placeholder={`${imageMain.url}?w=30&q=50`}
                        src={`${imageMain.url}?w=400&q=80`}
                        className={$.mainImage}
                        alt={imageMain.title}
                    />
                )}
            </div>
        </article>
    );
};

export default Brand;
