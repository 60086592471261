import parseJSON from "date-fns/parseJSON";
import { isString, isArray, isNum } from "utils/checkType";
import { Invoice as InvoiceEntity } from "../entities";
import { Invoice } from "../models";

export interface Invoices {
    invoices: InvoiceEntity[];
}

function createInvoice(data: InvoiceEntity): Invoice {
    const id = data.id && isString(data.id);
    const number = isNum(Number.parseInt(data.number, 10));
    const date =
        (data.date && parseJSON(data.date) && new Date(data.date)) ||
        new Date();

    const filename = data.filename && isString(data.filename);
    const totalAmountExVat =
        data.total_amount_ex_vat && isString(data.total_amount_ex_vat);
    const totalAmountInclVat =
        data.total_amount_incl_vat && isString(data.total_amount_incl_vat);
    const debitcredit = data.debitcredit && isString(data.debitcredit);

    const url = data.url && isString(data.url);

    return {
        id,
        number,
        date,
        filename,
        totalAmountExVat,
        totalAmountInclVat,
        debitcredit,
        url,
        blob: undefined,
    };
}

export default function createInvoices(data: Invoices): Invoice[] {
    const invoices = data && isArray(data);

    return invoices && invoices.map((invoice) => createInvoice(invoice));
}
