import { Init } from "core/models/Init";
import { createInit } from "core/mappers";
import endpoints from "core/constants/endpoints";
import fetchUrl from "core/services/fetchUrl";

export default async function fetchInit(config) {
    if (!config) {
        return null;
    }
    const endpoint = endpoints.init;
    const result = await fetchUrl<Init>({ config, endpoint });
    return createInit(result);
}
