import { useState, useMemo } from "react";
import { ConfigContextType } from "view/context/ConfigContext";
import { Document } from "core/models";
import fetchDocuments from "core/services/fetchDocuments";
import fetchDocument from "core/services/fetchDocument";
import Analytics from "core/services/analytics";

interface DocumentServiceData {
    pending: boolean;
    documents: Document[] | null;
    error: string | null;
}

export default function useDocumentService(
    config: ConfigContextType | null,
    dataUri: string,
    token: string | undefined,
) {
    const endpoint = dataUri;
    const [document, setDocument] = useState<Document>();
    const [pendingDownload, setPendingDownload] = useState<boolean>(false);

    const [data, setData] = useState<DocumentServiceData>({
        pending: true,
        documents: null,
        error: null,
    });

    const fetchData = useMemo(
        () => async () => {
            setData({
                pending: true,
                documents: null,
                error: null,
            });
            if (!config) {
                setData({
                    pending: false,
                    documents: null,
                    error: "Configuration not found",
                });
                return null;
            }
            if (!token) {
                return null;
            }
            const response = await fetchDocuments(config, endpoint, token);

            const { result, error } = response;

            if (result) {
                result.sort((a, b) =>
                    a.createdOn.getTime() > b.createdOn.getTime() ? -1 : 1,
                );
            }
            setData({
                pending: false,
                documents: result,
                error,
            });
            return null;
        },
        [config, endpoint, token],
    );

    const downloadDocument = useMemo(
        () => async (DocumentBlob: Document) => {
            // Reset
            setDocument(undefined);
            setPendingDownload(true);

            if (!config) {
                setPendingDownload(false);
                return null;
            }
            if (!token) {
                return null;
            }

            Analytics.event("Document", "Download", DocumentBlob.url);

            const response: Blob | undefined = await fetchDocument(
                config,
                DocumentBlob.url,
                token,
            );
            if (response) {
                setDocument({
                    ...DocumentBlob,
                    blob: response,
                });
            }
            setPendingDownload(false);
            return null;
        },
        [config, token],
    );

    return {
        fetchData,
        data,
        pendingDownload,
        downloadDocument,
        document,
    };
}
