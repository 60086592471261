import axios, { AxiosRequestConfig } from "axios";
import paths from "../constants/paths";
import { FetchUnAuthorized } from "../models";

export type ApiBehavior = {
    unwrap?: boolean; // true (default): return the data, false: return the response object,
    failOnError?: boolean; // true: Reject the promise on failure, false (default): silently ignore the error
};
export async function axiosRequest<T>(
    url: string,
    options: AxiosRequestConfig,
    behavior: ApiBehavior,
): Promise<T> {
    const unwrap =
        typeof behavior.unwrap === "undefined" ? true : behavior.unwrap;
    const failOnError =
        typeof behavior.failOnError === "undefined"
            ? false // silently ignoring errors is the default for compatiblility reasons :-(
            : behavior.failOnError;
    try {
        const response = await axios(url, options);
        if (unwrap) {
            return response.data as T;
        }
        return response as unknown as T;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        if (err?.response?.status === 403) {
            const data = err.response.data as FetchUnAuthorized;
            if (data.statusCode === 1) {
                window.location.href = paths.consent;
            }
            if (failOnError) {
                throw new Error("Not authorized");
            }
            return null as unknown as T;
        }
        if (failOnError) {
            throw err;
        }
        if (err.response) {
            return err.response;
        }
        return err;
    }
}
