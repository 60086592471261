import { useState, useCallback, useEffect } from "react";

export default function useTokenService(getAccessTokenSilently, user) {
    const [token, setToken] = useState<string | undefined>(undefined);
    const [tokenPending, setTokenPending] = useState<boolean>(true);

    const fetchTokenInternally = useCallback(async () => {
        setTokenPending(true);
        const response = await getAccessTokenSilently();
        setToken(response);
        setTokenPending(false);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (user) {
            fetchTokenInternally();
        } else {
            setTokenPending(false);
        }
    }, [fetchTokenInternally, user]);

    return { tokenPending, token };
}
