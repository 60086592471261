import classnames from "classnames";
import { LOGO } from "core/constants/global";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "..";
import $ from "./Logo.module.scss";

interface Props {
    white?: boolean;
    desktopOnly?: boolean;
    mobileOnly?: boolean;
}

const Logo = (props: Props) => {
    const { white, desktopOnly, mobileOnly } = props;
    const { t } = useTranslation();
    const imgStyles = classnames([
        $.logo,
        desktopOnly && $.logoDesktop,
        mobileOnly && $.logoMobile,
    ]);

    return (
        <Link to="/">
            <img
                className={imgStyles}
                src={white ? LOGO.inverted : LOGO.default}
                alt={t("app_name")}
            />
        </Link>
    );
};

export default Logo;
