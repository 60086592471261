import { MenuItemEntity } from "core/entities";
import { MenuItem } from "core/models/MenuItem";
// eslint-disable-next-line import/no-cycle
import createMenuItem from "./createMenuItem";

export default function createSubMenu(data: MenuItemEntity[][]): MenuItem[][] {
    if (data.length > 1) {
        const secondaryMenu = data.map((tertiaryMenu) =>
            tertiaryMenu.map((item) => createMenuItem(item)),
        );

        return secondaryMenu;
    }
    return [data[0].map((item) => createMenuItem(item))];
}
