import { DecisionTree, Product } from "core/models";

export default function createDecisionTreeFromProducts(
    products: Product[],
): DecisionTree {
    const rawTree = products.reduce<DecisionTree>((acc, product) => {
        const { packaging, brand } = product;

        if (!packaging || !brand) {
            return acc;
        }

        let packagingChoice = acc.find(
            (pChoice) => pChoice.packaging === packaging,
        );
        if (!packagingChoice) {
            packagingChoice = {
                packaging,
                brands: [],
            };
            acc.push(packagingChoice);
        }

        let brandChoice = packagingChoice.brands.find(
            (bChoice) => bChoice.brand === brand,
        );
        if (!brandChoice) {
            brandChoice = {
                brand,
                products: [],
            };
            packagingChoice.brands.push(brandChoice);
        }

        brandChoice.products.push(product);

        return acc;
    }, []);

    rawTree.sort((a, b) =>
        a.packaging.localeCompare(b.packaging, undefined, {
            numeric: true,
        }),
    );

    rawTree.forEach((packagingChoice) => {
        packagingChoice.brands.sort((a, b) => {
            if (a.brand < b.brand) {
                return -1;
            }

            return a.brand > b.brand ? 1 : 0;
        });

        packagingChoice.brands.forEach((brandChoice) => {
            brandChoice.products.sort((a, b) => {
                if (a.productDescription < b.productDescription) {
                    return -1;
                }

                return a.productDescription > b.productDescription ? 1 : 0;
            });
        });
    });

    return rawTree;
}
