import * as React from "react";
import classnames from "classnames";
import { useWindowWidth } from "utils/useWindowWidth";
import { DESKTOP } from "core/constants";
import $ from "./Expander.module.scss";

type Props = {
    startOpen?: boolean;
};

const Expander: React.FC<Props> = ({ children, startOpen = false }) => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= DESKTOP;

    const [isClosed, setIsClosed] = React.useState(!startOpen);
    const expanderStyles = classnames([
        $.expanderContent,
        isMobile && isClosed && $.expanderContentClosed,
    ]);

    const toggleStyles = classnames([
        $.expanderToggle,
        isClosed ? $.expanderToggleClosed : $.expanderToggleOpen,
    ]);

    return (
        <div className={$.expander}>
            <div className={expanderStyles}>{children}</div>
            <button
                type="button"
                className={toggleStyles}
                onClick={() => setIsClosed(!isClosed)}
            >
                {isClosed ? "Meer" : "Minder"} informatie
            </button>
        </div>
    );
};

export default Expander;
