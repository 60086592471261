import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line import/extensions
import nlNL from "static/translations/nl-NL.json";
// eslint-disable-next-line import/extensions
import enUS from "static/translations/en-US.json";

const languages = {
    nlNL: "nl-NL",
    enUS: "en-US",
};

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    [languages.nlNL]: nlNL,
    [languages.enUS]: enUS,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: languages.nlNL,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
