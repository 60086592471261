import { useState, useMemo } from "react";
import { ConfigContextType } from "view/context/ConfigContext";
import { Invoice } from "core/models";
import fetchInvoices from "core/services/fetchInvoices";
import fetchInvoice from "core/services/fetchInvoice";
import Analytics from "core/services/analytics";

interface InvoiceServiceData {
    pending: boolean;
    invoices: Invoice[] | null;
    error: string | null;
}

export default function useInvoiceService(
    config: ConfigContextType | null,
    dataUri: string,
    token: string | undefined,
) {
    const endpoint = dataUri;
    const [invoice, setInvoice] = useState<Invoice>();
    const [pendingDownload, setPendingDownload] = useState<boolean>(false);

    const [data, setData] = useState<InvoiceServiceData>({
        pending: true,
        invoices: null,
        error: null,
    });

    const fetchData = useMemo(
        () => async () => {
            setData({
                pending: true,
                invoices: null,
                error: null,
            });
            if (!config) {
                setData({
                    pending: false,
                    invoices: null,
                    error: "Configuration not found",
                });
                return null;
            }
            if (!token) {
                return null;
            }
            const response = await fetchInvoices(config, endpoint, token);

            const { result, error } = response;

            setData({
                pending: false,
                invoices: result,
                error,
            });
            return null;
        },
        [config, endpoint, token],
    );

    const downloadInvoice = useMemo(
        () => async (invoiceBlob: Invoice) => {
            // Reset
            setInvoice(undefined);
            setPendingDownload(true);

            if (!config) {
                setPendingDownload(false);
                return null;
            }
            if (!token) {
                return null;
            }

            Analytics.event("Invoice", "Download", invoiceBlob.url);

            const response: Blob | undefined = await fetchInvoice(
                config,
                invoiceBlob.url,
                token,
            );
            if (response) {
                setInvoice({
                    ...invoiceBlob,
                    blob: response,
                });
            }
            setPendingDownload(false);
            return null;
        },
        [config, token],
    );

    return {
        fetchData,
        data,
        pendingDownload,
        downloadInvoice,
        invoice,
    };
}
