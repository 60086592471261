/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import isExternal from "utils/isUrlExternal";

interface Props {
    disabled?: boolean;
    to?: string;
    children: Element | HTMLElement | React.ReactNode;
    className?: string | undefined;
    onClick?: () => void;
}

const Link = ({ to, disabled, className, ...props }: Props) =>
    // eslint-disable-next-line no-nested-ternary
    to ? (
        isExternal(to) ? (
            <a
                href={to}
                target="_blank"
                rel="noopener noreferrer"
                {...props}
                className={classnames([className])}
            />
        ) : (
            <NavLink
                to={disabled ? "#" : to}
                {...props}
                className={classnames([className])}
            />
        )
    ) : (
        <button
            type="button"
            {...props}
            disabled={disabled}
            className={classnames([className])}
        />
    );

export default Link;
