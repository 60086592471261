import * as React from "react";
import { UspList as UspListModel } from "core/models";
import ModuleWrapper from "view/components/ModuleWrapper";
import { Link } from "view/components";
import $ from "./UspList.module.scss";

const UspList: React.FC<UspListModel> = (props) => {
    const { title, blocks } = props;

    return (
        <ModuleWrapper title={title}>
            <div className={$.container}>
                {blocks.map((block) => {
                    const { title: blockTitle, text, image, cta } = block;
                    return (
                        <article className={$.block} key={block.title}>
                            {image && image.url && (
                                <span
                                    className={$.icon}
                                    style={{
                                        backgroundImage: `url(${image.url})`,
                                    }}
                                />
                            )}
                            {blockTitle && (
                                <h2 className={$.title}>
                                    {cta ? (
                                        <Link
                                            className={$.link}
                                            to={
                                                cta.path ||
                                                cta.slug ||
                                                cta.externalUrl
                                            }
                                        >
                                            {blockTitle}
                                        </Link>
                                    ) : (
                                        blockTitle
                                    )}
                                </h2>
                            )}
                            {text && <p className={$.text}>{text}</p>}
                        </article>
                    );
                })}
            </div>
        </ModuleWrapper>
    );
};

export default UspList;
