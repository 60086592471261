import { DESKTOP, MOBILE } from "core/constants/global";
import { Brands as BrandsModel } from "core/models";
import * as React from "react";
import { Image, Link, Card } from "view/components";
import $ from "./Brands.module.scss";

const Brands: React.FC<BrandsModel> = (props: BrandsModel) => {
    const { brands } = props;

    if (!brands) {
        return null;
    }

    let imgWidth = 48; // On Mobile
    if (window.innerWidth > MOBILE && window.innerWidth <= DESKTOP) {
        imgWidth = 64;
    } else {
        imgWidth = 80;
    }

    return (
        <section className={$.container} data-testid="brands">
            <Card>
                <ul className={$.list}>
                    {brands.map((brand) => (
                        <li key={brand.title} className={$.listItem}>
                            {brand.link && (
                                <Link
                                    to={
                                        brand.link.path ||
                                        brand.link.slug ||
                                        brand.link.externalUrl
                                    }
                                >
                                    {brand.image && (
                                        <Image
                                            placeholder={`${brand.image.url}?w=30&q=50&fm=jpg&fl=progressive`}
                                            src={`${brand.image.url}?w=${imgWidth}&q=75&fm=jpg&fl=progressive`}
                                            className={$.image}
                                            alt={brand.image.title}
                                        />
                                    )}
                                </Link>
                            )}
                            {!brand.link && (
                                <img
                                    placeholder={`${brand.image.url}?w=30&q=50&fm=jpg&fl=progressive`}
                                    src={`${brand.image.url}?w=${imgWidth}&q=75&fm=jpg&fl=progressive`}
                                    className={$.image}
                                    alt={brand.image.title}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </Card>
        </section>
    );
};

export default Brands;
