import { useContext, useState, useMemo } from "react";
import { ConfigContextType } from "view/context/ConfigContext";
import { AccountContext } from "view/context/AccountContext";
import { Account } from "core/models";
import { endpoints } from "core/constants";
import fetchAccount, { fetchAccounts } from "core/services/fetchAccount";
import putData from "core/services/putData";

export default function useAccountService(
    config: ConfigContextType | null,
    token: string | undefined,
) {
    const { selectedAccount } = useContext(AccountContext);
    const [pending, setPending] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<Account>({} as Account);
    const [accounts, setAccounts] = useState<Array<Account>>([]);

    const fetchData = useMemo(
        () => async () => {
            setPending(true);
            if (!config) {
                setPending(false);
                setError(true);
                return null;
            }
            if (!token) {
                setPending(false);
                return null;
            }

            const response: Account = await fetchAccount(
                config,
                endpoints.accountdetails(selectedAccount?.accountnumber),
                token,
            );
            if (response) {
                setData(response);
            }
            setPending(false);
            setError(false);
            return null;
        },
        [config, token, selectedAccount],
    );

    const sendData = async (account: Account | null): Promise<boolean> => {
        setPending(true);

        if (!config) {
            setPending(false);
            return false;
        }
        if (!token) {
            setPending(false);
            return false;
        }
        if (!account) {
            setPending(false);
            return false;
        }

        const response = await putData(
            config,
            endpoints.accounts(selectedAccount?.accountnumber),
            token,
            account,
        );

        setError(!response);
        setPending(false);

        return response;
    };

    const getAccounts = useMemo(
        () => async () => {
            setPending(true);
            if (!config) {
                setPending(false);
                setError(true);
                return null;
            }
            if (!token) {
                setPending(false);
                return null;
            }
            const response: Account[] = await fetchAccounts(
                config,
                endpoints.accounts(),
                token,
            );
            if (response) {
                setAccounts(response);
            }
            setPending(false);
            setError(false);
            return null;
        },
        [config, token],
    );

    return { fetchData, sendData, getAccounts, data, accounts, pending, error };
}
