import { isString, isArray } from "utils/checkType";
import { ModuleCtaLists } from "../entities";
import { CtaLists } from "../models";

export default function createCtaLists(data: ModuleCtaLists): CtaLists {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const list1Title = data.list1Title && isString(data.list1Title);
    const list1 = data.list1 && isArray(data.list1);
    const list2Title = data.list2Title && isString(data.list2Title);
    const list2 = data.list2 && isArray(data.list2);
    const list3Title = data.list3Title && isString(data.list3Title);
    const list3 = data.list3 && isArray(data.list3);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        list1Title,
        list1,
        list2Title,
        list2,
        list3Title,
        list3,
        colorBackground,
    };
}
