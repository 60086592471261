import * as React from "react";
import classnames from "classnames";
import $ from "./CardColumn.module.scss";
import { Card } from "..";
import { GridColumn } from "../Grid";

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
    title?: string;
    id?: string;
    dark?: boolean;
    stretch?: boolean;
    spaceBetween?: boolean;
    sameHeight?: boolean;
};

const CardColumn: React.FC<Props> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        title,
        children,
        spaceBetween,
        sameHeight,
        id,
        dark,
        stretch,
        ...rest
    } = props;
    if (!title && !children) {
        return null;
    }
    const style = classnames([
        stretch && $.stretch,
        spaceBetween && $.spaceBetween,
        sameHeight && $.sameHeight,
    ]);
    return (
        <GridColumn id={id} className={$.gridColumn}>
            <Card padding dark={dark} {...rest} className={style}>
                {title && <h3>{title}</h3>}
                {children}
            </Card>
        </GridColumn>
    );
};

export default CardColumn;
