import * as React from "react";
import { useTranslation } from "react-i18next";
import { ArticleList as ArticleListModel } from "core/models";
import { ArticleCard, ModuleWrapper, Link } from "view/components";
import $ from "./ArticleList.module.scss";

const ArticleList = (props: ArticleListModel) => {
    const { title, articleReferences, colorBackground, morePath, moreLabel } =
        props;
    const { t } = useTranslation();

    return (
        <ModuleWrapper title={title} background={colorBackground}>
            <section className={$.wrap}>
                {articleReferences && articleReferences.length > 0 && (
                    <>
                        {articleReferences.map((article) => (
                            <ArticleCard {...article} key={article.id} />
                        ))}
                        {/* Should be in the API */}
                        {morePath && (
                            <Link to={morePath} className={$.link}>
                                {moreLabel}
                            </Link>
                        )}
                    </>
                )}
                {(!articleReferences || articleReferences.length === 0) && (
                    <p data-testid="no-articles">{t("no_articles")}</p>
                )}
            </section>
        </ModuleWrapper>
    );
};

export default ArticleList;
