import { isArray } from "utils/checkType";
import { Notifications as NotifcationsEntity } from "../entities";
import { Notification } from "../models";
import { createNotification } from ".";

export default function createNotifications(
    data: NotifcationsEntity,
): Notification[] {
    const inboxpointers = data.inboxpointers && isArray(data.inboxpointers);
    const notifications = inboxpointers.map((inboxpointer) =>
        createNotification(inboxpointer),
    );

    return notifications;
}
