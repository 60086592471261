import { CtaLists as CtaListsModel } from "core/models";
import * as React from "react";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { Button, CardColumn, ModuleWrapper } from "view/components";
import { GridRow } from "view/components/Grid";
import $ from "./CtaLists.module.scss";

function getColumnContent(title, list) {
    if (!list) {
        return null;
    }
    return (
        <CardColumn title={title} dark sameHeight spaceBetween id="column">
            <div>
                {list.map(
                    (button) =>
                        MenuItemIsVisible(button) && (
                            <Button
                                key={button.id}
                                path={
                                    button.path ||
                                    button.slug ||
                                    button.externalUrl
                                }
                                title={button.title}
                                color="white"
                                className={$.button}
                            />
                        ),
                )}
            </div>
        </CardColumn>
    );
}

const CtaLists = (props: CtaListsModel) => {
    const {
        title,
        list1,
        list1Title,
        list2,
        list2Title,
        list3,
        list3Title,
        colorBackground,
    } = props;

    const contentColumn1 = getColumnContent(list1Title, list1);
    const contentColumn2 = getColumnContent(list2Title, list2);
    const contentColumn3 = getColumnContent(list3Title, list3);

    return (
        <ModuleWrapper title={title} background={colorBackground} anchor="cta">
            <GridRow>
                <>
                    {contentColumn1}
                    {contentColumn2}
                    {contentColumn3}
                </>
            </GridRow>
        </ModuleWrapper>
    );
};

export default CtaLists;
