import * as React from "react";
import { createRef, useEffect, useState } from "react";
import createDebounce from "utils/debounce";
import { Card } from "view/components";
import FaqIcon from "../FaqIcon";
import $ from "./FaqItem.module.scss";

interface Props {
    question: string;
    answer: string;
    dark: boolean;
}

const FaqItem = (props: Props) => {
    const elementRef = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState<number | string>();
    const { question, answer, dark } = props;

    const setItemHeight = () => {
        const el = elementRef.current;
        const faqItemHeight = el ? el.scrollHeight : "auto";

        if (open) {
            return setHeight(faqItemHeight);
        }

        return setHeight(0);
    };

    const resizeDebounce = createDebounce(200);
    const handleResize = () => {
        resizeDebounce(() => {
            setOpen(false);
            setItemHeight();
        });
    };
    useEffect(() => {
        setItemHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card className={$.base} dark={dark}>
            <button
                type="button"
                onClick={() => setOpen(!open)}
                className={$.toggleButton}
                data-testid="button"
            >
                <FaqIcon isPlus={open} />
                <h4 className={$.question}>{question}</h4>
            </button>
            <section
                className={$.answerWrap}
                ref={elementRef}
                style={{ height }}
                data-testid="answer-wrap"
            >
                <div
                    className={$.answer}
                    data-testid="answer"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: answer }}
                />
            </section>
        </Card>
    );
};

export default FaqItem;
