import { isString } from "utils/checkType";
import { ContactpersonOverview } from "../models";
import { ModuleContactpersonOverview } from "../entities";

export default function createContactpersonOverview(
    data: ModuleContactpersonOverview,
): ContactpersonOverview {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        colorBackground,
    };
}
