import classnames from "classnames";
import { MOBILE } from "core/constants/global";
import { Hero as HeroModel } from "core/models";
import * as React from "react";
import { useWindowWidth } from "utils/useWindowWidth";
import { Helmet } from "react-helmet";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { Body, Button, Card, Container, Image } from "view/components";
import $ from "./Hero.module.scss";

function renderDownloads(appstoreUri?: string, playstoreUri?: string) {
    return (
        <Card className={$.downloads}>
            <h3 className={$.downloadsTitle}>
                Download nu de Altijd Fris app!
            </h3>
            <a
                className={$.downloadsButton}
                href={playstoreUri}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    className={$.downloadsButtonImage}
                    src="/static/images/googleplay.png"
                    alt="Google Play - Altijd fris"
                />
            </a>
            <a
                className={$.downloadsButton}
                href={appstoreUri}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    className={$.downloadsButtonImage}
                    src="/static/images/appstore.svg"
                    alt="AppStore - Altijd fris"
                />
            </a>
            <p className={$.downloadsText}>
                Gratis bezorging binnen twee werkdagen
            </p>
        </Card>
    );
}

function renderUspItems(uspTitle?: string, uspItems?: string[]) {
    if (!uspItems || uspItems.length <= 0) {
        return null;
    }

    return (
        <div className={$.benefits}>
            <ul className={$.benefitsList}>
                {uspTitle && (
                    <li
                        className={classnames(
                            $.benefitsItem,
                            $.benefitsItemTitle,
                        )}
                    >
                        <h3 className={$.benefitsTitle}>{uspTitle}</h3>
                    </li>
                )}

                {uspItems &&
                    uspItems.map((uspItem) => (
                        <li
                            className={$.benefitsItem}
                            key={uspItem + Math.random()}
                        >
                            {uspItem}
                        </li>
                    ))}
            </ul>
        </div>
    );
}

const Hero = (props: HeroModel) => {
    const {
        title,
        text,
        textBlockHideBackground,
        image: imageDesktop,
        imageMobile,
        ctaPrimary,
        ctaSecondary,
        uspTitle,
        uspItems,
        appstoreUri,
        playstoreUri,
        alignment,
    } = props;

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE;
    const imgWidth = isMobile ? 1125 : 1800;
    const hasDownloads = appstoreUri || playstoreUri;
    const hasButton = ctaPrimary || ctaSecondary;

    const heroImage = isMobile && imageMobile?.url ? imageMobile : imageDesktop;

    return (
        <section
            className={classnames(
                $.hero,
                hasDownloads && $.hasDownloads,
                alignment === "Center" && $.center,
                alignment === "Right" && $.right,
            )}
            data-testid="hero"
        >
            {heroImage && heroImage.url && (
                <>
                    <Helmet>
                        <link
                            rel="preload"
                            href={`${heroImage.url}?w=${imgWidth}&q=80&fm=jpg&fl=progressive`}
                            as="image"
                        />
                    </Helmet>
                    <Image
                        placeholder={`${heroImage.url}?w=30&q=50&fm=jpg&fl=progressive`}
                        src={`${heroImage.url}?w=${imgWidth}&q=80&fm=jpg&fl=progressive`}
                        alt={heroImage.title}
                        className={$.image}
                        background
                    />
                </>
            )}

            <Container>
                {title && (
                    <h1
                        className={classnames(
                            $.title,
                            alignment === "Center" && $.center,
                            alignment === "Right" && $.right,
                        )}
                        data-testid="title"
                    >
                        {title}
                    </h1>
                )}

                {text && (
                    <Body
                        content={text}
                        className={classnames(
                            $.text,
                            textBlockHideBackground && $.noBackground,
                        )}
                    />
                )}

                {hasButton && (
                    <div className={$.buttonContainer}>
                        {ctaPrimary &&
                            ctaPrimary.title &&
                            MenuItemIsVisible(ctaPrimary) && (
                                <Button
                                    className={$.button}
                                    color="turquiose"
                                    title={ctaPrimary.title}
                                    path={
                                        ctaPrimary.slug ||
                                        ctaPrimary.path ||
                                        ctaPrimary.externalUrl
                                    }
                                    small
                                />
                            )}

                        {ctaSecondary &&
                            ctaSecondary.title &&
                            MenuItemIsVisible(ctaSecondary) && (
                                <Button
                                    className={$.button}
                                    color="green"
                                    title={ctaSecondary.title}
                                    path={
                                        ctaSecondary.slug ||
                                        ctaSecondary.path ||
                                        ctaSecondary.externalUrl
                                    }
                                    small
                                />
                            )}
                    </div>
                )}

                {hasDownloads && renderDownloads(appstoreUri, playstoreUri)}

                {uspItems && renderUspItems(uspTitle, uspItems)}
            </Container>
        </section>
    );
};

export default Hero;
