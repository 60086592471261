import { HtmlCode as HtmlCodeModel } from "core/models";
import * as React from "react";
import { Body, ModuleWrapper, Spinner } from "view/components";

const scriptRegExp = /<script.*?(src="(.*?)")?>(.*?)<\/script>/s;

interface Result {
    html: string;
    javascript: string[];
    scripts: string[];
}

const parseHtml = (
    html: string,
    javascript: string[] = [],
    scripts: string[] = [],
): Result => {
    const jsResult = scriptRegExp.exec(html);
    if (jsResult) {
        const [
            scriptBlock,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            srcBlock,
            javascriptSources,
            javascriptCode,
        ] = jsResult;
        const parsedHtml = html.replace(scriptBlock, "");
        return parseHtml(
            parsedHtml,
            javascript.concat(javascriptCode),
            scripts.concat(javascriptSources),
        );
    }
    return {
        html,
        javascript: javascript.filter(Boolean),
        scripts: scripts.filter(Boolean),
    };
};

const addedScriptsCache = new Map<string, boolean>();

const addScriptToTheDocument = (scriptSrc: string) => {
    if (!addedScriptsCache.get(scriptSrc)) {
        const scriptEl = document.createElement("script");
        scriptEl.async = true;
        scriptEl.src = scriptSrc;
        document.body.appendChild(scriptEl);
        addedScriptsCache.set(scriptSrc, true);
    }
};

const HtmlCode: React.FC<HtmlCodeModel> = (props: HtmlCodeModel) => {
    // This useless-rename (title: moduleTitle) is needed for IE11 and the evel() function to work.
    const { title: moduleTitle, html, colorBackground } = props;
    const parsed = React.useMemo(() => parseHtml(html), [html]);
    const [pending, setPending] = React.useState(false);

    React.useEffect(() => {
        try {
            // eval is not recommanded but due time constraints used until
            // we have found a way to use scripts in the api without calling
            // it this way
            //
            // @patrick @kostas @rdeveen
            setPending(true);
            parsed.scripts.forEach(addScriptToTheDocument);
            setTimeout(() => {
                setPending(false);
                parsed.javascript.forEach((code) => {
                    // eslint-disable-next-line no-eval
                    eval(code);
                });
            }, 1000);
        } catch (e) {
            // console.error(e.message);
            setPending(false);
        }
    }, [parsed]);

    return (
        <ModuleWrapper title={moduleTitle} background={colorBackground}>
            {pending ? (
                <Spinner />
            ) : (
                <Body content={parsed?.html ? parsed.html : html} />
            )}
        </ModuleWrapper>
    );
};

export default HtmlCode;
