import { useState, useMemo } from "react";
import fetchNotifications from "core/services/fetchNotifications";
import { ConfigContextType } from "view/context/ConfigContext";
import { Notification } from "core/models";
import { endpoints } from "core/constants";

export default function useNotificationsService(
    config: ConfigContextType | null,
    dataUri: string,
    token: string | undefined,
) {
    const endpoint = dataUri || endpoints.inbox();
    const [pending, setPending] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<Notification[] | null>(
        null,
    );

    const fetchData = useMemo(
        () => async () => {
            setPending(true);
            if (!config || !token) {
                setPending(false);
                return null;
            }
            const response: Notification[] = await fetchNotifications(
                config,
                endpoint,
                token,
            );
            if (response) {
                setNotifications(response);
            }
            setPending(false);
            return null;
        },
        [config, endpoint, token],
    );

    return { pending, fetchData, notifications };
}
