import * as React from "react";
import classnames from "classnames";
// eslint-disable-next-line import/extensions
import { Props as GridColumnProps } from "./Grid.Column";
import $ from "./Grid.module.scss";

type Props = {
    className?: string;
    children:
        | React.ReactElement<GridColumnProps>[]
        | React.ReactElement<GridColumnProps>;
};

const GridRow: React.FC<Props> = (props) => {
    const { children, className } = props;
    const numberOfColumns = Array.isArray(children) ? children.length : 1;
    return (
        <section
            className={classnames([
                $.row,
                `${$.row}-${numberOfColumns}`,
                className,
            ])}
            data-testid="row"
        >
            {children}
        </section>
    );
};

export default GridRow;
