import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import history from "utils/history";

import "core/constants/i18n";
import App from "view/components/App/App";
import "./styles/global.scss";

import { paths } from "core/constants";
import ScrollToTop from "utils/ScrollToTop";
import { Button, Container, BottomButtonContainer } from "view/components";

const onRedirectCallback = (appState: AppState) => {
    // Redirect to the page that was requested before the login. Or go to the dashboard.
    history.replace(appState?.targetUrl ? appState.targetUrl : paths.dashboard);
};

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
    <Container>
        <div role="alert">
            <h1>Er is een fout opgetreden.</h1>
            <pre style={{ color: "red" }}>{error.message}</pre>
            <BottomButtonContainer>
                <Button
                    onClick={resetErrorBoundary}
                    title="Herladen"
                    color="turquiose"
                />
                <Button
                    onClick={() => {
                        resetErrorBoundary();
                        history.goBack();
                    }}
                    title="Ga terug"
                    color="white"
                />
            </BottomButtonContainer>
        </div>
    </Container>
);
const dontMatchMe = "{apiU";
const placeholderValue = `#${dontMatchMe}rl}#`;
if (window.APP_SETTINGS.apiUrl === placeholderValue) {
    throw new Error(
        `APP_SETTINGS.apiUrl contains placeholder value: '${placeholderValue}'`,
    );
}
function createApp() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Auth0Provider
                domain={window.APP_SETTINGS.domain}
                clientId={window.APP_SETTINGS.clientId}
                audience={window.APP_SETTINGS.audience}
                redirectUri={window.APP_SETTINGS.redirectUri}
                onRedirectCallback={onRedirectCallback}
                returnTo={window.location.origin}
                cacheLocation="localstorage" // valid values are: 'memory' (default) or 'localstorage',
                useRefreshTokens
            >
                <Router history={history}>
                    <ScrollToTop />
                    <App config={window.APP_SETTINGS} />
                </Router>
            </Auth0Provider>
        </ErrorBoundary>
    );
}

const mountElement = document.getElementById("app");
ReactDOM.render(createApp(), mountElement);
