import { DESKTOP, MOBILE } from "core/constants/global";
import { Article as ArticleProps } from "core/models";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import nl from "date-fns/locale/nl";
import parseISO from "date-fns/parseISO";
import * as React from "react";
import { Helmet } from "react-helmet";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { useWindowWidth } from "utils/useWindowWidth";
import { Hero } from "view/modules";
import {
    ArchiveMenu,
    Body,
    Button,
    Card,
    Image,
    LinkFeatured,
} from "view/components";
import ModuleHandler from "view/modules/ModuleHandler";
import Container from "../Container";
import $ from "./Article.module.scss";

function renderSocialLink(title, href, imageSrc) {
    return (
        <li className={$.shareItem}>
            <a
                className={$.shareLink}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={imageSrc} alt={title} />
            </a>
        </li>
    );
}

function renderShareModule(isDesktop, props) {
    const { title, intro } = props;
    const ShareContainer = isDesktop ? "span" : Card;
    const ShareProps = isDesktop ? undefined : { dark: true };

    const url = window.location.href;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterLink = `https://twitter.com/intent/tweet?text=${intro} ${url}`;
    const linkedinLink = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${intro}&source=Vrumona`;
    const emailLink = `mailto:?subject=${title}&body=${url}`;

    return (
        <ShareContainer {...ShareProps}>
            <ul className={$.share}>
                {renderSocialLink(
                    "Facebook",
                    facebookLink,
                    "/static/images/ic-facebook.svg",
                )}
                {renderSocialLink(
                    "Twitter",
                    twitterLink,
                    "/static/images/ic-twitter.svg",
                )}
                {renderSocialLink(
                    "LinkedIn",
                    linkedinLink,
                    "/static/images/ic-linkedin.svg",
                )}
                {renderSocialLink(
                    "E-mail",
                    emailLink,
                    "/static/images/ic-envelope.svg",
                )}
            </ul>
        </ShareContainer>
    );
}

const Article: React.FC<ArticleProps> = (props: ArticleProps) => {
    const {
        id,
        title,
        intro,
        text,
        image,
        ctaPrimary,
        ctaSecondary,
        categoryPrimary,
        categories,
        links,
        modules,
        createdAt,
    } = props;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE;
    const isDesktop = windowWidth >= DESKTOP;
    const ContainerComponent = isDesktop ? Container : "span";

    const formatCreatedAtDateLong = React.useCallback(
        () =>
            formatDistance(parseISO(createdAt), new Date(), {
                locale: nl,
                addSuffix: true,
            }),
        [createdAt],
    );

    const formatCreatedAtDate = React.useCallback(
        () => format(parseISO(createdAt), "dd MMMM yyyy 'om' HH:mm 'uur'"),
        [createdAt],
    );

    return (
        <section data-testid="article">
            <Helmet>
                <meta property="og:type" content="article" />
                {createdAt && (
                    <meta
                        property="article:published_time"
                        content={createdAt}
                    />
                )}
                {categoryPrimary && categoryPrimary.title && (
                    <meta
                        property="article:section"
                        content={categoryPrimary.title}
                    />
                )}
                {image && image.url && (
                    <meta property="og:image" content={image.url} />
                )}

                {intro && <meta property="og:description" content={intro} />}

                {image && image.url && isMobile && (
                    <link
                        rel="preload"
                        href={`${image.url}?w=${1125}&q=80`}
                        as="image"
                    />
                )}
            </Helmet>

            <ArchiveMenu
                activeCategorySlug={categoryPrimary && categoryPrimary.slug}
                categories={categories}
            />

            <article>
                {/* Desktop */}
                {!isMobile && <Hero id={id} title={title} image={image} />}

                {/* Mobile */}
                {isMobile && image && image.url && (
                    <Image
                        placeholder={`${image.url}?w=30&q=50&fm=jpg&fl=progressive`}
                        src={`${
                            image.url
                        }?w=${1125}&q=80&fm=jpg&fl=progressive`}
                        alt={image.url}
                        className={$.image}
                    />
                )}

                <ContainerComponent>
                    <Container>
                        {isMobile && <h1 className={$.title}>{title}</h1>}
                    </Container>

                    <div className={$.columnContainer}>
                        <Container className={$.buttonContainer}>
                            {ctaSecondary &&
                                MenuItemIsVisible(ctaSecondary) && (
                                    <Button
                                        color="turquiose"
                                        className={$.button}
                                        title={ctaSecondary.title}
                                        path={
                                            ctaSecondary.slug ||
                                            ctaSecondary.path ||
                                            ctaSecondary.externalUrl
                                        }
                                    />
                                )}

                            {ctaPrimary && MenuItemIsVisible(ctaPrimary) && (
                                <Button
                                    color="green"
                                    className={$.button}
                                    title={ctaPrimary.title}
                                    path={
                                        ctaPrimary.slug ||
                                        ctaPrimary.path ||
                                        ctaPrimary.externalUrl
                                    }
                                />
                            )}
                        </Container>

                        {renderShareModule(isDesktop, props)}

                        <Container className={$.textContainer}>
                            <Body content={text} className={$.text} />
                            {links && links.length > 0 && (
                                <div className={$.linksContainer}>
                                    {links.map((link) => (
                                        <React.Fragment
                                            key={link.externalUrl + link.title}
                                        >
                                            {link.externalUrl && (
                                                <LinkFeatured
                                                    to={link.externalUrl}
                                                    title={link.title}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}

                            {createdAt && (
                                <div className={$.createdAt}>
                                    Gepubliceerd:{" "}
                                    <dfn title={formatCreatedAtDate()}>
                                        {formatCreatedAtDateLong()}
                                    </dfn>
                                </div>
                            )}
                        </Container>
                    </div>
                </ContainerComponent>
            </article>

            {modules && <ModuleHandler modules={modules} />}
        </section>
    );
};

export default Article;
