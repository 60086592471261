import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "core/constants";
import { Account } from "core/models";
import * as React from "react";
import { Button } from "view/components";
import { AccountContext } from "view/context/AccountContext";
import $ from "./AccountSwitch.module.scss";

type AccountSwitchProps = {
    Accounts: Array<Account>;
};

const AccountSwitch: React.FC<AccountSwitchProps> = (
    props: AccountSwitchProps,
) => {
    const { Accounts } = props;

    const { user } = useAuth0();
    const { selectedAccount, setSelectedAccount } =
        React.useContext(AccountContext);

    const createAccountButton = (account: Account) => {
        const color =
            selectedAccount?.id === account.id ? "turquiose" : "white";

        return (
            <Button
                key={account.id}
                title={account.name}
                className={$.button}
                path={paths.dashboard}
                onClick={() => setSelectedAccount(account)}
                color={color}
            />
        );
    };

    // Don't show control if there is only one account.
    if (
        user &&
        user["https://www.vrumona.nl/crm"]?.accountnumber.length === 1
    ) {
        return null;
    }

    return (
        <div className={$.accountSwitch}>
            <strong>Selecteer account</strong>
            {Accounts &&
                Accounts.map((account) => createAccountButton(account))}
        </div>
    );
};

export default AccountSwitch;
