import { FetchError } from "../models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function createError(result: Entity): FetchError {
    return {
        type: "fetch-error",
        ...result,
    };
}
