import { isString, isObject, isBool } from "utils/checkType";
import { Page } from "../models";
import { Page as PageEntity } from "../entities";
import createModules from "./createModules";

export default function createPage(data: PageEntity): Page {
    if (!data) throw new Error("no data");

    const type = "page";
    const id = isString(data.id);
    const title = isString(data.title);
    const slug = isString(data.slug);
    const contentType = data.contentType && isString(data.contentType);
    const seoDescription = data.seoDescription && isString(data.seoDescription);
    const noIndex = data.noIndex && isBool(data.noIndex);
    const seoKeywords = data.seoKeywords && isString(data.seoKeywords);
    const moduleReferences = data.modules && isObject(data.modules);
    const modules = createModules(moduleReferences);
    const requiredLogin = data.requiredLogin && isBool(data.requiredLogin);

    return {
        type,
        id,
        title,
        slug,
        contentType,
        seoDescription,
        seoKeywords,
        modules,
        noIndex,
        requiredLogin,
    };
}
