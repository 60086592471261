import * as React from "react";
import classnames from "classnames";
import { Button } from "view/components";
import $ from "./FormResponse.module.scss";

interface Props {
    title: string;
    text: string;
    link?: string;
    buttonText?: string;
    id?: string;
    type?: "success" | "error";
}

const FormResponse = (props: Props) => {
    const { title, text, link, buttonText, id, type } = props;

    return (
        <div
            className={classnames([$.base, type === "error" && $.error])}
            data-testid="formResponse"
            id={id}
        >
            <h2 className={$.title}>{title}</h2>
            <p className={$.text}>{text}</p>

            {link && buttonText && (
                <Button
                    title={buttonText}
                    path={link}
                    className={$.button}
                    color="turquiose"
                />
            )}
        </div>
    );
};

export default FormResponse;
