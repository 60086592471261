import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { account, paths } from "core/constants";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DESKTOP } from "core/constants/global";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { Button, Link, LoginPanel, Logo, NavItem } from "view/components";
import ConfigContext from "view/context/ConfigContext";
import SettingsContext from "view/context/SettingsContext";
import { useWindowWidth } from "utils/useWindowWidth";
import { RouteDefinition } from "../../routes";
import $ from "./Header.module.scss";

type Props = {
    route: RouteDefinition;
};

const Header: React.FC<Props> = ({ route }) => {
    const dialog = React.createRef<HTMLDivElement>();
    const settingsContext = React.useContext(SettingsContext);
    React.useContext(ConfigContext);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [activeSecondaryNav, setActiveSecondaryNav] = React.useState("");
    const { isAuthenticated } = useAuth0();
    const { t } = useTranslation();

    const changeActiveSecondaryNav = (subMenu) => {
        setActiveSecondaryNav(subMenu);
    };

    const handleClickOutside = (e) => {
        if (dialog.current && dialog.current.contains(e.target)) {
            return;
        }
        setActiveSecondaryNav("");
    };

    const topMenu = settingsContext?.topMenu.filter((item) =>
        MenuItemIsVisible(item),
    );

    const mainMenu = settingsContext?.mainMenu.filter((item) =>
        MenuItemIsVisible(item),
    );

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    React.useEffect(() => {
        setMenuOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    const windowWidth = useWindowWidth();
    const isDesktop = windowWidth >= DESKTOP;
    return (
        <header className={$.header}>
            {settingsContext && (
                <nav>
                    <div className={$.navBar}>
                        <button
                            className={$.menuButton}
                            type="button"
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            {!menuOpen && (
                                <img
                                    src="/static/images/ic-hamburger.svg"
                                    alt={t("openMenu")}
                                    title={t("openMenu")}
                                    className={$.menuButtonImg}
                                />
                            )}
                            {menuOpen && (
                                <img
                                    src="/static/images/ic-close.svg"
                                    alt={t("closeMenu")}
                                    title={t("closeMenu")}
                                    className={$.menuButtonImg}
                                />
                            )}
                        </button>
                        <Logo white mobileOnly />
                    </div>
                    <div
                        className={classnames([$.nav, menuOpen && $.navOpen])}
                        ref={dialog}
                    >
                        {!isAuthenticated && account.loginButton && (
                            <Button
                                {...account.loginButton}
                                color="white"
                                className={$.loginButton}
                            />
                        )}

                        {/* Don't call an API on consent screen, infinity
                            loop. */}
                        {isAuthenticated && route.path !== paths.consent && (
                            <LoginPanel />
                        )}
                        <section className={$.headMenu}>
                            <h2 className={$.srOnly}>{t("menu")}</h2>
                            <div className={$.logo}>
                                <Logo desktopOnly />
                            </div>
                            <ul className={$.navItems}>
                                {mainMenu &&
                                    mainMenu.map((menuItem) => (
                                        <NavItem
                                            {...menuItem}
                                            key={menuItem.title}
                                            activeSubMenu={activeSecondaryNav}
                                            changeActiveSubMenu={
                                                changeActiveSecondaryNav
                                            }
                                        />
                                    ))}
                                {!isAuthenticated && isDesktop && (
                                    <li>
                                        <Button
                                            narrow
                                            path={paths.becomeCustomer}
                                            title="Klant worden?"
                                            color="turquiose"
                                        />
                                    </li>
                                )}
                            </ul>
                        </section>
                        <section className={$.subMenu}>
                            <h2 className={$.srOnly}>{t("sub_menu")}</h2>
                            <ul className={$.subNavItems}>
                                {topMenu &&
                                    topMenu.map((menuItem) => (
                                        <li
                                            className={$.subNavItem}
                                            key={menuItem.title}
                                        >
                                            <Link
                                                to={menuItem.path}
                                                className={$.subNavLink}
                                            >
                                                {menuItem.title}
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                        </section>
                    </div>
                </nav>
            )}
        </header>
    );
};

export default Header;
