export interface ContactTypeEntity {
    id: string;
    name: string;
    label: string;
}

const getContactTypeName = (name) => {
    if (name === "Algemeen") return "general";
    if (name === "Product vraag/klacht") return "product";
    if (name === "Actie/Promotie vraag/klacht") return "specialOffer";
    return "general";
};

export class ContactType {
    id: string;

    // eslint-disable-next-line lines-between-class-members
    name: "product" | "specialOffer" | "general";

    // eslint-disable-next-line lines-between-class-members
    value: string;

    // eslint-disable-next-line lines-between-class-members
    label: string;

    constructor(contactType: ContactTypeEntity) {
        this.id = contactType.id;
        this.name = getContactTypeName(contactType.name);
        this.value = contactType.name;
        this.label = contactType.label;
    }
}
