import { isString, isImage, isCategory } from "utils/checkType";
import { ArticleReferenceEntity } from "../entities";
import { ArticleReference } from "../models";

export default function createArticleReference(
    data: ArticleReferenceEntity,
): ArticleReference {
    const id = isString(data.id);
    const title = data.title && isString(data.title);
    const slug = data.slug && isString(data.slug);
    const path = data.path && isString(data.path);
    const intro = data.intro && isString(data.intro);
    const image = data.image && isImage(data.image);
    const readTime = data.readTime && isString(data.readTime);
    const createdAt = data.createdAt && isString(data.createdAt);
    const category = data.categoryPrimary && isCategory(data.categoryPrimary);

    return {
        id,
        title,
        slug,
        path,
        intro,
        image,
        readTime,
        createdAt,
        category,
    };
}
