import { isString } from "utils/checkType";
import { ConsentData as ConsentEntity } from "../entities";
import { ConsentData } from "../models";

export default function createConsentData(data: ConsentEntity): ConsentData {
    const firstName = data.firstName && isString(data.firstName);
    const lastName = data.lastName && isString(data.lastName);
    const email = data.email && isString(data.email);

    return {
        firstName,
        lastName,
        email,
    };
}
