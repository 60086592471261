import { isString } from "utils/checkType";
import { ModuleBrands } from "../entities";
import { Brands } from "../models";
import createBrandThumb from "./createBrandThumb";

export default function createBrands(data: ModuleBrands): Brands {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);

    return {
        id,
        contentType,
        moduleType,
        title,
        brands: data.brands.map((brand) => createBrandThumb(brand)),
    };
}
