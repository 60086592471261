import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import paths from "core/constants/paths";
import {
    MasterDataGeneralViewModel,
    MasterDataInvoiceViewModel,
    MasterDataOverview as MasterDataOverviewModel,
    Account,
    FormFields,
} from "core/models";
import ConfigContext from "view/context/ConfigContext";
import useAccountService from "view/hooks/useAccountService";
import useTokenService from "view/hooks/useTokenService";
import useParam from "view/hooks/useParam";
import { useContext } from "react";
import BackgroundColorContext from "view/context/BackgroundColorContext";
import {
    CardColumn,
    FormResponse,
    MasterDataSetEditor,
    ModuleWrapper,
    Spinner,
} from "view/components";
import { GridRow } from "view/components/Grid";
import {
    createGeneralEditFields,
    createGeneralViewModelFields,
    createInvoiceEditFields,
    createInvoiceViewModelFields,
} from "./MasterDataFields";
import $ from "./MasterDataOverview.module.scss";

const MasterDataOverview: React.FC<
    MasterDataOverviewModel & RouteComponentProps
> = (props: MasterDataOverviewModel & RouteComponentProps) => {
    const { title, history } = props;
    const { user, getAccessTokenSilently } = useAuth0();
    const configContext = useContext(ConfigContext);
    const colorBackground = useContext(BackgroundColorContext);
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { fetchData, sendData, data, pending, error } = useAccountService(
        configContext,
        token,
    );

    const [account, setAccount] = React.useState<Account>({} as Account);

    const [queryStringEditParam] = useParam("edit");
    const isInEditMode = {
        general: queryStringEditParam === "general",
        invoice: queryStringEditParam === "invoice",
        contact: queryStringEditParam === "contact",
    };

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    React.useEffect(() => {
        setAccount(data);
    }, [data]);

    const [generalViewModel, setGeneralViewModel] = React.useState(
        {} as MasterDataGeneralViewModel,
    );
    const [invoicingViewModel, setInvoicingViewModel] = React.useState(
        {} as MasterDataInvoiceViewModel,
    );

    const [generalEditFieldSet, setGeneralEditFieldSet] = React.useState(
        [] as FormFields[],
    );
    const [generalViewModelFieldSet, setGeneralViewModelFieldSet] =
        React.useState([] as FormFields[]);

    const [invoiceEditFieldSet, setInvoiceEditFieldSet] = React.useState(
        [] as FormFields[],
    );
    const [invoiceViewModelFieldSet, setInvoiceViewModelFieldSet] =
        React.useState([] as FormFields[]);

    React.useEffect(() => {
        if (!account) {
            return;
        }

        let address1 = `${account.address1line1 ?? ""} ${
            account.address1line2 ?? ""
        }${account.address1line3 ?? ""}</br>${
            account.address1postalcode ?? ""
        } ${account.address1city ?? ""}`;

        if (address1.trim() === "</br>") {
            address1 = "";
        }

        let address2 = `Postbus ${account?.postalBoxNumber ?? ""}</br>${
            account?.address2postalcode ?? ""
        } ${account?.address2city ?? ""}`;

        if (!account?.postalboxaddress) {
            address2 = `${account?.address2line1 ?? ""} ${
                account?.address2line2 ?? ""
            }${account?.address2line3 ?? ""}</br>${
                account?.address2postalcode ?? ""
            } ${account?.address2city ?? ""}`;
        }

        if (address2.trim() === "</br>") {
            address2 = "";
        }

        setGeneralViewModel({
            address1,
            address2,
            chamberOfCommerceNumber: account?.chamberOfCommerceNumber,
            telephone: account?.telephone,
            emailaddress1: account?.emailaddress1,
        });

        setInvoicingViewModel({
            emailinvoice: account?.emailinvoice,
            emailaddress2: account?.emailaddress2,
            emailinvoicecc: account?.emailinvoicecc,
            ibanNumber: account?.ibanNumber,
            alternativename: account?.alternativename,
            invoiceto: account?.invoiceto,
            vatNumber: account?.vatNumber,
        });

        setGeneralViewModelFieldSet(createGeneralViewModelFields(account));
        setGeneralEditFieldSet(createGeneralEditFields(account));

        setInvoiceViewModelFieldSet(createInvoiceViewModelFields(account));
        setInvoiceEditFieldSet(createInvoiceEditFields(account));
    }, [account]);

    const sendAllData = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!e.currentTarget?.checkValidity()) {
            return;
        }

        if (!account.hasDifferentPostAddress) {
            account.address2city = account.address1city;
            account.address2line1 = account.address1line1;
            account.address2line2 = account.address1line2;
            account.address2line3 = account.address1line3;
            account.address2postalcode = account.address1postalcode;
            account.postalBoxNumber = undefined;
            account.postalboxaddress = false;
        }
        if (account.hasDifferentPostAddress && account.postalboxaddress) {
            account.address2line1 = undefined;
            account.address2line2 = account.postalBoxNumber;
            account.address2line3 = undefined;
        }
        account.emailinvoiceoldvalue = account.emailinvoice;

        sendData(account).then((result) => {
            if (result) {
                history.push(paths.account);
            }
        });
    };

    const handleInputChange = (value: boolean | string, name: string) => {
        if (account) {
            setAccount({
                ...account,
                [name]: value,
            });
        }
    };

    if (isInEditMode.contact) return null;

    return (
        <ModuleWrapper
            title={title}
            background={colorBackground?.backgroundColor}
        >
            <form onSubmit={sendAllData}>
                <GridRow>
                    <>
                        {!isInEditMode.invoice && (
                            <CardColumn
                                dark={
                                    colorBackground?.backgroundColor !== "Grey"
                                }
                                stretch
                                id="column_general"
                            >
                                {!pending ? (
                                    <>
                                        <MasterDataSetEditor
                                            id="set_general"
                                            viewModelFieldset={
                                                generalViewModelFieldSet
                                            }
                                            title={account?.name}
                                            editFieldset={generalEditFieldSet}
                                            viewModel={generalViewModel}
                                            dataSource={account}
                                            showEditor={isInEditMode.general}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />

                                        {!!error && (
                                            <FormResponse
                                                title="Er is iets fout gegaan"
                                                text="Probeer het later nog eens."
                                                type="error"
                                            />
                                        )}
                                        {!isInEditMode.general && (
                                            <button
                                                type="button"
                                                data-testid="editButton"
                                                className={$.editButton}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: paths.account,
                                                        search: "?edit=general",
                                                    })
                                                }
                                            >
                                                Bewerken
                                            </button>
                                        )}
                                        {isInEditMode.general && (
                                            <div className={$.buttonWrap}>
                                                <button
                                                    type="submit"
                                                    className={$.submitButton}
                                                    data-testid="submitButton"
                                                >
                                                    Opslaan
                                                </button>
                                                <button
                                                    type="button"
                                                    className={$.cancelButton}
                                                    onClick={() => {
                                                        fetchData();
                                                        history.goBack();
                                                    }}
                                                    data-testid="backButton"
                                                >
                                                    Annuleren
                                                </button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <Spinner />
                                )}
                            </CardColumn>
                        )}
                        {!isInEditMode.general && (
                            <CardColumn
                                dark={
                                    colorBackground?.backgroundColor !== "Grey"
                                }
                                stretch
                                id="column_invoice"
                            >
                                {!pending ? (
                                    <>
                                        <MasterDataSetEditor
                                            id="set_invoice"
                                            title="Facturatiegegevens"
                                            viewModelFieldset={
                                                invoiceViewModelFieldSet
                                            }
                                            editFieldset={invoiceEditFieldSet}
                                            viewModel={invoicingViewModel}
                                            dataSource={account}
                                            showEditor={isInEditMode.invoice}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />

                                        {!!error && (
                                            <FormResponse
                                                title="Er is iets fout gegaan"
                                                text="Probeer het later nog eens."
                                                type="error"
                                            />
                                        )}

                                        {!isInEditMode.invoice && (
                                            <button
                                                type="button"
                                                data-testid="invoiceEditButton"
                                                className={$.editButton}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: paths.account,
                                                        search: "?edit=invoice",
                                                    })
                                                }
                                            >
                                                Bewerken
                                            </button>
                                        )}
                                        {isInEditMode.invoice && (
                                            <div className={$.buttonWrap}>
                                                <button
                                                    type="submit"
                                                    className={$.submitButton}
                                                    data-testid="submitButton"
                                                >
                                                    Opslaan
                                                </button>
                                                <button
                                                    type="button"
                                                    className={$.cancelButton}
                                                    onClick={() => {
                                                        fetchData();
                                                        history.goBack();
                                                    }}
                                                    data-testid="backButton"
                                                >
                                                    Annuleren
                                                </button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <Spinner />
                                )}
                            </CardColumn>
                        )}
                    </>
                </GridRow>
            </form>
        </ModuleWrapper>
    );
};

export default withRouter(MasterDataOverview);
