import { FormFields, FormFieldType } from "core/models";

export function entrepeneursFields(contactSubType?: string): FormFields[] {
    return [
        {
            type: FormFieldType.Hidden,
            name: "contactSubType",
            placeholder: contactSubType || "",
        },
        {
            type: FormFieldType.Label,
            name: "bedrijfsGegevensLabel",
            placeholder: "Bedrijfsgegevens",
        },
        {
            type: FormFieldType.Text,
            name: "companyname",
            placeholder: "Bedrijfsnaam",
            maxLength: 100,
            required: true,
            autocomplete: "organization",
        },
        // {
        //     type: 'text',
        //     name: 'chamberOfCommerce',
        //     placeholder: 'KVK nummer',
        //     maxLength: 8,
        //     required: false,
        // },
        {
            type: FormFieldType.Text,
            name: "street",
            placeholder: "Straat",
            maxLength: 250,
            required: true,
            autocomplete: "street-address",
        },
        {
            type: FormFieldType.Text,
            name: "housenumber",
            placeholder: "Huisnummer",
            maxLength: 250,
            required: true,
        },
        {
            type: FormFieldType.Postalcode,
            name: "postalcode",
            placeholder: "Postcode",
            maxLength: 7,
            required: true,
            autocomplete: "postal-code",
        },
        {
            type: FormFieldType.Text,
            name: "city",
            placeholder: "Plaats",
            maxLength: 100,
            required: true,
            autocomplete: "address-level2",
        },
        {
            type: FormFieldType.Label,
            name: "jouwGegevensLabel",
            placeholder: "Jouw gegevens",
        },
        {
            type: FormFieldType.Text,
            name: "firstname",
            placeholder: "Voornaam",
            maxLength: 100,
            required: true,
            autocomplete: "given-name",
        },
        {
            type: FormFieldType.Text,
            name: "lastname",
            placeholder: "Achternaam",
            maxLength: 100,
            required: false,
            autocomplete: "family-name",
        },
        {
            type: FormFieldType.Tel,
            name: "phone",
            placeholder: "Telefoonnummer",
            required: true,
            autocomplete: "tel",
        },
        {
            type: FormFieldType.Email,
            name: "email",
            placeholder: "E-mailadres",
            maxLength: 100,
            required: true,
            autocomplete: "email",
        },
        {
            type: FormFieldType.Textarea,
            name: "description",
            placeholder: "Vraag of opmerking",
            maxLength: 2000,
            required: true,
        },
    ];
}
