import { lazy, Suspense, Fragment } from "react";
import * as React from "react";
import { ModuleType } from "core/models";
import BackgroundColorContext from "view/context/BackgroundColorContext";
import $ from "./ModuleHandler.module.scss";
import { Spinner } from "../../components";
import Genesys from "../Genesys";

const Brand = React.lazy(() => import("../Brand"));
const UspList = React.lazy(() => import("../UspList"));
const BrandsOverview = React.lazy(() => import("../BrandsOverview"));
const ReviewBlock = React.lazy(() => import("../ReviewBlock"));
const Brands = lazy(() => import("../Brands"));
const Hero = React.lazy(() => import("../Hero"));
const BonusTool = React.lazy(
    () => import("view/containers/BonusTool/Redirect"),
);
const BonusToolOverview = React.lazy(() => import("../BonusToolOverview"));
const TextImage = React.lazy(() => import("../TextImage"));
const ArticleList = React.lazy(() => import("../ArticleList"));
const CtaLists = React.lazy(() => import("../CtaLists"));
const Support = React.lazy(() => import("../Support"));
const Video = React.lazy(() => import("../Video"));
const HtmlCode = React.lazy(() => import("../HtmlCode"));
const Faq = React.lazy(() => import("../Faq"));
const Form = React.lazy(() => import("../Form"));
const Login = React.lazy(() => import("../Login"));
const Dashboard = React.lazy(() => import("../Dashboard"));
const AccountOverview = React.lazy(() => import("../AccountOverview"));
const MasterDataOverview = React.lazy(() => import("../MasterDataOverview"));
const ContactpersonOverview = React.lazy(
    () => import("../ContactpersonOverview"),
);
const InvoiceOverview = React.lazy(
    () => import("../InvoiceOverview/InvoiceOverview"),
);
const DocumentsOverview = React.lazy(
    () => import("../DocumentsOverview/DocumentsOverview"),
);

interface Props {
    modules: ModuleType[];
}

const renderModule = (module: ModuleType) => {
    switch (module.moduleType) {
        case "moduleHero":
            return <Hero {...module} />;
        case "moduleBrands":
            return <Brands {...module} />;
        case "moduleArticleList":
            return <ArticleList {...module} />;
        case "moduleTextImage":
            return <TextImage {...module} />;
        case "moduleCtaLists":
            return <CtaLists {...module} />;
        case "moduleFaq":
            return <Faq {...module} />;
        case "moduleSupport":
            return <Support {...module} />;
        case "moduleVideo":
            return <Video {...module} />;
        case "moduleHtmlCode":
            return <HtmlCode {...module} />;
        case "moduleContact":
            return <Form {...module} />;
        case "moduleLogin":
            return <Login {...module} />;
        case "moduleDashboard":
            return <Dashboard {...module} />;
        case "moduleBonusTool":
            return <BonusTool {...module} />;
        case "moduleBonusToolOverview":
            return <BonusToolOverview {...module} />;
        case "moduleAccountOverview":
            return <AccountOverview {...module} />;
        case "moduleInvoiceOverview":
            return <InvoiceOverview {...module} />;
        case "moduleDocumentsOverview":
            return <DocumentsOverview {...module} />;
        case "moduleMasterDataOverview":
            return <MasterDataOverview {...module} />;
        case "moduleContactpersonOverview":
            return <ContactpersonOverview {...module} />;
        case "moduleUspList":
            return <UspList {...module} />;
        case "moduleBrandsOverview":
            return <BrandsOverview {...module} />;
        case "moduleReviewBlock":
            return <ReviewBlock {...module} />;
        case "moduleBrand":
            return <Brand {...module} />;
        case "moduleGenesys":
            return <Genesys {...module} />;
        default:
            return null;
    }
};

const renderLoader = () => <Spinner />;

const ModuleHandler = (props: Props) => {
    const { modules } = props;

    return (
        <section className={$.moduleHandler}>
            <Suspense fallback={renderLoader()}>
                {modules.map((module: ModuleType, index: number) => (
                    // Combined key, if same module is set on page multiple times.
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={module.id + index}>
                        <BackgroundColorContext.Provider
                            // eslint-disable-next-line react/jsx-no-constructed-context-values
                            value={{
                                backgroundColor: module.colorBackground,
                            }}
                        >
                            {renderModule(module)}
                        </BackgroundColorContext.Provider>
                    </Fragment>
                ))}
            </Suspense>
        </section>
    );
};

export default ModuleHandler;
