import * as React from "react";
import { Link } from "..";
import $ from "./LinkFeatured.module.scss";

type Props = {
    to: string;
    title: string;
};

const LinkFeatured: React.FC<Props> = (props) => {
    const { to, title } = props;
    if (!to || !title) {
        return null;
    }
    return (
        <Link to={to} className={$.container}>
            <img
                src="/static/images/ic-external-link.svg"
                alt="external link"
            />
            <span>{title}</span>
        </Link>
    );
};

export default LinkFeatured;
