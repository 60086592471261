import { isString, isImage } from "utils/checkType";
import { ThtData, ThtProduct } from "../models";
import { ThtData as ThtDataEntity } from "../entities";
import { createProduct } from ".";

export default function createThtData(thtData: ThtDataEntity): ThtData {
    const products = Array.isArray(thtData.products)
        ? thtData.products.map(createProduct)
        : [];
    const accountManager = thtData.accountmanager && {
        fullName:
            thtData.accountmanager.fullName &&
            isString(thtData.accountmanager.fullName),
        image:
            thtData.accountmanager.image &&
            isImage(thtData.accountmanager.image),
        title:
            thtData.accountmanager.title &&
            isString(thtData.accountmanager.title),
        personalEmailAddress:
            thtData.accountmanager.personalEmailAddress &&
            isString(thtData.accountmanager.personalEmailAddress),
        mobilePhone:
            thtData.accountmanager.mobilePhone &&
            isString(thtData.accountmanager.mobilePhone),
    };

    const rawProductsToReturn = Array.isArray(thtData.productstoreturn)
        ? thtData.productstoreturn
        : [];

    // As not all products are guaranteed to be editable we can't use the array index.
    let index = 0;
    const productsToReturn = rawProductsToReturn
        .map((productToReturn) => {
            const formattedProduct = products.find(
                (product) => product.productId === productToReturn.productId,
            );
            if (!formattedProduct) {
                return null;
            }

            const bestBefore =
                thtData.bestbefore_values.find(
                    (bestBeforeValue) =>
                        bestBeforeValue.id === productToReturn.bestbefore_id,
                ) || thtData.bestbefore_values[0];

            const returnProduct = {
                ...formattedProduct,
                amount: productToReturn.amount,
                index,
                bestBefore,
                locationId: productToReturn.locationId,
            };

            index += 1;
            return returnProduct;
        })
        // We need the cast because TS doesn't understand the filter gets rid off nulls.
        .filter((thtProduct) => !!thtProduct) as ThtProduct[];

    const rawLocations = Array.isArray(thtData.locations)
        ? thtData.locations
        : [];
    const locations = rawLocations.map((location) => ({
        address: isString(location.address),
        city: isString(location.city),
        id: isString(location.id),
        name: isString(location.name),
        wholesaler: isString(location.wholesaler),
        editedWholesaler: isString(location.wholesaler),
    }));

    const uploadedFiles = Array.isArray(thtData.uploaded_files)
        ? thtData.uploaded_files
              .map((file) => ({
                  id: file.id,
                  name: decodeURIComponent(file.filename || ""),
              }))
              .filter((file) => Boolean(file.name))
        : [];

    return {
        accountManager,
        bestBeforeOptions: thtData.bestbefore_values,
        isEditable: thtData.editable,
        locations,
        products,
        productsToReturn,
        uploadedFiles,
    };
}
