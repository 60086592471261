/* eslint-disable camelcase */
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints } from "core/constants";
import { InvoiceOverview as InvoiceOverviewModel } from "core/models";
import { saveAs } from "file-saver";
import * as React from "react";
import { AccountContext } from "view/context/AccountContext";
import ConfigContext from "view/context/ConfigContext";
import useInvoiceService from "view/hooks/useInvoiceService";
import useTokenService from "view/hooks/useTokenService";
import { Spinner, ModuleWrapper } from "view/components";
import InvoiceRow from "./Invoice.Row";
import $ from "./InvoiceOverview.module.scss";

const InvoiceOverview: React.FC<InvoiceOverviewModel> = (
    props: InvoiceOverviewModel,
) => {
    const { title, colorBackground } = props;
    const { user, getAccessTokenSilently } = useAuth0();
    const configContext = React.useContext(ConfigContext);
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { selectedAccount } = React.useContext(AccountContext);

    const { fetchData, data, pendingDownload, downloadInvoice, invoice } =
        useInvoiceService(
            configContext,
            endpoints.invoices(selectedAccount?.accountnumber),
            token,
        );

    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";

    const { pending, invoices } = data;

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    React.useEffect(() => {
        if (!invoice || !invoice.blob) {
            return;
        }

        saveAs(invoice.blob, invoice.filename);
    }, [invoice]);

    return (
        <ModuleWrapper title={title} background={colorBackground}>
            <section className={$.wrapper}>
                {pending && <Spinner />}
                {!pending && invoices && invoices.length > 0 ? (
                    <>
                        {invoices.map((item) => (
                            <InvoiceRow
                                key={item.id}
                                dark={lightBackground}
                                invoice={item}
                                downloadInvoice={() => downloadInvoice(item)}
                                pending={pendingDownload}
                            />
                        ))}
                    </>
                ) : (
                    <p>Geen facturen beschikbaar.</p>
                )}
            </section>
        </ModuleWrapper>
    );
};

export default InvoiceOverview;
