import * as React from "react";
import $ from "./Spinner.module.scss";

type Props = {
    withContainer?: boolean;
};

const Spinner: React.FC<Props> = ({ withContainer }) => (
    <div data-testid="spinner" className={withContainer ? $.container : $.wrap}>
        <div className={$.spinner}>Loading...</div>
    </div>
);

export default Spinner;
