import { FormFields, Account, FormFieldType } from "core/models";

export function createGeneralEditFields(data?: Account): FormFields[] {
    return [
        {
            type: FormFieldType.Label,
            name: "bezoekAdresLabel",
            placeholder: "Bezoekadres",
        },
        {
            type: FormFieldType.Text,
            name: "address1line1",
            placeholder: "Adres",
            maxLength: 150,
            required: true,
            disabled: true,
        },
        {
            type: FormFieldType.Text,
            name: "address1line2",
            placeholder: "Huisnummer",
            maxLength: 150,
            required: true,
            disabled: true,
        },
        {
            type: FormFieldType.Postalcode,
            name: "address1postalcode",
            placeholder: "Postcode",
            maxLength: 7,
            required: true,
            disabled: true,
        },
        {
            type: FormFieldType.Text,
            name: "address1city",
            placeholder: "Plaats",
            maxLength: 100,
            required: true,
            disabled: true,
        },

        {
            type: FormFieldType.Radio,
            name: "hasDifferentPostAddress",
            required: true,
            placeholder: "Afwijkend postadres?",
            options: [
                {
                    label: "Ja",
                    value: true,
                },
                {
                    label: "Nee",
                    value: false,
                },
            ],
        },
        {
            type: FormFieldType.Label,
            name: "factuurAdresLabel",
            placeholder: "Postadres",
            visible: data?.hasDifferentPostAddress,
        },
        {
            type: FormFieldType.Radio,
            name: "postalboxaddress",
            placeholder: "Postbus?",
            required: data?.hasDifferentPostAddress,
            visible: data?.hasDifferentPostAddress,
            options: [
                {
                    label: "Ja",
                    value: true,
                },
                {
                    label: "Nee",
                    value: false,
                },
            ],
        },
        {
            type: FormFieldType.Text,
            name: "address2line1",
            placeholder: "Adres",
            maxLength: 100,
            required: data?.hasDifferentPostAddress && !data?.postalboxaddress,
            visible: data?.hasDifferentPostAddress && !data?.postalboxaddress,
        },
        {
            type: FormFieldType.Text,
            name: "address2line2",
            placeholder: "Huisnummer",
            maxLength: 100,
            required: data?.hasDifferentPostAddress && !data?.postalboxaddress,
            visible: data?.hasDifferentPostAddress && !data?.postalboxaddress,
        },
        {
            type: FormFieldType.Number,
            name: "postalBoxNumber",
            placeholder: "Postbusnummer",
            maxLength: 100,
            required: data?.hasDifferentPostAddress && data?.postalboxaddress,
            visible: data?.hasDifferentPostAddress && data?.postalboxaddress,
        },
        {
            type: FormFieldType.Postalcode,
            name: "address2postalcode",
            placeholder: "Postcode",
            maxLength: 7,
            required: data?.hasDifferentPostAddress,
            visible: data?.hasDifferentPostAddress,
        },
        {
            type: FormFieldType.Text,
            name: "address2city",
            placeholder: "Plaats",
            maxLength: 100,
            required: data?.hasDifferentPostAddress,
            visible: data?.hasDifferentPostAddress,
        },
        {
            type: FormFieldType.Label,
            name: "contactGegevensLabel",
            placeholder: "Contactgegevens",
        },
        {
            type: FormFieldType.Tel,
            name: "telephone",
            placeholder: "Telefoonnummer",
            required: true,
        },
        {
            type: FormFieldType.Email,
            name: "emailaddress1",
            placeholder: "Zakelijk e-mailadres",
            maxLength: 100,
            required: true,
        },
    ];
}
export function createGeneralViewModelFields(data?: Account): FormFields[] {
    return [
        {
            type: FormFieldType.Text,
            name: "address1",
            placeholder: "Bezoekadres",
        },
        {
            type: FormFieldType.Text,
            name: "address2",
            placeholder: "Postadres",
            visible: data?.hasDifferentPostAddress,
        },
        {
            type: FormFieldType.Text,
            name: "chamberOfCommerceNumber",
            placeholder: "Kamer van Koophandel",
            newline: true,
        },
        {
            type: FormFieldType.Tel,
            name: "telephone",
            placeholder: "Telefoonnummer",
            newline: true,
        },
        {
            type: FormFieldType.Email,
            name: "emailaddress1",
            placeholder: "Zakelijk e-mailadres",
            newline: !data?.telephone,
        },
    ];
}

export function createInvoiceEditFields(data?: Account): FormFields[] {
    return [
        {
            type: FormFieldType.Iban,
            name: "ibanNumber",
            placeholder: "Rekeningnummer (IBAN)",
            required: true,
        },
        {
            type: FormFieldType.Text,
            name: "alternativename",
            placeholder: "Tenaamstelling rekeningnummer",
            maxLength: 100,
            required: true,
        },
        {
            type: FormFieldType.Text,
            name: "vatNumber",
            placeholder: "BTW nummer",
            maxLength: 14,
        },
        {
            type: FormFieldType.Radio,
            name: "emailinvoice",
            placeholder: "Factuur ontvangen per",
            required: true,
            options: [
                {
                    label: "E-mail",
                    value: true,
                },
                {
                    label: "Post",
                    value: false,
                },
            ],
            visible: !data?.emailinvoiceoldvalue,
        },
        {
            type: FormFieldType.Label,
            name: "emailInvoiceLabel",
            placeholder: "Ontvangen op",
            visible: data?.emailinvoice,
        },
        {
            type: FormFieldType.Email,
            name: "emailaddress2",
            placeholder: "Factuur e-mailadres",
            maxLength: 100,
            required: data?.emailinvoice,
            visible: data?.emailinvoice,
        },
        {
            type: FormFieldType.Email,
            name: "emailinvoicecc",
            placeholder: "2e factuur e-mailadres (CC)",
            maxLength: 100,
            visible: data?.emailinvoice,
        },
        {
            type: FormFieldType.Text,
            name: "invoiceto",
            placeholder: "Ter attentie van",
            maxLength: 100,
            required: true,
            visible: !data?.emailinvoice,
        },
    ];
}

export function createInvoiceViewModelFields(data?: Account): FormFields[] {
    return [
        {
            type: FormFieldType.Iban,
            name: "ibanNumber",
            placeholder: "IBAN",
        },
        {
            type: FormFieldType.Text,
            name: "alternativename",
            placeholder: "T.n.v.",
        },
        {
            type: FormFieldType.Text,
            name: "vatNumber",
            placeholder: "BTW nummer",
        },
        {
            type: FormFieldType.Radio,
            name: "emailinvoice",
            placeholder: "Factuur per",
            newline: true,
            options: [
                {
                    label: "E-mail",
                    value: true,
                },
                {
                    label: "Post",
                    value: false,
                },
            ],
        },
        {
            type: FormFieldType.Email,
            name: "emailaddress2",
            placeholder: "Factuur e-mailadres",
            visible: data?.emailinvoice,
        },
        {
            type: FormFieldType.Email,
            name: "emailinvoicecc",
            placeholder: "2e e-mailadres (CC)",
            visible: data?.emailinvoice,
        },
        {
            type: FormFieldType.Text,
            name: "invoiceto",
            placeholder: "Ter attentie van",
            visible: !data?.emailinvoice,
        },
    ];
}
