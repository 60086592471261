import { useEffect } from "react";

/**
 * Usage:
 *   useEsc(()=>{ console.log('User pressed the ESC key') })
 */
export default function useEsc(callback: (e: KeyboardEvent) => void) {
    useEffect(() => {
        function listener(e: KeyboardEvent) {
            if (e.key === "Escape") {
                callback(e);
            }
        }
        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [callback]);
}
