import parseJSON from "date-fns/parseJSON";
import { isString, isNum } from "utils/checkType";
import { Notification as NotificationEntity } from "../entities";
import { Notification } from "../models";

export default function createNotifications(
    data: NotificationEntity,
): Notification {
    const action = data.action && isString(data.action);
    const activityDate =
        (data.activitydate &&
            parseJSON(data.activitydate) &&
            new Date(data.activitydate)) ||
        new Date();

    const activityType = data.activitytype && isString(data.activitytype);
    const clicked = data.clicked && isNum(data.clicked);
    const description = data.description && isString(data.description);
    const id = data.id && isString(data.id);

    const priority = data.priority && data.priority;
    const statusCode = data.statuscode && data.statuscode;
    const subject = data.subject && isString(data.subject);

    return {
        action,
        activityDate,
        activityType,
        clicked,
        description,
        id,
        priority,
        statusCode,
        subject,
    };
}
