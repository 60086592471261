import * as React from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import $ from "./Overlay.module.scss";

const Overlay = (props) => {
    const modalRoot = document.getElementsByTagName("body")[0];
    const htmlRoot = document.getElementsByTagName("html")[0];

    const id = "overlayDiv";
    let el = document.getElementById(id);
    if (!el) {
        el = document.createElement("div");
        el.id = id;
    }
    const [elementData, setElementData] = useState({
        element: el,
        mounted: false,
        overflow: htmlRoot.style.overflowY,
    });

    useEffect(() => {
        const { element, mounted, overflow } = elementData;
        function unMount() {
            if (mounted) {
                try {
                    modalRoot.removeChild(element);
                } catch (error) {
                    // Slick. Hot reload error.
                }

                htmlRoot.style.overflowY = overflow;
                setElementData({
                    element,
                    mounted: false,
                    overflow,
                });
            }
        }
        if (!mounted) {
            modalRoot.appendChild(element);
            htmlRoot.style.overflowY = "hidden";
            setElementData({
                element,
                mounted: true,
                overflow,
            });
        }
        return unMount;
    }, [el, elementData, htmlRoot.style, htmlRoot.style.overflowY, modalRoot]);

    const { children } = props;

    return ReactDOM.createPortal(
        <div className={$.overlay}>{children}</div>,
        el,
    );
};
export default Overlay;
