import { lazy } from "react";

import paths from "core/constants/paths";

const Page = lazy(
    () => import(/* webpackChunkName:'Page' */ "view/containers/Page/Page"),
);
const Article = lazy(
    () =>
        import(
            /* webpackChunkName:'Article' */ "view/containers/Article/Article"
        ),
);
const Archive = lazy(
    () =>
        import(
            /* webpackChunkName:'Archive' */ "view/containers/Archive/Archive"
        ),
);
const NotFound = lazy(
    () =>
        import(
            /* webpackChunkName:'NotFound' */ "./components/NotFound/NotFound"
        ),
);

const Consent = lazy(
    () =>
        import(
            /* webpackChunkName:'Consent' */ "view/containers/Consent/Consent"
        ),
);
const Tht = lazy(
    () => import(/* webpackChunkName:'Tht' */ "view/containers/Tht/Tht"),
);
const SalesContractOverview = lazy(
    () =>
        import(
            /* webpackChunkName:'SalesContractOverview' */ "view/containers/SalesContract/SalesContractOverview"
        ),
);
const PerfectFit = lazy(
    () =>
        import(
            /* webpackChunkName:'PerfectFit' */ "view/containers/PerfectFit/PerfectFit"
        ),
);
const BonusTool = lazy(
    () =>
        import(
            /* webpackChunkName:'BonusTool' */ "view/containers/BonusTool/BonusTool"
        ),
);
export interface RouteDefinition {
    path: string;
    exact: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentType<any>;
    private?: boolean;
    debug?: boolean;
    featureToggle?: boolean;
    fullscreen?: true;
}

const routes: RouteDefinition[] = [
    {
        path: paths.consent,
        component: Consent,
        exact: true,
        fullscreen: true,
    },
    {
        path: paths.perfectFit,
        component: PerfectFit,
        exact: true,
        fullscreen: true,
    },
    {
        path: paths.tht,
        component: Tht,
        exact: true,
        private: true,
    },
    {
        path: paths.salesContract,
        component: SalesContractOverview,
        exact: true,
        private: true,
    },
    {
        path: paths.article,
        component: Article,
        exact: true,
    },
    {
        path: paths.archive,
        component: Archive,
        exact: true,
    },
    {
        path: paths.category,
        component: Archive,
        exact: true,
    },
    {
        path: paths.bonusStep,
        component: BonusTool,
        exact: true,
        fullscreen: true,
    },
    {
        path: paths.callback,
        // eslint-disable-next-line react/jsx-no-useless-fragment
        component: () => <></>,
        exact: true,
        private: true,
    },
    {
        path: paths.home,
        component: Page,
        exact: true,
    },
    // The wildcard route
    {
        path: paths.subPage,
        component: Page,
        exact: true,
    },
    {
        path: paths.page,
        component: Page,
        exact: true,
    },
    {
        path: "",
        component: NotFound,
        exact: false,
    },
];

export default routes;
