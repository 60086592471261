import * as React from "react";
import classnames from "classnames";
import $ from "./Grid.module.scss";

export type Props = {
    id?: string;
    className?: string;
    dark?: boolean;
    end?: boolean;
    children: React.ReactNode;
};

const GridColumn: React.FC<Props> = (props: Props) => {
    const { children, className, id, dark, end } = props;

    return (
        <div
            className={classnames([
                $.column,
                className,
                dark ? $.columnDark : undefined,
                end ? $.columnEnd : undefined,
            ])}
            id={id}
            data-testid={id}
        >
            {children}
        </div>
    );
};

export default GridColumn;
