/* eslint-disable camelcase */
import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { paths } from "core/constants";
import { Account, AccountOverview as AccountOverviewModel } from "core/models";
import parseISO from "date-fns/parseISO";
import * as React from "react";
import ConfigContext from "view/context/ConfigContext";
import useAccountService from "view/hooks/useAccountService";
import useTokenService from "view/hooks/useTokenService";
import {
    Button,
    CardColumn,
    ModuleWrapper,
    PersonBlock,
    Spinner,
} from "view/components";
import { GridRow } from "view/components/Grid";
import $ from "./AccountOverview.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createProfile(data: Account | null) {
    const title = "Mijn onderneming";
    if (!data) {
        return { profileTitle: title, profileContent: null };
    }

    const {
        name,
        emailaddress1,
        telephone,
        chamberOfCommerceNumber,
        ibanNumber,
        vatNumber,
        address1city,
    } = data;

    const content = (
        <>
            {name && <strong className={$.title}>{name}</strong>}
            {address1city && (
                <span className={classnames([$.block, $.paddingBottom])}>
                    {address1city}
                </span>
            )}

            {emailaddress1 && <span className={$.block}>{emailaddress1}</span>}
            {telephone && <span className={$.block}>{telephone}</span>}

            <dl>
                {chamberOfCommerceNumber && (
                    <>
                        <dt className="newline">KvK</dt>
                        <dd className="newline">{chamberOfCommerceNumber}</dd>
                    </>
                )}
                {ibanNumber && (
                    <>
                        <dt>IBAN</dt>
                        <dd>{ibanNumber}</dd>
                    </>
                )}
                {vatNumber && (
                    <>
                        <dt>BTW nr</dt>
                        <dd>{vatNumber}</dd>
                    </>
                )}
            </dl>

            <Button
                className={$.button}
                bottom
                title="Wijzigen"
                color="turquiose"
                path={paths.account}
            />
        </>
    );

    return { profileTitle: title, profileContent: content };
}

function createAgreement(data: Account | null) {
    const title = "Overeenkomst";
    if (!data) {
        return { agreementTitle: title, agreementContent: null };
    }
    const formatDate = (date) => parseISO(date).toLocaleDateString("nl-NL");

    let salescontractnumber;
    let startdate;
    let enddate;

    const { salescontract, name } = data;

    if (salescontract) {
        ({ salescontractnumber, startdate, enddate } = salescontract);
        startdate = formatDate(startdate);
        enddate = formatDate(enddate);
    }

    const content = (
        <>
            {!salescontract && (
                <p>Geen samenwerkingsovereenkomst beschikbaar.</p>
            )}
            <dl>
                {salescontractnumber && (
                    <>
                        <dt>Referentie</dt>
                        <dd>{salescontractnumber}</dd>
                    </>
                )}

                {startdate && (
                    <>
                        <dt className="newline">Startdatum</dt>
                        <dd className="newline">{startdate}</dd>
                    </>
                )}
                {enddate && (
                    <>
                        <dt>Einddatum</dt>
                        <dd>{enddate}</dd>
                    </>
                )}

                {name && (
                    <>
                        <dt className="newline">Eigenaar</dt>
                        <dd className="newline">{name}</dd>
                    </>
                )}
            </dl>

            <Button
                className={$.button}
                bottom
                title="Samenwerkingsovereenkomst"
                color="turquiose"
                path={paths.salesContract}
                disabled={!salescontract}
            />
        </>
    );

    return { agreementTitle: title, agreementContent: content };
}

function createContact(data: Account | null) {
    const title = "Contactpersonen";
    if (!data) {
        return { contactTitle: title, agreementContent: null };
    }
    const { accountmanager, salesSupport } = data;

    const content = (
        <>
            <PersonBlock person={accountmanager} />
            <PersonBlock person={salesSupport} />
        </>
    );

    return { contactTitle: title, contactContent: content };
}

const AccountOverview: React.FC<AccountOverviewModel> = (
    props: AccountOverviewModel,
) => {
    const { title, colorBackground } = props;
    const { user, getAccessTokenSilently } = useAuth0();
    const configContext = React.useContext(ConfigContext);
    const { token } = useTokenService(getAccessTokenSilently, user);
    const { pending, fetchData, data } = useAccountService(
        configContext,
        token,
    );

    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { profileTitle, profileContent } = createProfile(data);
    const { agreementTitle, agreementContent } = createAgreement(data);
    const { contactTitle, contactContent } = createContact(data);

    return (
        <ModuleWrapper title={title} background={colorBackground} noMargin>
            <section className={$.wrapper}>
                {pending && <Spinner />}
                {!pending && (
                    <GridRow>
                        <>
                            <CardColumn
                                title={profileTitle}
                                dark={lightBackground}
                                stretch
                                id="cardColumnProfile"
                            >
                                {profileContent}
                            </CardColumn>
                            <CardColumn
                                title={agreementTitle}
                                dark={lightBackground}
                                stretch
                                id="cardColumnAgreement"
                            >
                                {agreementContent}
                            </CardColumn>
                            <CardColumn
                                title={contactTitle}
                                dark={lightBackground}
                                stretch
                                id="cardColumnContact"
                            >
                                {contactContent}
                            </CardColumn>
                        </>
                    </GridRow>
                )}
            </section>
        </ModuleWrapper>
    );
};

export default AccountOverview;
