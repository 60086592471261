import * as React from "react";
import classnames from "classnames";
import $ from "./Card.module.scss";

type Props = {
    children: React.ReactNode[] | React.ReactNode;
    className?: React.CSSProperties | string | undefined;
    dark?: boolean;
    padding?: boolean;
};

const Card: React.FC<Props> = (props: Props) => {
    const { dark, children, className, padding } = props;
    return (
        <div
            className={classnames(
                $.card,
                dark && $.cardDark,
                padding && $.cardPadding,
                className,
            )}
        >
            {children}
        </div>
    );
};

export default Card;
