import { isString } from "utils/checkType";
import { BonusTool } from "../models";
import { ModuleBonusTool } from "../entities";

export default function createBonusTool(data: ModuleBonusTool): BonusTool {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);

    return {
        id,
        contentType,
        moduleType,
        title,
    };
}
