import * as React from "react";
import useScript from "view/hooks/useScript";

type Props = {
    className?: string;
    firstName: string;
    id: string;
    lastName: string;
    locale: string;
    surveyId: string;
};

const Survey: React.FC<Props> = ({
    className,
    firstName,
    id,
    lastName,
    locale,
    surveyId,
}) => {
    const [loaded, error] = useScript(
        "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js",
    );

    React.useEffect(() => {
        if (loaded) {
            const se = new window.SurveyEmbed(
                surveyId,
                "https://forms.office.com/",
                "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/",
                "true",
            );
            const context = {
                "First Name": firstName,
                "Last Name": lastName,
                locale,
            };
            se.renderInline(id, context);
        }
    }, [firstName, id, lastName, loaded, locale, surveyId]);

    if (error) {
        return null;
    }

    return (
        <>
            <style type="text/css">
                {`@import url('https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.css');`}
            </style>
            <div className={className} id={id} />
        </>
    );
};

export default Survey;
