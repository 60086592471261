import { useState, useEffect, useContext } from "react";
import classnames from "classnames";
import { isValidIBAN } from "ibantools";
import { FormFields, FormFieldType } from "core/models";

import BackgroundColorContext from "view/context/BackgroundColorContext";

import $ from "./FormFieldset.module.scss";
import $formSelect from "../FormSelect/FormSelect.module.scss";

interface Validator {
    element: string;
    validator: (input: string) => string;
}

interface FormFieldsetProps {
    title?: string;
    fields: FormFields[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChange: (e: boolean | string, name: string, files?: any) => void;
}

const FormFieldset = (props: FormFieldsetProps) => {
    const { title, formData, fields, handleChange: handleChangeProp } = props;
    const [selectedFiles, setSelectedFiles] = useState<number>(0);
    const colorBackground = useContext(BackgroundColorContext);

    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        setIsDark(colorBackground?.backgroundColor === "Grey");
    }, [colorBackground]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const customValidators: Validator[] = [];

    const validateCustomValidators = (
        validatorList: Validator[],
        value?: string,
    ) => {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < validatorList.length; index++) {
            const validator = validatorList[index];

            const element = document.getElementById(
                validator.element,
            ) as HTMLInputElement;
            if (element) {
                const val = value === undefined ? element.value : value;
                const msg = validator.validator(val);
                element.setCustomValidity(msg);
                if (msg) {
                    return false;
                }
            }
        }
        return true;
    };

    const handleChange = (e, name, files?) => {
        handleChangeProp(e, name, files);
        validateCustomValidators(customValidators, e);
    };

    useEffect(() => {
        validateCustomValidators(customValidators);
    }, [customValidators]);

    const mappedComponents =
        Array.isArray(fields) &&
        fields.map((field: FormFields) => {
            const {
                type,
                options,
                name,
                required,
                placeholder,
                minLength,
                maxLength,
                visible,
                autocomplete,
                disabled,
                customValidation,
            } = field;
            const isVisible = visible === undefined ? true : visible;

            if (!isVisible) {
                return null;
            }

            const id =
                type === FormFieldType.Radio && options && options[0]
                    ? `${name}_${options[0].value}`
                    : name;
            const validationFunc =
                type === FormFieldType.Iban
                    ? (input: string) => {
                          if (input.includes(" ")) {
                              return "IBAN mag geen spaties bevatten";
                          }
                          if (!isValidIBAN(input.toUpperCase())) {
                              return "Het opgegeven nummer is geen geldig IBAN";
                          }
                          if (customValidation) {
                              return customValidation(input);
                          }
                          return "";
                      }
                    : customValidation;
            if (validationFunc) {
                customValidators.push({
                    element: id,
                    validator: validationFunc,
                });
            }

            if (type === FormFieldType.Select && options && isVisible) {
                return (
                    <>
                        {placeholder && (
                            <label htmlFor={name} className={$formSelect.title}>
                                {placeholder}
                            </label>
                        )}
                        <div className={$formSelect.selectWrap} key={name}>
                            <select
                                name={name}
                                id={name}
                                data-testid={name}
                                required={required}
                                aria-label={placeholder}
                                className={classnames([
                                    $formSelect.input,
                                    $formSelect.select,
                                    isDark && $.dark,
                                ])}
                                value={formData[name] || placeholder}
                                onChange={(e) =>
                                    handleChange(e.target.value, name)
                                }
                            >
                                {options.map((option) => (
                                    <option
                                        key={`${name}_${option.value}`}
                                        value={option.value}
                                        className={$.option}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                );
            }

            if (type === FormFieldType.Textarea && isVisible) {
                return (
                    <div key={name} className={$.inputWrap}>
                        <textarea
                            name={name}
                            id={name}
                            data-testid={name}
                            aria-label={placeholder}
                            placeholder={`${placeholder}${required ? "*" : ""}`}
                            required={required}
                            rows={8}
                            className={classnames([
                                $.input,
                                $.textArea,
                                isDark && $.dark,
                            ])}
                            value={formData[name]}
                            onChange={(e) => handleChange(e.target.value, name)}
                        />
                        <label htmlFor={name} className={$.floatingLabel}>
                            {placeholder}
                            {required ? "*" : ""}
                        </label>
                    </div>
                );
            }

            if (type === FormFieldType.Radio && options && isVisible) {
                return (
                    <div key={name} className={$.radioWrap}>
                        <p className={$.radioTitle}>
                            {placeholder}
                            {required && "*"}
                        </p>
                        <div>
                            {options.map((option) => (
                                <label
                                    htmlFor={`${name}_${option.value}`}
                                    key={option.value}
                                    className={$.radioLabelWrap}
                                >
                                    <input
                                        type={type}
                                        name={name}
                                        value={`${option.value}`}
                                        required={required}
                                        data-testid={`${name}_${option.value}`}
                                        id={`${name}_${option.value}`}
                                        onChange={() =>
                                            handleChange(option.value, name)
                                        }
                                        checked={
                                            `${formData[name]}` ===
                                            `${option.value}`
                                        }
                                        className={$.radioBox}
                                        disabled={disabled}
                                    />
                                    <span className={$.radioCustomBox} />
                                    <span className={$.radioLabel}>
                                        {option.label}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div>
                );
            }

            if (type === FormFieldType.Checkbox && isVisible) {
                return (
                    <div key={name} className={$.inputWrap}>
                        <label htmlFor={name} className={$.checkbox}>
                            <input
                                type="checkbox"
                                value={formData[name]}
                                id={name}
                                name={name}
                                required={required}
                                className={$.checkboxInput}
                                onChange={(e) =>
                                    handleChange(e.target.checked, name)
                                }
                                disabled={disabled}
                            />
                            <span className={$.checkboxCheckbox} />
                            {required && "*"}
                            <span className={$.checkboxLabel}>
                                {placeholder}
                            </span>
                        </label>
                    </div>
                );
            }

            if (type === FormFieldType.Date && isVisible) {
                return (
                    <div key={name} className={$.inputWrap}>
                        <input
                            type={type}
                            id={name}
                            data-testid={name}
                            aria-label={placeholder}
                            placeholder="dd-mm-jjjj*"
                            required={required}
                            pattern="\d{1,2}\-\d{1,2}\-\d{4}"
                            className={classnames([$.input, $.textInput])}
                            value={formData[name]}
                            onChange={(e) => handleChange(e.target.value, name)}
                            disabled={disabled}
                        />
                        <label htmlFor={name} className={$.floatingLabel}>
                            {placeholder}
                            {required ? "*" : ""}
                        </label>
                    </div>
                );
            }

            if (type === FormFieldType.File) {
                const labelStyle = classnames([$.fileLabel]);
                return (
                    <>
                        <input
                            id={name}
                            key={name}
                            type={type}
                            accept="image/*"
                            aria-label={placeholder}
                            placeholder={`${placeholder}${required ? "*" : ""}`}
                            required={required}
                            className={classnames([$.input, $.file])}
                            onChange={(e) => {
                                setSelectedFiles(
                                    e.target.files ? e.target.files.length : 0,
                                );
                                handleChange(e.target, name, e.target.files);
                            }}
                            multiple
                            disabled={disabled}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={name} className={labelStyle}>
                            Bijlage(s) toevoegen
                        </label>
                        {selectedFiles > 0 && (
                            <p className={$.fileCount}>
                                {selectedFiles} bestand
                                {selectedFiles > 1 ? "en" : ""} toegevoegd
                            </p>
                        )}
                    </>
                );
            }

            if (type === FormFieldType.Label && isVisible) {
                return (
                    <h4 key={name} className={$.subtitle}>
                        {placeholder}
                    </h4>
                );
            }

            if (type === FormFieldType.Info && isVisible) {
                return (
                    <div className={$.infoWrapper}>
                        <img
                            aria-label="success"
                            alt="success"
                            src="/static/images/ic-checkmark.svg"
                        />
                        <p className={$.infoText}>{placeholder}</p>
                    </div>
                );
            }

            if (type === FormFieldType.Hidden && isVisible) {
                return (
                    <input
                        id={name}
                        key={name}
                        name={name}
                        type={type}
                        disabled={disabled}
                        value={placeholder}
                    />
                );
            }

            if (type === FormFieldType.Paragraph && isVisible) {
                return (
                    <div key={name} data-testid={name}>
                        <p>{placeholder}</p>
                    </div>
                );
            }

            let maxLength2 = maxLength;
            let regEx;
            switch (type) {
                case FormFieldType.Tel:
                    maxLength2 = 11;
                    regEx = `0(?:(?:[\\d]{9})|(?:6[\\s-]{1}[\\d]{8})|(?:[\\d]{2}[\\s-]{1}[\\d]{7})|(?:[\\d]{3}[\\s-]{1}[\\d]{6}))`;
                    break;
                case FormFieldType.Mobile:
                    maxLength2 = 10;
                    regEx = `06(?:(?:[\\d]{8}))`;
                    break;
                case FormFieldType.Iban:
                    maxLength2 = 34;
                    regEx = `^[a-zA-Z0-9]{6,30}$`;
                    break;
                case FormFieldType.Postalcode:
                    maxLength2 = 7;
                    regEx = `^[1-9][0-9]{3}\\s*(?:[a-zA-Z]{2})?$`;
                    break;
                case FormFieldType.Email:
                    regEx = "[^@\\s]+@[^@\\s]+\\.[^@\\s]+";
                    break;
                default:
                    break;
            }

            return (
                <div key={name} className={$.inputWrap}>
                    <input
                        name={name}
                        type={type}
                        id={name}
                        data-testid={name}
                        aria-label={placeholder}
                        placeholder={`${placeholder}${required ? "*" : ""}`}
                        required={required}
                        className={classnames([
                            $.input,
                            $.textInput,
                            isDark && $.dark,
                        ])}
                        value={formData[name]}
                        pattern={regEx}
                        minLength={
                            type === FormFieldType.Tel ||
                            type === FormFieldType.Mobile
                                ? 10
                                : minLength
                        }
                        maxLength={maxLength2}
                        onChange={(e) => handleChange(e.target.value, name)}
                        autoComplete={autocomplete}
                        disabled={disabled}
                    />
                    <label htmlFor={name} className={$.floatingLabel}>
                        {placeholder}
                        {required ? "*" : ""}
                    </label>
                </div>
            );
        });

    return (
        <fieldset className={$.base}>
            {title && <h3 className={$.titleDisplay}>{title}</h3>}
            {mappedComponents}
        </fieldset>
    );
};

export default FormFieldset;
