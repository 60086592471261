import { isString, isButton } from "utils/checkType";
import { ModuleSupport } from "../entities";
import { Support } from "../models";

export default function createSupport(data: ModuleSupport): Support {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const text1 = data.text1 && isString(data.text1);
    const text1Cta = data.text1Cta && isButton(data.text1Cta);
    const text2 = data.text2 && isString(data.text2);
    const text2Cta = data.text2Cta && isButton(data.text2Cta);
    const text3 = data.text3 && isString(data.text3);
    const text3Cta = data.text3Cta && isButton(data.text3Cta);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);

    return {
        id,
        contentType,
        moduleType,
        title,
        text1,
        text1Cta,
        text2,
        text2Cta,
        text3,
        text3Cta,
        colorBackground,
    };
}
