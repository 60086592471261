import { MOBILE } from "core/constants";
import { Usp as UspModel } from "core/models";
import * as React from "react";
import { useWindowWidth } from "utils/useWindowWidth";
import { Container } from "view/components";
import SettingsContext from "view/context/SettingsContext";
import $ from "./UspBar.module.scss";

const Usp: React.FC<UspModel> = (props) => {
    const { id, title, image } = props;

    return (
        <li key={id} className={$.listItem}>
            {image && image.url && (
                <span
                    className={$.icon}
                    style={{
                        backgroundImage: `url(${image.url})`,
                    }}
                />
            )}
            <span className={$.title}>{title}</span>
        </li>
    );
};

const UspBar: React.FC = () => {
    const settingsContext = React.useContext(SettingsContext);
    const uspItems = settingsContext?.uspBar;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE;
    const initialIndex = 0;
    const [currentIndex, setCurrentIndex] = React.useState(initialIndex);
    const interval = 2000;

    React.useEffect(() => {
        const rotateInterval = isMobile
            ? window.setInterval(() => {
                  if (uspItems && currentIndex < uspItems.length - 1) {
                      setCurrentIndex(currentIndex + 1);
                  } else {
                      setCurrentIndex(initialIndex);
                  }
              }, interval)
            : 0;

        return () => clearInterval(rotateInterval);
    }, [isMobile, uspItems, currentIndex]);

    return (
        <section className={$.container}>
            <Container>
                <ul className={$.list}>
                    {uspItems &&
                        uspItems.map((usp, index) => {
                            if (isMobile) {
                                if (index === currentIndex) {
                                    return Usp(usp);
                                }
                                return null;
                            }
                            return Usp(usp);
                        })}
                </ul>
            </Container>
        </section>
    );
};

export default UspBar;
