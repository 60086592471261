import * as React from "react";
import classnames from "classnames";
import $ from "./Body.module.scss";

type Props = {
    content: string;
    className?: string | undefined;
};

const Body: React.FC<Props> = ({ content, className }: Props) => (
    <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
        data-testid="text"
        className={classnames($.container, className)}
    />
);

export default Body;
