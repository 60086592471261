const paths = {
    account: "/mijn-account/account",
    archive: "/nieuws",
    article: "/nieuws/:category/:slug",
    bonus: "/bonus",
    bonusStep: "/bonus/:step",
    bonusOverview: "/mijn-account/bonus-overzicht",
    callback: "/callback",
    category: "/nieuws/:category",
    consent: "/consent",
    becomeCustomer: "/klant-worden",
    dashboard: "/mijn-account/dashboard",
    error: "500.html",
    home: "/",
    invoice: "/mijn-account/facturen",
    login: "/inloggen",
    logout: "/uitloggen",
    myvrumona: "/mijn-account",
    page: "/:slug",
    perfectFit: "/perfect-fit",
    salesContract: "/mijn-account/swo",
    subPage: "/:parent/:slug",
    tht: "/tht",
    thtFaq: "/vragen-tht",
    users: "/mijn-account/users",
};

export default paths;
