// Based on https://github.com/scriptex/react-accordion-ts
import { useState, useEffect } from "react";
import { AccordionItem } from "./AccordionItem";
import Panel from "./Panel";
import $ from "./Accordion.module.scss";

interface AccordionProps {
    items: Array<AccordionItem>;
}

const Accordion = (props: AccordionProps): JSX.Element => {
    const { items } = props;

    const [activeTab, setActiveTab] = useState(0);

    const activatePanel = (index: number) => {
        setActiveTab(activeTab === index ? -1 : index);
    };

    useEffect(() => {
        setActiveTab(activeTab);
    }, [activeTab]);

    return (
        <>
            {items.map(({ title, content, key }, index) => (
                <div className={$.accordion} role="tablist" key={key}>
                    <Panel
                        key={`panel${key}`}
                        title={title}
                        index={index}
                        activeTab={activeTab}
                        activatePanel={activatePanel}
                    >
                        {content}
                    </Panel>
                </div>
            ))}
        </>
    );
};

export default Accordion;
