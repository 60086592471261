import Analytics from "core/services/analytics";
import * as React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";

function createTitle(title: string, subTitle?: string) {
    let result = title;
    if (subTitle) {
        result = `${subTitle} - ${title}`;
    }
    return result;
}

interface HeadProps {
    subTitle?: string;
    seoDescription?: string;
    seoKeywords?: string;
    seoImage?: string;
    type?: string;
    pageNotFound?: boolean;
    noIndex?: boolean;
}

type Props = HeadProps & RouteComponentProps;

const Head: React.FC<Props> = (props) => {
    const settingsContext = React.useContext(SettingsContext);

    const {
        subTitle,
        seoDescription,
        seoKeywords,
        seoImage,
        type,
        pageNotFound,
        history,
        noIndex,
    } = props;

    React.useEffect(() => {
        Analytics.pageView(history.location.pathname, subTitle);
    }, [history, subTitle]);

    if (!settingsContext) {
        return null;
    }

    const {
        configuration: {
            seoKeywords: seoKeywordsContext,
            title,
            seoDescription: seoDescriptionContext,
            seoImage: seoImageContext,
        },
    } = settingsContext;

    const description = seoDescription || seoDescriptionContext;
    const keywords = seoKeywords || seoKeywordsContext;
    const url = window.location.href;
    const ogType = type || "website";
    const image = seoImage || (seoImageContext && seoImageContext.url);

    return (
        <Helmet>
            {title && <title>{createTitle(title, subTitle)}</title>}
            {title && (
                <meta
                    property="og:title"
                    content={createTitle(title, subTitle)}
                />
            )}
            {description && <meta name="description" content={description} />}
            {description && (
                <meta property="og:description" content={description} />
            )}
            {keywords && <meta name="keywords" content={keywords} />}
            {url && <meta property="og:url" content={url} />}
            {ogType && <meta property="og:type" content={ogType} />}
            {image && <meta property="og:image" content={image} />}
            {(pageNotFound || noIndex) && (
                <meta name="robots" content="NOINDEX, NOFOLLOW" />
            )}
            {pageNotFound && (
                <meta name="prerender-status-code" content="404" />
            )}
            {url && <link rel="canonical" href={url} />}
        </Helmet>
    );
};

export default withRouter(Head);
