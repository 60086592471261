import * as React from "react";
import { Account } from "core/models/Account";

export type AccountContextType = {
    selectedAccount: Account | undefined;
    setSelectedAccount: (account: Account) => void;
    clearSelectedAccount: () => void;
};

const defaultSelectedAccount = localStorage.getItem("selectedAccount")
    ? JSON.parse(localStorage.getItem("selectedAccount") as string)
    : undefined;

export const AccountContext = React.createContext<AccountContextType>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    undefined!,
);

const AccountContextProvider: React.FC = ({ children }) => {
    const [selectedAccount, setSelectedAccount] = React.useState<
        Account | undefined
    >(defaultSelectedAccount);

    const clearSelectedAccount = () => {
        localStorage.removeItem("selectedAccount");
    };

    React.useEffect(() => {
        if (selectedAccount)
            localStorage.setItem(
                "selectedAccount",
                JSON.stringify(selectedAccount),
            );
    }, [selectedAccount]);

    const accountMemo = React.useMemo(
        () => ({ selectedAccount, setSelectedAccount, clearSelectedAccount }),
        [selectedAccount],
    );

    return (
        <AccountContext.Provider value={accountMemo}>
            {children}
        </AccountContext.Provider>
    );
};

export default AccountContextProvider;
