/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from "axios";
import { ConfigContextType } from "view/context/ConfigContext";
import { ApiBehavior, axiosRequest } from "./axiosRequest";

interface CustomHeaders {
    "Cache-Control"?: string;
}

interface FetchUrlParams {
    config: ConfigContextType;
    endpoint: string;
    method?: "GET" | "POST" | "PUT" | "DELETE";
    body?: any;
    token?: string;
    params?: any;
    customHeaders?: CustomHeaders;
    onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
}

interface Headers {
    "Ocp-Apim-Subscription-Key": string;
    "Content-Type"?: string;
    Authorization?: string;
}

export default async function fetchUrl<Entity>(
    {
        config,
        endpoint,
        body,
        token,
        params,
        customHeaders,
        onUploadProgress,
        method = "GET",
    }: FetchUrlParams,
    behavior: ApiBehavior = {},
): Promise<Entity> {
    let headers: Headers & CustomHeaders = {
        "Ocp-Apim-Subscription-Key": config.apiKey,
    };
    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }
    if (customHeaders) {
        headers = {
            ...headers,
            ...customHeaders,
        };
    }
    let data: undefined | FormData | string;
    if (body instanceof FormData) {
        data = body;
    } else if (body) {
        data = JSON.stringify(body);
        headers["Content-Type"] = "application/json";
    }
    const options = {
        method,
        headers,
        params,
        data,
        responseType: params?.responseType ? params?.responseType : undefined,
        onUploadProgress,
    };
    const url = `${config.apiUrl}${endpoint}`;
    return axiosRequest(url, options, behavior);
}
