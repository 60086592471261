import { isString, isImage, isButton } from "utils/checkType";
import { ModuleUspList } from "../entities";
import { UspList } from "../models";

export default function createUspList(data: ModuleUspList): UspList {
    const {
        moduleType,
        image1,
        image2,
        image3,
        text1,
        text2,
        text3,
        title1,
        title2,
        title3,
    } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const blocks = [
        {
            image: isImage(image1),
            text: isString(text1),
            title: isString(title1),
            cta:
                data.navigationItem1Reference &&
                isButton(data.navigationItem1Reference),
        },
        {
            image: isImage(image2),
            text: isString(text2),
            title: isString(title2),
            cta:
                data.navigationItem2Reference &&
                isButton(data.navigationItem2Reference),
        },
        {
            image: isImage(image3),
            text: isString(text3),
            title: isString(title3),
            cta:
                data.navigationItem3Reference &&
                isButton(data.navigationItem3Reference),
        },
    ];

    return {
        blocks,
        contentType,
        id,
        moduleType,
        title,
    };
}
