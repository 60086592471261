import { useAuth0 } from "@auth0/auth0-react";
import { Button as ButtonModel, MenuItem } from "core/models";

const MenuItemIsVisible = (props: ButtonModel | MenuItem) => {
    const { isAuthenticated } = useAuth0();

    return (
        !props.visibleTo ||
        props.visibleTo.length === 0 ||
        (props.visibleTo.includes("AnonymousUser") && !isAuthenticated) ||
        (props.visibleTo.includes("AuthenticatedUser") && isAuthenticated)
    );
};

export default MenuItemIsVisible;
