import * as React from "react";
import classnames from "classnames";
import { Invoice } from "core/models";
import Button from "view/components/Button";
import { GridColumn, GridRow } from "view/components/Grid";
import $ from "./Invoice.Row.module.scss";

type Props = {
    invoice: Invoice;
    dark: boolean;
    downloadInvoice: (invoice: Invoice) => void;
    pending: boolean;
};

const InvoiceRow: React.FC<Props> = ({
    invoice,
    dark,
    downloadInvoice,
    pending,
}) => (
    <GridRow
        className={classnames([$.invoiceRow, dark && $.invoiceRowDark])}
        key={invoice.id}
    >
        <span className={$.iconContainer}>
            <img src="/static/images/ic-invoice.svg" alt="Factuur" />
        </span>

        <GridColumn>
            <div>
                Factuurnummer: <strong>{invoice.number}</strong>
            </div>
            {invoice.date && (
                <small className={$.date}>
                    {invoice.date.toLocaleDateString("nl-NL")}
                </small>
            )}
        </GridColumn>

        <GridColumn>
            <span
                className={classnames([
                    $.text,
                    invoice.debitcredit.toLowerCase() === "credit" &&
                        $.textDebit,
                ])}
            >
                <strong>{invoice.totalAmountInclVat}</strong>
            </span>{" "}
            <small>incl. BTW</small>
            {invoice.debitcredit && (
                <small className={$.date}>
                    {invoice.debitcredit.toUpperCase()}
                </small>
            )}
        </GridColumn>

        <Button
            title="Downloaden"
            color="green"
            end
            onClick={() => downloadInvoice(invoice)}
            disabled={pending}
        />
    </GridRow>
);

export default InvoiceRow;
