import * as React from "react";
import classnames from "classnames";
import { ArrowDown, Link } from "view/components";
import { MenuItem } from "core/models/MenuItem";
import $ from "./SubHeader.module.scss";

interface Props {
    items: MenuItem[];
}

export default function SubHeader(props: Props) {
    const { items } = props;
    const [menuOpen, setMenuOpen] = React.useState(false);

    const createNavItem = (item: MenuItem, isSelected?: boolean) => (
        <li
            key={item.id}
            className={classnames([
                $.listItem,
                isSelected && $.listItemSelected,
            ])}
        >
            {!isSelected && (
                <Link
                    to={item.path || item.externalUrl}
                    className={$.link}
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    {item.title}
                </Link>
            )}
            {isSelected && (
                <button
                    type="button"
                    className={classnames([$.link, $.linkActive])}
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    {item.title}
                    <ArrowDown
                        active={menuOpen}
                        baseColor="dark-blue"
                        activeColor="turquoise"
                        className={$.arrow}
                    />
                </button>
            )}
        </li>
    );

    return (
        <section className={$.wrap}>
            <ul className={classnames([$.list, menuOpen && $.listOpen])}>
                {items.map((item) => {
                    if (
                        item.path === window.location.pathname ||
                        item.externalUrl === window.location.pathname
                    ) {
                        return createNavItem(item, true);
                    }
                    return createNavItem(item);
                })}
            </ul>
        </section>
    );
}
