import * as React from "react";
import { useState, useRef, useCallback, useEffect } from "react";
import classnames from "classnames";
import { XLARGE } from "core/constants";
import {
    Brand,
    BrandsOverview as BrandsOverviewModel,
    Button as ButtonModel,
} from "core/models";
import { useWindowWidth } from "utils/useWindowWidth";
import { Card, Button, Image, ModuleWrapper } from "view/components";
import $ from "./BrandsOverview.module.scss";

type Props = {
    id: Brand["id"];
    overview: Brand["overview"];
    index: number;
};

const BrandCard: React.FC<Props> = ({ overview, id, index }) => {
    const { image, text: brandText, title: brandTitle, cta: link } = overview;
    // load a larger image for grid items with span of two rows
    const imgWidth = index === 2 || index === 6 ? 200 : 100;
    return (
        <article key={id} className={$.cardContainer}>
            <Card padding className={$.contentContainer}>
                <div className={$.textContainer}>
                    {brandTitle && (
                        <h3 className={$.brandTitle}>{brandTitle}</h3>
                    )}
                    {brandText && <p className={$.brandText}>{brandText}</p>}
                    {link && (
                        <Button
                            color="turquiose"
                            title={link.title}
                            path={link.slug || link.path || link.externalUrl}
                            className={$.brandButton}
                        />
                    )}
                </div>

                {image && (
                    <div className={$.imageContainer}>
                        <Image
                            placeholder={`${image.url}?w=30&q=50`}
                            src={`${image.url}?w=${imgWidth}&q=75`}
                            className={$.image}
                            alt={image.title}
                        />
                    </div>
                )}
            </Card>
        </article>
    );
};

type PropsBrandIntroduction = {
    text: string;
    ctaPrimary: ButtonModel;
};

const BrandIntroduction: React.FC<PropsBrandIntroduction> = ({
    text,
    ctaPrimary,
}) => (
    <article className={$.cardContainer}>
        <Card className={$.card} padding>
            {text && <h2 className={$.textTitle}>{text}</h2>}
            {ctaPrimary && (
                <Button
                    color="turquiose"
                    title={ctaPrimary.title}
                    path={
                        ctaPrimary.slug ||
                        ctaPrimary.path ||
                        ctaPrimary.externalUrl
                    }
                    className={$.cardButton}
                />
            )}
            <span className={$.backgroundBubble} />
        </Card>
    </article>
);

const BrandsOverview = (props: BrandsOverviewModel) => {
    const { title, text, ctaPrimary, brands } = props;
    const [brandLists, setBrandLists] = useState<Brand[][]>([]);
    const [containerHeight, setContainerHeight] = useState("auto");
    const [containerWidth, setContainerWidth] = useState("100%");
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const listRefs = useRef<HTMLDivElement[]>([]);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const windowWidth = useWindowWidth();
    const isXlarge = windowWidth >= XLARGE;
    const desktopContainerWidth = 1060;

    const listClass = (amount, index: number) => {
        const isFirstSlide = index === 0;
        const amountIsFourOrFive = amount === 4 || amount === 5;
        const amountIsFiveOrSix = amount === 5 || amount === 6;
        const fullGrid =
            (isFirstSlide && amount > 5) || (!isFirstSlide && amount > 6);
        const noFullGrid =
            (isFirstSlide && amountIsFourOrFive) ||
            (!isFirstSlide && amountIsFiveOrSix);

        return classnames(
            $.list,
            noFullGrid ? $.listGrid3Col : undefined,
            fullGrid ? $.listGrid : undefined,
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addToRefs = (el: any) => {
        if (el && !listRefs.current.includes(el)) {
            listRefs.current.push(el);
        }
    };

    const slideToPrev = () => {
        setCurrentSlideIndex(currentSlideIndex - 1);
    };

    const slideToNext = () => {
        setCurrentSlideIndex(currentSlideIndex + 1);
    };

    const getTransformValue = useCallback(() => {
        const slideValue = isXlarge
            ? currentSlideIndex * desktopContainerWidth
            : 0;
        return `translateX(-${slideValue}px)`;
    }, [currentSlideIndex, isXlarge]);

    useEffect(() => {
        if (brands && brands.length > 0) {
            const firstArraySize = isXlarge ? 6 : brands.length;
            const arraySize = 7;
            const brandListsArray: Brand[][] = [];
            // push first six items to array
            brandListsArray.push(brands.slice(0, firstArraySize));
            // push other items to array in groups of ${size}
            for (
                let index = firstArraySize;
                index < brands.length;
                index += arraySize
            ) {
                brandListsArray.push(brands.slice(index, index + arraySize));
            }

            setBrandLists(brandListsArray);
        }
    }, [brands, isXlarge]);

    useEffect(() => {
        if (isXlarge) {
            const width = brandLists.length * desktopContainerWidth;
            setContainerWidth(`${width}px`);
        } else {
            setContainerWidth("100%");
        }
    }, [brandLists, isXlarge]);

    useEffect(() => {
        if (isXlarge) {
            let height = 0;
            listRefs.current.forEach((element: HTMLDivElement) => {
                if (element.offsetHeight > height) {
                    height = element.offsetHeight;
                }
            });
            setContainerHeight(`${height}px`);
        } else {
            setContainerHeight("auto");
        }
    }, [containerWidth, isXlarge]);

    return (
        <ModuleWrapper title={title} background="grey">
            <div className={$.container}>
                <div
                    ref={listContainerRef}
                    className={$.slider}
                    style={{
                        height: containerHeight,
                        width: containerWidth,
                        transform: getTransformValue(),
                    }}
                >
                    {brandLists.map((brandList: Brand[], index: number) => (
                        <div
                            className={listClass(brandList.length, index)}
                            ref={addToRefs}
                            key={brandList[0].id}
                        >
                            {index === 0 &&
                                BrandIntroduction({ text, ctaPrimary })}

                            {brandList.map((brand: Brand, cardIndex: number) =>
                                BrandCard({ index: cardIndex, ...brand }),
                            )}
                        </div>
                    ))}
                </div>
                {currentSlideIndex > 0 && (
                    <button
                        className={classnames($.arrowButton, $.arrowButtonPrev)}
                        type="button"
                        onClick={slideToPrev}
                        aria-label="Vorige brands"
                    />
                )}
                {currentSlideIndex < brandLists.length - 1 && (
                    <button
                        className={$.arrowButton}
                        type="button"
                        onClick={slideToNext}
                        aria-label="Volgende brands"
                    />
                )}
            </div>
        </ModuleWrapper>
    );
};

export default BrandsOverview;
