import { isString, isImage, isBool, isButton } from "utils/checkType";
import { ModuleTextImage } from "../entities";
import { TextImage } from "../models";

export default function createTextImage(data: ModuleTextImage): TextImage {
    const { moduleType } = data;
    const id = isString(data.id);
    const title = data.title && isString(data.title);
    const subtitle = data.subtitle && isString(data.subtitle);
    const text = data.text && isString(data.text);
    const image = data.image && isImage(data.image);
    const imageBoxShadow =
        data.imageBoxShadow !== undefined ? isBool(data.imageBoxShadow) : true;
    const ctaPrimary = data.ctaPrimary && isButton(data.ctaPrimary);
    const ctaSecondary = data.ctaSecondary && isButton(data.ctaSecondary);
    const orientationReversed =
        data.orientationReversed && isBool(data.orientationReversed);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);
    const anchor = data.anchor && isString(data.anchor);

    return {
        id,
        moduleType,
        title,
        subtitle,
        text,
        image,
        imageBoxShadow,
        ctaPrimary,
        ctaSecondary,
        orientationReversed,
        colorBackground,
        anchor,
    };
}
