import { isString } from "utils/checkType";
import { ModuleGenesys } from "../entities";
import { Genesys } from "../models";

export default function createGenesys(data: ModuleGenesys): Genesys {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const deploymentId = data.deploymentId && isString(data.deploymentId);

    return {
        id,
        contentType,
        moduleType,
        title,
        deploymentId,
    };
}
