import * as React from "react";
import { useState, createRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DESKTOP } from "core/constants/global";
import { MenuItem } from "core/models/MenuItem";
import { ArrowDown, SubNavItem } from "view/components";
import $ from "./NavItem.module.scss";

interface NavItemProps {
    activeSubMenu: string;
    changeActiveSubMenu: (title: string) => void;
}

type Props = MenuItem & NavItemProps;

const NavItem = (props: Props) => {
    const { title, path, slug, subMenu, activeSubMenu, changeActiveSubMenu } =
        props;
    const [activeTertiairyNav, setActiveTertiairyNav] = useState();
    const listItem = createRef<HTMLLIElement>();
    const active = activeSubMenu === title;
    const link = path || slug;
    let current;

    const handleHover = (e) => {
        if (current && current.contains(e.target)) {
            return;
        }
        document.removeEventListener("mousemove", handleHover);
        changeActiveSubMenu("");
    };
    const openSubMenu = () => {
        if (window.innerWidth >= DESKTOP) {
            current = listItem && listItem.current;
            changeActiveSubMenu(title);
            return document.addEventListener("mousemove", handleHover);
        }
        return changeActiveSubMenu(active ? "" : title);
    };

    useEffect(() => {
        changeActiveSubMenu("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    return (
        <li ref={listItem} className={$.item}>
            {title && link && (
                <div
                    className={$.buttonWrap}
                    onMouseEnter={() => subMenu && openSubMenu()}
                >
                    <NavLink
                        to={link}
                        className={$.button}
                        activeClassName={$.buttonActive}
                    >
                        {title}
                    </NavLink>
                    {subMenu && (
                        <ArrowDown
                            active={active}
                            baseColor="dark-blue"
                            activeColor="turquoise"
                            onClick={openSubMenu}
                        />
                    )}
                </div>
            )}
            {active && subMenu && (
                <div className={$.columns}>
                    {subMenu.map((column) => (
                        <SubNavItem
                            items={column}
                            key={column[0].id}
                            activeSubMenu={activeTertiairyNav}
                            changeActiveSubMenu={setActiveTertiairyNav}
                        />
                    ))}
                </div>
            )}
        </li>
    );
};

export default NavItem;
