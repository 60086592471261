import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";
import { createAccount } from "../mappers";
import { createAccounts } from "../mappers/createAccount";
import { Account } from "../models";

export default async function fetchAccount(
    config: ConfigContextType,
    endpoint: string,
    token: string,
) {
    const customHeaders = { "Cache-Control": "no-cache" };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await fetchUrl<any>({
        config,
        endpoint,
        body: undefined,
        token,
        customHeaders,
    });

    if (result && result.id) {
        return createAccount(result) as Account;
    }

    return result;
}

export async function fetchAccounts(
    config: ConfigContextType,
    endpoint: string,
    token: string,
): Promise<Account[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await fetchUrl<any>({
        config,
        endpoint,
        body: undefined,
        token,
    });

    if (result === null || (result.status && result.status !== 200)) {
        return [] as Account[];
    }

    return createAccounts(result) as Account[];
}
