import { isString } from "utils/checkType";
import { ModuleArticleList } from "../entities";
import { ArticleList } from "../models";
import createArticles from "./createArticles";
import { paths } from "../constants";

export default function createArticleList(
    data: ModuleArticleList,
): ArticleList {
    const { moduleType } = data;
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const id = data.id && isString(data.id);
    const colorBackground =
        data.colorBackground && isString(data.colorBackground);
    const articleReferences = createArticles(data.articles);
    const morePath = paths.archive;
    const moreLabel = "Laad meer artikelen";

    return {
        moduleType,
        contentType,
        title,
        id,
        articleReferences,
        colorBackground,
        morePath,
        moreLabel,
    };
}
