import classNames from "classnames";

import $ from "./NumberInputButton.module.scss";

type Props = {
    type: "+" | "-";
    attached?: boolean;
    focused?: boolean;
    invalid?: boolean;
    onClick: () => void;
};
const NumberInputButton: React.FC<Props> = ({
    type,
    attached,
    focused,
    invalid,
    onClick,
}) => {
    return (
        <button
            className={classNames({
                [$.numberInputButton]: true,
                [$.increment]: type === "+",
                [$.decrement]: type === "-",
                [$.attached]: attached,
                [$.focused]: focused,
                [$.invalid]: invalid,
            })}
            type="button"
            onClick={onClick}
        >
            {type}
        </button>
    );
};

export default NumberInputButton;
