import { isArray, isBool, isImage, isString } from "utils/checkType";
import { Account as AccountEntity } from "../entities";
import { Account } from "../models";

export default function createAccount(data: AccountEntity): Account {
    const accountmanager = data.accountmanager && {
        fullName:
            data.accountmanager.fullName &&
            isString(data.accountmanager.fullName),
        image: data.accountmanager.image && isImage(data.accountmanager.image),
        title: data.accountmanager.title && isString(data.accountmanager.title),
        personalEmailAddress:
            data.accountmanager.personalEmailAddress &&
            isString(data.accountmanager.personalEmailAddress),
        mobilePhone:
            data.accountmanager.mobilePhone &&
            isString(data.accountmanager.mobilePhone),
    };

    const accountnumber = data.accountnumber && isString(data.accountnumber);
    const address1city = data.address1city && isString(data.address1city);
    const address1line1 = data.address1line1 && isString(data.address1line1);
    const address1line2 = data.address1line2 && isString(data.address1line2);
    const address1line3 = data.address1line3 && isString(data.address1line3);
    const address1postalcode =
        data.address1postalcode && isString(data.address1postalcode);

    const address2city = data.address2city && isString(data.address2city);
    const address2line1 = data.address2line1 && isString(data.address2line1);
    const address2line2 = data.address2line2 && isString(data.address2line2);
    const address2line3 = data.address2line3 && isString(data.address2line3);
    const address2postalcode =
        data.address2postalcode && isString(data.address2postalcode);

    const hasDifferentPostAddress =
        data.hasDifferentPostAddress && isBool(data.hasDifferentPostAddress);
    const chamberOfCommerceNumber =
        data.chamberOfCommerceNumber && isString(data.chamberOfCommerceNumber);

    const emailaddress1 = data.emailaddress1 && isString(data.emailaddress1);
    const emailaddress2 = data.emailaddress2 && isString(data.emailaddress2);
    const emailinvoicecc = data.emailinvoicecc && isString(data.emailinvoicecc);
    const emailinvoice = data.emailinvoice && isBool(data.emailinvoice);

    const ibanNumber = data.ibanNumber && isString(data.ibanNumber);
    const alternativename =
        data.alternativename && isString(data.alternativename);
    const id = data.id && isString(data.id);
    const invoiceto = data.invoiceto && isString(data.invoiceto);
    const name = data.name && isString(data.name);
    const postalboxaddress =
        data.postalboxaddress && isBool(data.postalboxaddress);
    const postalBoxNumber = data.address2line2 && isString(data.address2line2);

    const salesSupport = data.salesSupport && {
        fullName:
            data.salesSupport.fullName && isString(data.salesSupport.fullName),
        image: data.salesSupport.image && isImage(data.salesSupport.image),
        title: data.salesSupport.title && isString(data.salesSupport.title),
        personalEmailAddress:
            data.salesSupport.personalEmailAddress &&
            isString(data.salesSupport.personalEmailAddress),
        mobilePhone:
            data.salesSupport.mobilePhone &&
            isString(data.salesSupport.mobilePhone),
    };

    const salescontract = data.salescontract && {
        duration:
            data.salescontract.duration &&
            isString(data.salescontract.duration),
        enddate:
            data.salescontract.enddate && isString(data.salescontract.enddate),
        id: data.salescontract.id && isString(data.salescontract.id),
        salescontractnumber:
            data.salescontract.salescontractnumber &&
            isString(data.salescontract.salescontractnumber),
        startdate:
            data.salescontract.startdate &&
            isString(data.salescontract.startdate),
    };

    const telephone = data.telephone && isString(data.telephone);
    const vatNumber = data.vatNumber && isString(data.vatNumber);

    return {
        accountmanager,
        accountnumber,
        address1city,
        address1line1,
        address1line2,
        address1line3,
        address1postalcode,
        hasDifferentPostAddress,
        address2city,
        address2line1,
        address2line2,
        address2line3,
        address2postalcode,
        postalBoxNumber,
        emailaddress1,
        emailaddress2,
        emailinvoicecc,
        emailinvoice,
        emailinvoiceoldvalue: emailinvoice,
        invoiceto,
        postalboxaddress,
        salescontract,
        telephone,
        vatNumber,
        ibanNumber,
        alternativename,
        chamberOfCommerceNumber,
        id,
        name,
        salesSupport,
    };
}

export function createAccounts(data: []): Account[] {
    const accounts = data && isArray(data);

    return accounts && accounts.map((account) => createAccount(account));
}
