import { DESKTOP } from "core/constants/global";
import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { ArrowDown } from "view/components";
import $ from "./SubNavItem.module.scss";

const SubNavItem = (props) => {
    const { items, changeActiveSubMenu, activeSubMenu } = props;
    const [isDesktop, setIsDesktop] = useState(false);

    const head = items.filter((item) => item.menuHeading === true)[0];

    const tertiairyNav = items.filter(
        (item) => !item.menuHeading && MenuItemIsVisible(item),
    );

    const active = head && activeSubMenu === head.title;

    const openSubMenuOnDesktop = () => {
        if (window.innerWidth >= DESKTOP) {
            return setIsDesktop(true);
        }
        return setIsDesktop(false);
    };

    useEffect(() => {
        openSubMenuOnDesktop();
        document.addEventListener("resize", openSubMenuOnDesktop);

        return document.removeEventListener("resize", openSubMenuOnDesktop);
    });

    return (
        // Menu item array will never change dynamically
        // eslint-disable-next-line react/no-array-index-key
        <div className={$.column}>
            {head && (
                <button
                    type="button"
                    className={$.columnButton}
                    onClick={() => {
                        if (!isDesktop)
                            changeActiveSubMenu(active ? "" : head.title);
                    }}
                >
                    <span>{head.title}</span>
                    {!isDesktop && (
                        <ArrowDown
                            active={active}
                            baseColor="turquoise"
                            activeColor="turquoise"
                        />
                    )}
                </button>
            )}
            {(!head || active || isDesktop) && (
                <ul className={$.subMenu}>
                    {tertiairyNav.map((navItem) => (
                        <li key={navItem.id} className={$.listItem}>
                            <NavLink
                                to={navItem.path || navItem.externalUrl}
                                className={$.link}
                                activeClassName={$.linkActive}
                            >
                                {navItem.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SubNavItem;
