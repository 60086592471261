import { isString } from "utils/checkType";
import { Product } from "../models";
import { Product as ProductEntity } from "../entities";

export default function createProduct(data: ProductEntity): Product {
    const brand = isString(data.brand);
    const packaging = isString(data.packaging);
    const productDescription = isString(data.productDescription);
    const productId = isString(data.productId);
    const productNumber = isString(data.productnumber);

    return {
        brand,
        packaging,
        productDescription,
        productId,
        productNumber,
    };
}
