import { Genesys as GenesysModel } from "core/models";
import * as React from "react";
import { Container } from "view/components";
import $ from "./Genesys.module.scss";

const Genesys: React.FC<GenesysModel> = (props: GenesysModel) => {
    const { deploymentId } = props;

    React.useEffect(() => {
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.charset = "utf-8";
        script.innerHTML = `
        (function (g, e, n, es, ys) {
          g['_genesysJs'] = e;
          g[e] = g[e] || function () {
            (g[e].q = g[e].q || []).push(arguments)
          };
          g[e].t = 1 * new Date();
          g[e].c = es;
          ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
        })(window, 'Genesys', 'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js', {
          environment: 'prod-euc1',
          deploymentId: '${deploymentId}'
        });
        `;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Container
            fullWidth
            id="genesys-support-center"
            className={$.genesysContainer}
        />
    );
};

export default Genesys;
