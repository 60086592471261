import classnames from "classnames";
import React from "react";
import { Container } from "view/components";
import $ from "./ModuleWrapper.module.scss";

interface Props {
    title?: string;
    background?: string | undefined;
    noMargin?: boolean;
    anchor?: string;
    TitleButton?: React.FC;
}

const ModuleWrapper: React.FC<Props> = ({
    children,
    title,
    background,
    noMargin,
    anchor,
    TitleButton,
}) => {
    const wrapStyles = classnames([
        $.wrap,
        !noMargin && $.margin,
        background === "Grey" && $.wrapGrey,
    ]);

    return (
        <section className={wrapStyles} id={anchor}>
            <Container>
                {title && (
                    <h1 className={$.title} data-testid="title">
                        {title}
                    </h1>
                )}
                {TitleButton && <TitleButton />}
                {children}
            </Container>
        </section>
    );
};

export default ModuleWrapper;
