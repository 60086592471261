import classnames from "classnames";
import { MOBILE } from "core/constants/global";
import { Image as ImageModel, TextImage as TextImageModel } from "core/models";
import * as React from "react";
import MenuItemIsVisible from "utils/MenuItemIsVisible";
import { useWindowWidth } from "utils/useWindowWidth";
import { Body, Button, Card, Image, ModuleWrapper } from "view/components";
import $ from "./TextImage.module.scss";

function renderImage(image: ImageModel, windowWidth: number, isCover: boolean) {
    const imgWidth = windowWidth <= MOBILE ? 748 : 1370;
    const imageStyle = isCover ? classnames($.image, $.imageCover) : $.image;

    return (
        <div className={$.imageContainer} data-testid="imageContainer">
            {image && image.url && (
                <Image
                    placeholder={`${image.url}?w=30&q=50&fm=jpg&fl=progressive`}
                    src={`${image.url}?w=${imgWidth}&q=75&fm=jpg&fl=progressive`}
                    alt={image.title}
                    className={imageStyle}
                    background={isCover}
                />
            )}
        </div>
    );
}

const TextImage: React.FC<TextImageModel> = (props: TextImageModel) => {
    const {
        title,
        subtitle,
        text,
        image,
        imageBoxShadow,
        ctaPrimary,
        ctaSecondary,
        orientationReversed,
        colorBackground,
        anchor,
    } = props;

    const lightBackground: boolean =
        colorBackground === undefined || colorBackground === "White";

    const windowWidth = useWindowWidth();
    const reversed: React.CSSProperties | undefined =
        windowWidth > MOBILE && !orientationReversed
            ? { flexDirection: "row-reverse" }
            : undefined;
    const CardOrSpan = imageBoxShadow ? Card : "span";
    const CardOrFragment = imageBoxShadow ? React.Fragment : Card;
    const CardProps = imageBoxShadow ? undefined : { className: $.card };
    const textContainerStyle = classnames(
        $.textContainer,
        lightBackground && $.textContainerDark,
        !imageBoxShadow && $.textContainerFit,
    );
    const isCover = imageBoxShadow;

    return (
        <ModuleWrapper
            title={title}
            background={colorBackground}
            anchor={anchor}
        >
            <CardOrSpan>
                <div className={$.container} style={reversed}>
                    <CardOrFragment {...CardProps}>
                        <div
                            className={textContainerStyle}
                            data-testid="textContainer"
                        >
                            {subtitle && (
                                <h1 className={$.title}>{subtitle}</h1>
                            )}
                            {text && <Body content={text} className={$.text} />}

                            {ctaPrimary &&
                                ctaPrimary.title &&
                                MenuItemIsVisible(ctaPrimary) && (
                                    <Button
                                        className={$.button}
                                        color="turquiose"
                                        title={ctaPrimary.title}
                                        path={
                                            ctaPrimary.slug ||
                                            ctaPrimary.path ||
                                            ctaPrimary.externalUrl
                                        }
                                        small
                                    />
                                )}
                            {ctaSecondary &&
                                ctaSecondary.title &&
                                MenuItemIsVisible(ctaSecondary) && (
                                    <Button
                                        className={$.button}
                                        color="green"
                                        title={ctaSecondary.title}
                                        path={
                                            ctaSecondary.slug ||
                                            ctaSecondary.path ||
                                            ctaSecondary.externalUrl
                                        }
                                        small
                                    />
                                )}
                        </div>
                    </CardOrFragment>

                    {renderImage(image, windowWidth, isCover)}
                </div>
            </CardOrSpan>
        </ModuleWrapper>
    );
};

export default TextImage;
