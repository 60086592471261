import {
    isString,
    isImage,
    isButton,
    isArray,
    isStringOrUndefined,
    isBool,
} from "utils/checkType";
import { ModuleHero } from "../entities";
import { Hero } from "../models";

export default function createHero(data: ModuleHero): Hero {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = data.contentType && isString(data.contentType);
    const title = data.title && isString(data.title);
    const text = data.text && isString(data.text);
    const textBlockHideBackground =
        data.textBlockHideBackground && isBool(data.textBlockHideBackground);
    const image = data.image && isImage(data.image);
    const imageMobile = data.imageMobile && isImage(data.imageMobile);
    const ctaPrimary = data.ctaPrimary && isButton(data.ctaPrimary);
    const ctaSecondary = data.ctaSecondary && isButton(data.ctaSecondary);
    const appstoreUri = data.appstoreUri && isString(data.appstoreUri);
    const playstoreUri = data.playstoreUri && isString(data.playstoreUri);
    const uspTitle = data.uspTitle && isString(data.uspTitle);
    const uspItems = data.uspItems && isArray(data.uspItems);
    const alignment = data.alignment && isStringOrUndefined(data.alignment);

    return {
        id,
        contentType,
        moduleType,
        title,
        text,
        textBlockHideBackground,
        image,
        imageMobile,
        ctaPrimary,
        ctaSecondary,
        appstoreUri,
        playstoreUri,
        uspTitle,
        uspItems,
        alignment,
    };
}
