function getPreviewQuerySecret() {
    const previewSecret = new URL(window.location.href).searchParams.get(
        "preview",
    );
    if (previewSecret === null || previewSecret === "") {
        return "";
    }

    return `?preview=${encodeURIComponent(previewSecret)}`;
}

const endpoints = {
    home: "home",
    config: "config.json",
    init: `/contentfulservice/api/v1/init${getPreviewQuerySecret()}`,
    invoices(accountnumber?: string): string {
        let endpoint = `/contentfulservice/api/v1/invoices`;
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }

        return endpoint;
    },
    documents(accountnumber?: string): string {
        let endpoint = `/contentfulservice/api/v1/documents`;
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }

        return endpoint;
    },
    inbox(accountnumber?: string): string {
        let endpoint = `/contentfulservice/api/v1/inbox`;
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }

        return endpoint;
    },
    inboxPost(accountnumber?: string): string {
        let endpoint = `/contentfulservice/api/v1/inbox/--guid--`;
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }

        return endpoint;
    },
    consent: "/contentfulservice/api/v1/users",
    categories: `/contentfulservice/api/v1/categories${getPreviewQuerySecret()}`,
    archive: `/contentfulservice/api/v1/articles/latest${getPreviewQuerySecret()}`,
    contactForm: "/contactservice/api/v2/contacts/contactform",
    bonusTool: {
        products: "/contentfulservice/api/v1/actualssalesdata/products",
        salesDataById(guid: string, accountnumber?: string): string {
            let endpoint = `/contentfulservice/api/v1/actualssalesdata/${guid}`;
            if (accountnumber) {
                endpoint += `?accountnumber=${accountnumber}`;
            }

            return endpoint;
        },
        salesData(accountnumber?: string): string {
            let endpoint = `/contentfulservice/api/v1/actualssalesdata/`;
            if (accountnumber) {
                endpoint += `?accountnumber=${accountnumber}`;
            }

            return endpoint;
        },
        attachments(
            guid: string,
            posId: string,
            accountnumber?: string,
        ): string {
            let endpoint = `/contentfulservice/api/v1/actualssalesdata/${guid}/attachments?posId=${encodeURIComponent(
                posId,
            )}`;
            if (accountnumber) {
                endpoint += `&accountnumber=${encodeURIComponent(
                    accountnumber,
                )}`;
            }

            return endpoint;
        },
        attachment(guid: string, posId: string, id: number) {
            return `/contentfulservice/api/v1/actualssalesdata/${guid}/attachments/${id}?posId=${encodeURIComponent(
                posId,
            )}`;
        },
    },
    thtTool: {
        coronaData: "/contentfulservice/api/v1/corona",
        coronaPutData: `/contentfulservice/api/v1/corona`,
        attachments: `/contentfulservice/api/v1/corona/attachments`,
    },
    accountdetails(accountnumber?: string): string {
        let endpoint = `/contentfulservice/api/v1/accounts/details`;
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }

        return endpoint;
    },
    accounts(accountnumber?: string): string {
        let endpoint = "/contentfulservice/api/v1/accounts";
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }
        return endpoint;
    },
    contacts(accountnumber?: string, guid?: string): string {
        let endpoint = "/contentfulservice/api/v1/contacts/";
        if (guid) {
            endpoint += `/${guid}`;
        }
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }
        return endpoint;
    },
    salescontractdata(accountnumber?: string): string {
        let endpoint = `/contentfulservice/api/v1/salescontract`;
        if (accountnumber) {
            endpoint += `?accountnumber=${accountnumber}`;
        }

        return endpoint;
    },
    page(slug: string): string {
        return `/contentfulservice/api/v1/pages/${slug}${getPreviewQuerySecret()}`;
    },
    article(slug: string): string {
        return `/contentfulservice/api/v1/articles/${slug}${getPreviewQuerySecret()}`;
    },
    category(slug: string): string {
        return `/contentfulservice/api/v1/categories/${slug}${getPreviewQuerySecret()}`;
    },
};
export default endpoints;
