import * as React from "react";
import { useEffect, useState } from "react";
import { ArrowDown, Card } from "view/components";
import $ from "./Accordion.module.scss";

interface Props {
    index: number;
    title: React.ReactNode;
    children?: React.ReactNode;
    activeTab: number;
    activatePanel(index: number): void;
}

const Panel = (props: Props) => {
    const { index, title, children, activeTab, activatePanel } = props;

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(activeTab === index);
    }, [activeTab, index]);

    const onClick = (index1: number) => {
        activatePanel(index1);
        setIsActive(!isActive);
    };

    return (
        <div className={$.panel} key={index}>
            <section>
                <Card className={$.card}>
                    {title && (
                        <button
                            type="button"
                            aria-expanded={isActive}
                            aria-controls={`sect${index}`}
                            className={$.panelHead}
                            onClick={() => onClick(index)}
                        >
                            <ArrowDown
                                active={isActive}
                                baseColor="turquoise"
                                activeColor="dark-blue"
                            />
                            <h3>{title}</h3>
                        </button>
                    )}
                </Card>

                {isActive && (
                    <div
                        className={$.panelBody}
                        id={`sect${index}`}
                        aria-hidden={!isActive}
                        role="tabpanel"
                    >
                        <div className={$.panelBodyContent}>{children}</div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default Panel;
