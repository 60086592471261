import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";
import { createInvoices } from "../mappers";
import { Invoice } from "../models";

export default async function fetchInvoices(
    config: ConfigContextType,
    endpoint: string,
    token: string,
) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await fetchUrl<any>({
        config,
        endpoint,
        body: undefined,
        token,
    });

    if (response.status && response.status !== 200) {
        const { statusText } = response;
        return {
            result: null,
            error: statusText,
        };
    }

    return {
        error: null,
        result: createInvoices(response.invoices) as Invoice[],
    };
}
