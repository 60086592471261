import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Category } from "core/models";
import $ from "./CategoryLabel.module.scss";

interface MatchParams {
    slug: string;
}

type Props = Category & RouteComponentProps<MatchParams>;

const CategoryLabel = (props: Props) => {
    const { path, title, color, history } = props;
    if (!path) {
        return null;
    }

    const linkStyle = {
        color: color && color,
    };

    return (
        <h4 className={$.label} data-testid="label">
            <button
                className={$.labelLink}
                type="button"
                style={linkStyle}
                onClick={(e) => {
                    e.preventDefault();
                    history.push(path);
                }}
                data-testid="title"
            >
                {title}
            </button>
        </h4>
    );
};

export default withRouter(CategoryLabel);
