import * as React from "react";
import classnames from "classnames";
import { Person } from "core/models";
import { Image } from "..";

import $ from "./PersonBlock.module.scss";

type Props = {
    person: Person;
    textFirst?: boolean;
};

const PersonBlock: React.FC<Props> = ({ person, textFirst = false }) => {
    if (!person) {
        return null;
    }
    const { image, fullName, title, personalEmailAddress, mobilePhone } =
        person;
    const imageSrc = image && image.url;
    const personClassName = classnames([
        $.person,
        textFirst && $.personTextFirst,
    ]);
    return (
        <div className={personClassName}>
            {imageSrc && (
                <div className={$.imageContainer}>
                    <Image
                        src={imageSrc}
                        className={$.image}
                        placeholder="/static/images/ic-placeholder.svg"
                        background
                    />
                </div>
            )}
            <p>
                <span className={$.block}>
                    <strong>{fullName}</strong>
                </span>
                {title && <span className={$.block}>{title}</span>}
                {personalEmailAddress && (
                    <span className={$.block}>
                        <strong>
                            <a href={`mailto:${personalEmailAddress}`}>
                                {personalEmailAddress}
                            </a>
                        </strong>
                    </span>
                )}
                {mobilePhone && (
                    <span className={$.block}>
                        <strong>
                            <a href={`tel:${mobilePhone}`}>{mobilePhone}</a>
                        </strong>
                    </span>
                )}
            </p>
        </div>
    );
};

export default PersonBlock;
