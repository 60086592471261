import paths from "./paths";
import { MenuItem } from "../models";

const loginButton: MenuItem = {
    title: "Inloggen",
    slug: "inloggen",
    id: "5",
    path: paths.login,
    contentType: "navigationItem",
    externalUrl: "",
    icon: {
        url: "/static/images/ic-login-white.svg",
        title: "Lock",
    },
    menuHeading: false,
};

const logoutButton: MenuItem = {
    title: "Uitloggen",
    slug: "uitloggen",
    id: "6",
    path: paths.logout,
    contentType: "navigationItem",
    externalUrl: "",
    icon: {
        url: "/static/images/ic-logout.svg",
        title: "Unlock",
    },
    menuHeading: false,
};

const registerButton: MenuItem = {
    title: "Registreren",
    slug: "register",
    id: "7",
    path: "/registreren",
    contentType: "navigationItem",
    externalUrl: "",
    icon: {
        url: "",
        title: "",
    },
    menuHeading: false,
};

const dashboardButton: MenuItem = {
    title: "Dashboard",
    slug: "dashboard",
    id: "8",
    path: paths.dashboard,
    contentType: "navigationItem",
    externalUrl: "",
    icon: {
        url: "/static/images/ic-login-white.svg",
        title: "Dashboard",
    },
    menuHeading: false,
};

export default { loginButton, logoutButton, registerButton, dashboardButton };
