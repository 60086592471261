import { isString } from "utils/checkType";
import { Login } from "../models";
import { ModuleLogin } from "../entities";

export default function createLogin(data: ModuleLogin): Login {
    const { moduleType } = data;
    const id = isString(data.id);
    const contentType = isString(data.contentType);
    const title = data.title && isString(data.title);

    return {
        id,
        contentType,
        title,
        moduleType,
    };
}
