import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "core/constants";
import { LOGO } from "core/constants/global";
import { Config } from "core/models";
import { User } from "core/models/User";
import fetchInit from "core/services/fetchInit";
import * as React from "react";
import {
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from "react-router-dom";
import { Footer, Header, Spinner } from "view/components";
import AccountContextProvider from "view/context/AccountContext";
import ConfigContext, { ConfigContextType } from "view/context/ConfigContext";
import UserContext from "view/context/UserContext";
import useTokenService from "view/hooks/useTokenService";
import SettingsContext, {
    SettingsContextType,
} from "../../context/SettingsContext";
import routes, { RouteDefinition } from "../../routes";

const PrivateRoute = React.lazy(
    () =>
        import(
            /* webpackChunkName:'PrivateRoute' */ "view/components/PrivateRoute"
        ),
);

interface ConfigProps {
    config: ConfigContextType;
}

type Props = ConfigProps & RouteComponentProps;

function addLogo(response: SettingsContextType | null) {
    const url = window.location.origin;
    const seoImage = {
        url: `${url}${LOGO.default}`,
        title: "logo Vrumona",
    };

    if (response && response.configuration) {
        response.configuration.seoImage = seoImage;
    }

    return response;
}

function useInitController(config) {
    const [pending, setPending] = React.useState(true);
    const [init, setInit] = React.useState<SettingsContextType | null>(null);
    const fetchData = React.useCallback(async () => {
        if (!config) {
            return null;
        }
        setPending(true);
        let response = await fetchInit(config);
        response = addLogo(response);
        setInit(response);
        setPending(false);
        return null;
    }, [config]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { init, pending };
}

function useInitUser(config) {
    const [pending, setPending] = React.useState(true);

    const { user: auth0User, getAccessTokenSilently } = useAuth0();
    const [user, setUser] = React.useState<User | null>(null);

    const { token } = useTokenService(getAccessTokenSilently, auth0User);

    const fetchData = React.useCallback(async () => {
        if (!config) {
            return null;
        }
        if (!token) {
            return null;
        }

        setPending(true);

        if (auth0User) {
            const user1: User = {
                firstName: auth0User.given_name,
                lastName: auth0User.family_name,
                phonenumber: auth0User.name,
                organisation:
                    auth0User["https://www.vrumona.nl/crm"]?.accountnumber,
            };

            setUser(user1);
        }

        setPending(false);
        return null;
    }, [auth0User, config, token]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { user, pending };
}

function renderRoute(route: RouteDefinition, config: Config) {
    if (route.debug && !config.debug) {
        return null; // Only render debug routes when debug is enabled
    }
    const RouteComponent = route.private ? PrivateRoute : Route;
    return (
        <RouteComponent
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={(p: RouteComponentProps) =>
                route.fullscreen ? (
                    <route.component {...p} route />
                ) : (
                    <>
                        <Header route={route} />
                        <route.component {...p} route />
                        <Footer />
                    </>
                )
            }
        />
    );
}
const App: React.FC<Props> = (props) => {
    const { config } = props;
    const { pending, init } = useInitController(config);
    const { user } = useInitUser(config);

    if (!pending && !init) {
        // couldn't render app because of missing required data
        window.location.href = paths.error;
    }

    const renderLoader = () => <Spinner />;

    return (
        <ConfigContext.Provider value={config}>
            <SettingsContext.Provider value={init}>
                <UserContext.Provider value={user}>
                    <AccountContextProvider>
                        <React.Suspense fallback={renderLoader()}>
                            <section data-testid="container">
                                {!pending && init ? (
                                    <Switch>
                                        {routes.map((route) =>
                                            renderRoute(route, config),
                                        )}
                                    </Switch>
                                ) : (
                                    <Spinner withContainer />
                                )}
                            </section>
                        </React.Suspense>
                    </AccountContextProvider>
                </UserContext.Provider>
            </SettingsContext.Provider>
        </ConfigContext.Provider>
    );
};

export default withRouter(App);
